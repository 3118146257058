<template>
  <div class="header-content">
    <div class="header-left">
      <div class="header-nav-btn" v-on:click="toggleNav">
        <b-icon-list></b-icon-list>
      </div>

      <div class="header-home-btn-wrapper">
        <button
          v-html="svg.welcomeGraph"
          v-on:click="homeOnClick"
          class="header-home-btn"
        ></button>
      </div>
      <div v-if="this.$parent.isAdmin">
        <button v-on:click="goToAdmin" class="header-logout-btn">
          Evorisk Admin
        </button>
      </div>
    </div>

    <div v-if="$auth.isAuthenticated" class="header-logout-btn-wrapper">
      <button @click="logout" class="header-logout-btn">
        <b-icon-arrow-bar-right
          style="width: 15px; height: 15px; margin-right: 4px"
        ></b-icon-arrow-bar-right>
        {{ $t("header.logout") }}
      </button>
    </div>
  </div>
</template>

<script>
import { svg } from "../assets/svg";
import axios from "axios";
import JQuery from "jquery";
let $ = JQuery;
export default {
  props: {
    onClickCallback: Function,
  },

  data: function () {
    return {
      defaultSmallSize: 768,
    };
  },

  computed: {
    svg() {
      return svg;
    },
  },
  mounted: function () {
    window.addEventListener("resize", this.handleWindowResize);
    this.refreshNav();
  },
  methods: {
    homeOnClick(e) {
      e.preventDefault();
      this.$router.push({ name: "home" });
      this.$router.go();
      // if (this.onClickCallback) {
      //     this.onClickCallback();
      // }
    },
    logout() {
      console.log("check logout");
      axios
        .get(process.env.VUE_APP_API_LOGOUT, {
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        })
        .then((success) => {
          console.log(success);
          this.$auth.logout({
            returnTo: window.location.origin,
          });
        });
    },
    goToAdmin() {
      if (process.env.VUE_APP_URL == "http://localhost:5000") {
        var url = process.env.VUE_APP_ADMIN_URL;
        window.open(url, "_target");
      } else {
        this.$router.push({ name: "admin" });
        this.$router.go();
      }
    },
    toggleNav() {
      if (this.isNavOpened()) {
        $(".router-wrapper").removeClass("nav-opened").addClass("nav-closed");
      } else {
        $(".router-wrapper").removeClass("nav-closed").addClass("nav-opened");
      }
      if (!this.isSmallScreenSize()) {
        setTimeout(function () {
          var evt = window.document.createEvent("UIEvents");
          evt.initUIEvent("resize", true, false, window, 0);
          window.dispatchEvent(evt);
        }, 300);
      }
    },
    isNavOpened() {
      return $(".router-wrapper").hasClass("nav-closed") == false;
    },
    isSmallScreenSize() {
      return $(window).width() < this.defaultSmallSize;
    },
    refreshNav() {
      if (this.isSmallScreenSize()) {
        if (this.isNavOpened()) {
          this.toggleNav();
        }
      }
    },
    handleWindowResize(event) {
      this.refreshNav();
    },
  },
  /*
    created: function(){
    }
    */
};
</script>

<style scoped>
.header-content {
  display: grid;
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;

  /*
    padding-left: 2rem;
    padding-right: 2rem;
    */
  padding-left: 1rem;
  padding-right: 1rem;

  grid-template-columns: auto 1fr auto;
  /*grid-template-rows: 1fr;*/
  grid-template-rows: 100%;
  grid-template-areas: "headerLeft . headerLogout";
}
.header-home-btn-wrapper {
  grid-area: headerHome;
  display: flex;
  width: 4rem;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
}
.header-home-btn {
  width: 100%;
  height: 100%;
  pointer-events: auto;
}
.header-logout-btn-wrapper {
  grid-area: headerLogout;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-logout-btn {
  pointer-events: auto;
  padding: 0.4rem;
}
.header-nav-btn {
  grid-area: headerNav;
  width: 36px;
  height: 36px;
  padding: 2px;
  margin-right: 40px;
  pointer-events: initial;
  cursor: pointer;
}
.header-nav-btn:hover {
  background: #eee;
}
.header-admin-btn {
  grid-area: headerAdmin;
}
.header-left {
  grid-area: headerLeft;
  display: flex;
}
</style>