<template>
  <div id="app">
    <router-view class="router-wrapper" />
  </div>
</template>

<script>
import store from "@/store/store.js";

export default {
  name: "app",
  components: {},
  computed: {},
  mounted: function () {
    //console.log("App.vue mounted.");
  },
  created: function () {
    //console.log(this.$route.path)
    if (this.$route.path === "/") {
      this.$store.dispatch("retrieveTokenFromAuth0");
    }
  },
};
</script>

<style>
/* FONT FACE */
@font-face {
  font-family: "Roboto Regular";
  src: local("Roboto-Regular"),
    url("./assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Regular.woff") format("woff"),
    url("./assets/fonts/Roboto-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto Bold";
  src: local("Roboto-Bold"),
    url("./assets/fonts/Roboto-Bold.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Bold.woff") format("woff"),
    url("./assets/fonts/Roboto-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto Black";
  src: local("Roboto-Black"),
    url("./assets/fonts/Roboto-Black.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Black.woff") format("woff"),
    url("./assets/fonts/Roboto-Black.ttf") format("ttf");
}
/* GLOBAL */
* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
svg {
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}
button:focus {
  outline: 0;
}
input {
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: text;
}
/*
  select {
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }
  option {
    color: red; 
  }
  */
/* INPUT TYPE RANGE */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
}
input[type="range"]:focus {
  outline: none;
}

/* HTML/BODY */
html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  /*overscroll-behavior-y: contain;*/
}
body {
  /*background: rgba(59, 59, 59, 1.0);*/
  background: #fff;

  font-size: 1rem;
  font-family: "Roboto Regular";
  color: white;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
/* APP style removed */
/* router-wrapper */
.router-wrapper {
  /*
    background: rgba(59, 59, 59, 1.0);
    */

  /*
    background: #4B79A1;
    background: -webkit-linear-gradient(to right, rgb(49, 80, 107), #4B79A1);
    background: linear-gradient(to right, rgb(49, 80, 107), #4B79A1);
    */

  /*
    background: #fbfbfb;
    color: black;
    */

  background: #fff;
  color: black;

  /* EDGE -> OVERFLOW-X Fix? */
  /*
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    */
}
.col-header {
  background: #fff;
  color: #444;
  padding: 4px 0;
  box-shadow: 0 0 5px #999;
  position: fixed;
  z-index: 10;
  height: 46px;
  top: 0;
}
</style>