import { getInstance } from "./index";
import store from '@/store/store.js'

export const authGuard = (to, from, next) => {
  const authService = getInstance();
  
  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    // Otherwise, log in
    // authService.getTokenSilently();
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    authService.user = authService.auth0Client.getUser();
    store.dispatch('updateUser', authService.user)
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};