<template>
  <div>
    <div class="col-12 col-header">
      <header class="header">
        <Header :onClickCallback="goToHome" />
      </header>
    </div>
    <div class="col-nav">
      <nav class="nav">
        <div class="nav-content">
          <button
            v-on:click="goToAskProject"
            class="project-ask-access main-shadow-hovered"
          >
            {{ $t("home.demande_acces") }}
          </button>
          <div v-if="projectsScope" class="project-selector main-shadow">
            <select v-model="projectSelected">
              <option value="" disabled selected>{{ $t("home.select_project") }}</option>
              <option
                v-for="option in projectsScope"
                :key="option.name"
                v-bind:value="option.data_name"
              >
                {{ option.name | capitalize }}
              </option>
            </select>
          </div>
          <div v-if="!projectSelectedConfig">
              <div
                  v-for="project in projectsScope"
                  :key="project.name"
                >
                  <div v-if="project.name == 'Soilstab'"
                   class="bloc_project_soilstab"
                    @click="projectSelected = project.data_name"
                  >
                    <!-- <div class="pic"><img :src="project.cover" width="150" height="150"/></div> -->
                    {{ project.infos.title }}
                  </div>
                </div>
           </div>
          <div v-if="projectSelectedConfig" class="project-nav">
            <!-- partner(s) -->
            <div
              v-if="projectSelectedConfig.partners"
              class="project-nav-item project-nav-partners"
            >
              <img
                v-for="partner in projectSelectedConfig.partners"
                :key="partner.img_url"
                :src="partner.img_url"
                alt="partner's logo"
                :class="{
                  'project-nav-partners-img-1-1': partner.img_format === '1:1',
                  'project-nav-partners-img-2-1': partner.img_format === '2:1',
                  'project-nav-partners-img-4-1': partner.img_format === '4:1',
                }"
              />
            </div>
            <!-- rim-nat link -->
            <!-- <a
              v-if="hasRimnatAccess"
              v-on:click="openRimnatViewer"
              rel="noopener"
              target="_blank"
              class="project-nav-item project-nav-rimnat main-shadow-hovered"
            >
              <div v-html="svg.rimnat" class="project-nav-rimnat-logo"></div>
            </a> -->
            <!-- quote link -->

          <button
          v-if="projectSelectedConfig.hasquotelink"
              v-on:click="goToAskQuote(projectSelectedConfig.hasquotelink)"
            class="project-ask-access main-shadow-hovered"
          >
            {{ $t("ask_quote") }}
          </button>
            <!-- module(s) -->
            <div
              v-if="projectSelectedConfig.modules"
              class="project-nav-modules"
            >
              <div
                v-for="module in projectSelectedConfig.modules"
                :key="module.data_name"
                class="project-nav-module main-shadow-hovered"
              >
                <div
                  v-if="
                    module.who.includes(
                      userProjectsRoles[projectSelectedConfig.data_name]
                    )
                  "
                  class="project-nav-module-content"
                >
                  <div
                    v-if="module.nav_expandable"
                    class="project-nav-module-content"
                  >
                    <button
                      class="project-nav-module-item-expander"
                      v-on:click="expand(module.data_name)"
                    >
                      <p class="project-nav-module-title">{{ module.name }}</p>
                      <p
                        v-if="module.sub_name"
                        class="project-nav-module-subtitle"
                      >
                        {{ module.sub_name }}
                      </p>
                      <div
                        class="project-nav-module-item-expander-arrow"
                        :class="{
                          'project-nav-module-item-expander-arrow-close':
                            expandBlock[module.data_name],
                        }"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M5 12h13M12 5l7 7-7 7" />
                        </svg>
                      </div>
                    </button>
                    <transition
                      v-on:before-enter="ts_beforeEnter"
                      v-on:enter="ts_enter"
                      v-on:after-enter="ts_afterEnter"
                      v-on:before-leave="ts_beforeLeave"
                      v-on:leave="ts_leave"
                      v-on:after-leave="ts_afterLeave"
                    >
                      <div
                        v-if="expandBlock[module.data_name]"
                        class="project-nav-module-expandable-block"
                      >
                        <div class="project-nav-module-items">
                          <p class="spec_vol_ext" v-if="module.data_name=='extenso_analyse' && projectSelectedConfig.data_name=='volvic'">
                           Zone 2: capt. 13, 14, 22 <br>
                           Zone 3: capt. 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, <br>
                           <span class="tab">15 ,16 ,17 ,18 ,19 ,20 , 21 </span> <br>
                           Zone  4: capt. 1, 2, 3
                          </p>
                          <div
                            v-for="item in module.items"
                            :key="item.data_name"
                            class="project-nav-module-item"
                          >
                            <button
                              class="toggle-button"
                              :class="{
                                'toggle-button-activated':
                                  toggleOn[
                                    module.data_name + ':' + item.data_name
                                  ],
                              }"
                              v-on:click="
                                moduleItemSelector(
                                  module.name,
                                  module.data_name,
                                  item.name,
                                  item.data_name,
                                  item.type,
                                  item.chart_display,
                                  item.sampling,
                                  module.series_show
                                )
                              "
                            >
                              <div
                                class="toggle-button-indicator"
                                :class="{
                                  'toggle-button-indicator-activated':
                                    toggleOn[
                                      module.data_name + ':' + item.data_name
                                    ],
                                }"
                              ></div>
                            </button>
                            <span>{{ item.name }}</span>
                            <button
                              v-if="item.download"
                              class="project-nav-item-download"
                              v-on:click="showDownloadModal(item.download)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="black"
                                stroke-width="3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"
                                />
                              </svg>
                            </button>
                          </div>
                          <button
                            v-if="module.download"
                            class="project-nav-module-global-download"
                            v-on:click="showDownloadModal(module.download)"
                          >
                            <b-icon-download
                              style="
                                width: 16px;
                                height: 16px;
                                margin-right: 12px;
                              "
                            ></b-icon-download>
                            {{ $t("download") }}
                          </button>
                        </div>
                      </div>
                    </transition>
                  </div>
                  <div v-else class="project-nav-module-content">
                    <div class="project-nav-module-title-wrapper">
                      <p class="project-nav-module-title">{{ module.name }}</p>
                      <p
                        v-if="module.sub_name"
                        class="project-nav-module-subtitle"
                      >
                        {{ module.sub_name }}
                      </p>
                    </div>
                    <div class="project-nav-module-items">
                      <div
                        v-for="item in module.items"
                        :key="item.data_name"
                        class="project-nav-module-item"
                      >
                        <button
                          class="toggle-button"
                          :class="{
                            'toggle-button-activated':
                              toggleOn[module.data_name + ':' + item.data_name],
                          }"
                          v-on:click="
                            moduleItemSelector(
                              module.name,
                              module.data_name,
                              item.name,
                              item.data_name,
                              item.type,
                              item.chart_display,
                              item.sampling,
                              module.series_show
                            )
                          "
                        >
                          <div
                            class="toggle-button-indicator"
                            :class="{
                              'toggle-button-indicator-activated':
                                toggleOn[
                                  module.data_name + ':' + item.data_name
                                ],
                            }"
                          ></div>
                        </button>
                        <span>{{ item.name }}</span>
                        <button
                          v-if="item.download"
                          class="project-nav-item-download"
                          v-on:click="showDownloadModal(item.download)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="black"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <button
                      v-if="module.download"
                      class="project-nav-module-global-download"
                      v-on:click="showDownloadModal(module.download)"
                    >
                      Download Files
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <div v-if="backConnectionError==true" class="home-content">
      <main class="main">
        <div class="main-content">
          <div class="main-welcome-text">
            <div v-html="svg.welcomeGraph" class="main-welcome-svg"></div>
            <h1>
              {{ $t("home.HS_evorisk") }}
            </h1>
            <h2>
              {{ $t("home.try_later") }}
            </h2>
          </div>
        </div>
      </main>
    </div>
    <div v-else class="home-content">
      <main class="main">
        <div class="main-content">
        <div class="box-redirection" v-if="currentUrl == 'http://localhost:5000/' || currentUrl == 'https://argos.surveillance-geolithe.fr/'">
            <h3>{{ $t("home.redirection_evorisk") }} <a class="URL" href="https://evorisk.fr" target="_blank">{{ $t("home.good_URL") }}</a> </h3>
            <h3>{{ $t("home.redirection_evorisk_2") }} <span class="URL">{{ $t("home.bad_URL") }}</span> {{ $t("home.redirection_evorisk_3") }} </h3>
        </div>
          <!-- main-welcome -->
          <div v-if="!projectSelectedConfig" class="main-welcome">
            <div class="main-welcome-text">
              <div v-html="svg.welcomeGraph" class="main-welcome-svg"></div>
              <h1>
                {{ $t("home.bonjour") }} {{ (this.userFirstName + " " + userLastName) | capitalize }}
              </h1>
            </div>
            <h2>{{ $t("home.bienvenue") }}</h2>
            <div v-if="projectsScope" class="container-fluid">
              <h4>{{ $t("home.my_projects") }}</h4>
              <div class="row">
                <div
                  v-for="project in projectsScope"
                  :key="project.name"
                  class="* col-xs-12 col-sm-12 col-md-6"
                >
                  <div v-if="project.data_name !== 'soilstab_service'"
                    class="bloc_project"
                    @click="projectSelected = project.data_name"
                  >
                    <div class="pic"><img :src="project.cover" width="150" height="150"/></div>
                    {{ project.infos.title }}
                    <div class="parameter">
                      <b-icon-geo-alt class="icon"></b-icon-geo-alt
                      >{{ project.infos.location }}
                    </div>
                    <div class="parameter">
                      <b-icon-clock class="icon"></b-icon-clock
                      >{{ project.infos.date_project }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <span
                >{{ $t("home.no_project") }}
              </span>
              <button
                v-on:click="goToAskProject"
                class="main-welcome-text-ask-project"
              >
                {{ $t("home.demande_acces") }}
              </button>
              <span>.</span>
            </div>

            <div class="main-welcome-info">
              <ul>
                <li>
                  {{ $t("home.home_info_1") }}
                </li>
                <li>
                  {{ $t("home.home_info_2") }}
                </li>
                <li>
                  {{ $t("home.home_info_3") }}
                </li>
                <li>
                  {{ $t("home.home_info_4") }}
                </li>
                <!-- <li>
                  {{ $t("home.home_info_5") }}
                </li> -->
                <li>
                  {{ $t("home.home_info_6") }}
                </li>
              </ul>
            </div>
          </div>
          <!-- main-project -->
          <div v-else class="main-project">
            <!-- main-project-infos -->
            <div v-if="!moduleItemsActivated" class="main-project-infos">
              <div class="main-project-infos-cover">
                <div
                  v-if="projectSelectedConfig.infos"
                  class="main-project-infos-cover-text"
                >
                  <h1>{{ projectSelectedConfig.infos.title }}</h1>
                  <p>{{ projectSelectedConfig.infos.location }}</p>
                  <p class="main-project-infos-cover-text-method">
                    {{ projectSelectedConfig.infos.method }}
                  </p>
                  <p class="main-project-infos-cover-text-subtitle">
                    {{ projectSelectedConfig.infos.subtitle }}
                  </p>
                </div>
                <div
                  v-if="projectSelectedConfig.cover"
                  class="main-project-infos-cover-img"
                  :style="{
                    'background-image':
                      'url(' + projectSelectedConfig.cover + ')',
                  }"
                ></div>
              </div>
            </div>
            <!-- project-content -->
            <div v-else class="project-content">
              <div v-if="syncBarIsActive" class="project-content-syncBar">
                <button
                  class="project-content-syncBar-toggle-button"
                  v-on:click="syncBarToggleSync"
                >
                  <p v-if="!ChartsSyncOn" style="color: rgba(33, 175, 33, 1)">
                    Activer la synchronisation des graphiques
                  </p>
                  <p v-else style="color: rgba(255, 0, 0, 1)">
                    Désactiver la synchronisation des graphiques
                  </p>
                </button>
                <button
                  v-if="ChartsSyncOn"
                  class="project-content-syncBar-resetZoom-button"
                  v-on:click="syncBarResetZoom"
                >
                  reset zoom
                </button>
              </div>
              <HC1ResizeBar></HC1ResizeBar>
              <div
                v-for="module in moduleItemsActivated"
                :key="module.id"
                class="project-content-chart-wrapper"
              >
                <div
                  v-if="
                    module.module == 'meteo_analyse2' ||
                    module.module == 'tor_analyse' ||
                    module.module == 'geocubes_analyse' ||
                    module.module == 'extenso_analyse' ||
                    module.module == 'objets_t1' ||
                    module.module == 'objets_t2' ||
                    module.module == 'info_capts' ||
                    module.module == 'rfid_geophy' ||
                    module.module == 'inclino_analyse' ||
                    module.module == 'noise_analyse' ||
                    module.module == 'netrisk_analyse' ||
                    module.module == 'hydro_analyse'
                  "
                >
                <!-- <HC1ResizeBar></HC1ResizeBar> -->
                  <HC1
                    :apiCallUrl="apiCallUrl"
                    :module="module"
                    :item="module.item"
                    :type="module.type"
                    :chart_display="module.chart_display"
                    :project="projectSelectedConfig.data_name"
                    :sampling="module.sampling"
                    :series_show="module.series_show"
                    :sharedKey="sharedKey"
                  />
                </div>
                <div
                  v-else-if="module.module == 'sismo_dff_analyse'"
                >
                <div v-if="module.item.includes('png') == true">
                  <div v-for="url in module.type" :key="url" class="img_included">
                   <img
                    :src="url"
                    style="width: 100%"
                  />
                </div>
                </div>
                <div v-else>
                  <HC1
                    :apiCallUrl="apiCallUrl"
                    :module="module"
                    :item="module.item"
                    :type="module.type[0]"
                    :chart_display="module.chart_display"
                    :project="projectSelectedConfig.data_name"
                    :sampling="module.sampling"
                    :series_show="module.series_show"
                    :sharedKey="sharedKey"
                  />
                  <div v-for="url in module.type.slice(1)" :key="url" class="img_included">
                  <img
                    :src="url"
                    style="width: 100%"
                  />
                  </div>
                </div>
                </div>
                <div v-else-if="module.module == 'rfid_analyse'">
                  <GESTIONTOR v-if="module.item == 'tor'"
                    :module="module"
                    :project="projectSelectedConfig.data_name"
                    />
                  <inner-image-zoom v-else-if="module.item.includes('img') == true"
                    :src="projectSelectedConfig.img_rfid_info"
                    style="width: 90vw"
                  />
                  <inner-image-zoom v-else-if="module.item.includes('secteurs') == true"
                    :src="projectSelectedConfig.img_rfid_secteurs"
                    style="width: 90vw"
                  />
                  <AlerteRfid v-else-if="module.item == 'alertes_rfid'" :module="module" />
                  <HC1
                    v-else
                    :apiCallUrl="apiCallUrl"
                    :module="module"
                    :item="module.item"
                    :type="module.type"
                    :chart_display="module.chart_display"
                    :project="projectSelectedConfig.data_name"
                    :sampling="module.sampling"
                    :series_show="module.series_show"
                    :sharedKey="sharedKey"
                  />
                </div>
                <div
                  v-else-if="module.module == 'info_site'"
                  class="zoom"
                  style="margin-left: auto; margin-right: auto"
                >
                  <inner-image-zoom v-if="projectSelectedConfig.info_site"
                    :src="projectSelectedConfig.info_site"
                    style="width: auto"
                  />
                  <inner-image-zoom v-if="projectSelectedConfig.info_site2"
                    :src="projectSelectedConfig.info_site2"
                    style="width: auto"
                  />
                  <iframe v-if="projectSelectedConfig.info_site_pdf"
                    style="border:1px;solid;#666CCC;width: 99%;height:80vh"
                    title="PDF in an i-Frame"
                    zoom=72
                    :src="projectSelectedConfig.info_site_pdf"
                    frameborder="1"
                    scrolling="auto" >
                  </iframe>
                </div>
                <div
                  v-else-if="module.module == 'info_sismo'"
                >
                    <iframe v-if="module.item == 'info_dvv'"
                    style="border:1px;solid;#666CCC;width: 99%;height:80vh"
                    title="PDF in an i-Frame"
                    zoom=72
                    :src="projectSelectedConfig.info_dvv"
                    frameborder="1"
                    scrolling="auto" >
                    </iframe>

                    <iframe v-if="module.item == 'config_dvv'"
                    style="border:1px;solid;#666CCC;width: 99%;height:80vh"
                    title="PDF in an i-Frame"
                    zoom=72
                    :src="projectSelectedConfig.config_dvv"
                    frameborder="1"
                    scrolling="auto" >
                    </iframe>

                </div>


                <div v-else-if="module.module == 'vib'">
                  <LogTable v-if="module.item == 'vib_log'" :module="module"/>
                  <!-- <HC1ResizeBar></HC1ResizeBar> -->

                  <HC1
                    v-if="module.item.includes('vib_hist')"
                    :apiCallUrl="apiCallUrl"
                    :module="module"
                    :item="module.item"
                    :type="module.type"
                    :chart_display="module.chart_display"
                    :project="projectSelectedConfig.data_name"
                    :sampling="module.sampling"
                    :series_show="module.series_show"
                    :sharedKey="sharedKey"
                  />
                </div>
                <div v-else-if="module.module == 'sismo_geolithe'">
                  <!-- <HC1ResizeBar></HC1ResizeBar> -->
                  <inner-image-zoom v-if="module.item.includes('img') == true"
                    :src="projectSelectedConfig.img_kernel"
                    style="width: 90vw"
                  />
                  <HC1
                    v-else
                    :apiCallUrl="apiCallUrl"
                    :module="module"
                    :item="module.item"
                    :type="module.type"
                    :chart_display="module.chart_display"
                    :project="projectSelectedConfig.data_name"
                    :sampling="module.sampling"
                    :series_show="module.series_show"
                    :sharedKey="sharedKey"
                  />
                </div>
                <div v-else-if="module.module == 'sismo_analyse'">
                  <!-- <HC1ResizeBar></HC1ResizeBar> -->
                  <inner-image-zoom v-if="module.item.includes('img') == true"
                    :src="projectSelectedConfig.img_dvv_info"
                    style="width: 90vw"
                  />
                  <AlerteSismo v-else-if="module.item == 'alertes_sismo'" :module="module" />

                  <HC1
                    v-else
                    :apiCallUrl="apiCallUrl"
                    :module="module"
                    :item="module.item"
                    :type="module.type"
                    :chart_display="module.chart_display"
                    :project="projectSelectedConfig.data_name"
                    :sampling="module.sampling"
                    :series_show="module.series_show"
                    :sharedKey="sharedKey"
                  />
                </div>
                <div v-else-if="module.module == 'meteo_analyse'">
                  <!-- <HC1ResizeBar></HC1ResizeBar> -->
                  <AlerteMeteo v-if="module.item == 'alertes_meteo'" :module="module" />

                  <HC1
                    v-else
                    :apiCallUrl="apiCallUrl"
                    :module="module"
                    :item="module.item"
                    :type="module.type"
                    :chart_display="module.chart_display"
                    :project="projectSelectedConfig.data_name"
                    :sampling="module.sampling"
                    :series_show="module.series_show"
                    :sharedKey="sharedKey"
                  />
                </div>
                <div v-else-if="module.module == 'meteo_geolithe'">
                  <!-- <HC1ResizeBar></HC1ResizeBar> -->
                  <AlerteMeteo v-if="module.item == 'alertes_meteo'" :module="module" />

                  <HC1
                    v-else
                    :apiCallUrl="apiCallUrl"
                    :module="module"
                    :item="module.item"
                    :type="module.type"
                    :chart_display="module.chart_display"
                    :project="projectSelectedConfig.data_name"
                    :sampling="module.sampling"
                    :series_show="module.series_show"
                    :sharedKey="sharedKey"
                  />
                </div>
                <div v-else-if="module.module == 'hydro_analyse'">
                  <AlerteHydro v-if="module.item == 'alertes_piezo'" :module="module" />

                  <!-- <HC1ResizeBar></HC1ResizeBar> -->
                  <HC1
                    v-if="module.item.includes('piezos') == true"
                    :apiCallUrl="apiCallUrl"
                    :module="module"
                    :item="module.item"
                    :type="module.type"
                    :chart_display="module.chart_display"
                    :project="projectSelectedConfig.data_name"
                    :sampling="module.sampling"
                    :series_show="module.series_show"
                    :sharedKey="sharedKey"
                  />
                </div>
                <div v-else-if="module.module.includes('topo_analyse') == true">
                  <!-- <HC1ResizeBar></HC1ResizeBar> -->
                  <AlerteTopo v-if="module.item == 'alertes_topo'" :module="module" />

                  <HC1
                    v-else
                    :apiCallUrl="apiCallUrl"
                    :module="module"
                    :item="module.item"
                    :type="module.type"
                    :chart_display="module.chart_display"
                    :project="projectSelectedConfig.data_name"
                    :sampling="module.sampling"
                    :series_show="module.series_show"
                    :sharedKey="sharedKey"
                  />
                </div>
                <div v-else-if="module.module.includes('topo_geophy')">
                <iframe v-if="module.item.includes('pdf') == true"
                    :src="projectSelectedConfig.pdf_topo_info"
                    style="width: 99%;height:80vh"
                  />
                  <AlerteTopo v-else-if="module.item == 'alertes_topo'" :module="module"/>
                  <HC1
                    v-else
                    :apiCallUrl="apiCallUrl"
                    :module="module"
                    :item="module.item"
                    :type="module.type"
                    :chart_display="module.chart_display"
                    :project="projectSelectedConfig.data_name"
                    :sampling="module.sampling"
                    :series_show="module.series_show"
                    :sharedKey="sharedKey"
                  />
                </div>
                <div v-else-if="module.module == 'gestion_data'">
                    <GESTIONDATA
                    :module="module"
                    :project="projectSelectedConfig.data_name"
                  />
                </div>
                <div v-else-if="module.module == 'photo_analyse'">
                    <GESTIONPHOTO
                    :module="module"
                    :project="projectSelectedConfig.data_name"
                  />
                </div>
                <div v-else-if="module.module == 'alertes'">
                    <Alerte
                    :module="module"
                    :project="projectSelectedConfig.data_name"
                  />
                </div>
                <div v-else-if="module.module == 'download_data'">
                    <DOWNLOAD
                    :module="module"
                    :project="projectSelectedConfig.data_name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer class="footer">
        <div class="footer-content">
          <p
            v-if="projectSelectedConfig && projectSelectedConfig.disclaimer"
            class="footer-disclaimer"
          >
            {{ projectSelectedConfig.disclaimer }}
          </p>
          <!-- <div
            v-if="projectSelectedConfig && projectSelectedConfig.rimnat"
            class="footer-disclaimer"
          >
            <span
              >* Rim-Nat, registered trademark of GEOLITHE INNOV, is a
              collaborative 3D platform (features not included in this research
              project) - </span
            ><a
              href="https://rim-nat.com"
              target="_blank"
              rel="noopener nofollow noreferrer"
              >https://decouvrir.rim-nat.fr</a
            >
          </div> -->
        </div>
      </footer>
    </div>

    <div
      v-if="downloadModal"
      class="modal-download-wrapper"
      v-on:click.self="downloadModal = false"
    >
      <div class="modal-download">
        <div v-for="download in downloadModalLinks" :key="download.url">
          <div class="modal-download-link-wrapper">
            <button
              class="modal-download-link"
              v-on:click="downloader(download)"
              :class="{
                'modal-download-link-no-pointer':
                  activeDownload[projectSelected + ':' + download.url],
              }"
            >
              <p class="modal-download-link-text">{{ download.name }}</p>
              <div
                v-if="activeDownload[projectSelected + ':' + download.url]"
                class="modal-download-link-progress"
                :style="{
                  transform:
                    'translate(' +
                    activeDownload[projectSelected + ':' + download.url]
                      .progress +
                    '%, 0)',
                }"
              ></div>
            </button>
          </div>
        </div>
        <div class="modal-download-close" v-on:click="downloadModal = false">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import LogTable from "../components/LogTable";
import AlerteHydro from "../components/AlerteHydro";
import Alerte from "../components/Alerte";
import AlerteMeteo from "../components/AlerteMeteo";
import AlerteSismo from "../components/AlerteSismo";
import AlerteRfid from "../components/AlerteRfid";
import AlerteTopo from "../components/AlerteTopo";
import HC1 from "../components/HC1";
import HC1ResizeBar from "../components/HC1ResizeBar";
import GESTIONDATA from "../components/GESTIONDATA";
import GESTIONPHOTO from "../components/GESTIONPHOTO";
import DOWNLOAD from "../components/DOWNLOAD";
import GESTIONTOR from "../components/GESTIONTOR";
// import PhotoCompare from "../components/PhotoCompare";
import axios from "axios";
import { svg } from "../assets/svg";
import * as HC_service from "../services/dynamic_highcharts";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import InnerImageZoom from "vue-inner-image-zoom";
import JQuery from "jquery";
let $ = JQuery;

//
export default {
  name: "home",
  components: {
    Header,
    LogTable,
    AlerteHydro,
    Alerte,
    AlerteTopo,
    AlerteMeteo,
    AlerteSismo,
    AlerteRfid,
    HC1,
    HC1ResizeBar,
    GESTIONDATA,
    GESTIONPHOTO,
    DOWNLOAD,
    GESTIONTOR,
    InnerImageZoom,
  },
  data: function () {
    return {
      test: false,
      onlyOneIsActive: true,
      totalActive: 0,
      apiCallUrlBase: process.env.VUE_APP_API_DATA,
      apiCallGetProjects: process.env.VUE_APP_API_GET_PROJECTS,
      apiCallUrl: null,
      chartFontColor: "rgb(0, 0, 0)",
      chartGridColor: "rgba(0, 0, 0, 0.2)",
      chartAnnotationColor: "rgb(255, 0, 0)",

      // project / project config variables
      projectSelected: "",
      projectSelectedConfig: null,
      backConnectionError: false,
      projectSelectedInitModuleItem: null,

      userFirstName: "",
      userLastName: "",
      userProjectsRoles: {},
      userRimnatAccess: {},
      isAdmin: false,
      sharedKey: "",

      // module(s) variables
      modulesConfig: {}, // Object
      moduleItemsActivated: null, // Array
      moduleItemsActivatedPrev: null, // Array
      moduleItemsActivatedState: {}, // Object
      resizebar_display: "",
      // expandable variable(s)
      expandBlock: {},

      // toggleOn variable(s)
      toggleOn: {},

      // download modal variables
      downloadModal: false,
      downloadModalLinks: [],
      activeDownload: {},

      // ChartSync
      ChartsSyncOn: false, //<- active ou désactive la synchro data des graphs
      syncBarIsActive: false, // -> managed from -> watch/totalActive AND methods/ChartInitCallback
      ChartsSynchronized: [], //<- work ONLY for simple Chart Or Chart group (cannot see if this a multiple chart)
      ChartsSynchronizedTotal: 0,

      // scrollbarDetection variable(s)
      scrollbarIsActive: false,
      scrollbarDetectionSizeRef: null,
      scrollbarDetectionTimeOut: 0, //<- IMPORTANT -> +300ms inside AND for -> methods/expand function

      currentUrl: window.location


    };
  },
  watch: {
    authToken(token) {
      //   // As soon as the token arrives in the store we call the
      //   // function that furnished the list of projects accesible to
      this.updateProjectsAPI();
    },
    scrollbarIsActive(val) {
      HC_service.reflowAll();
    },
    ChartsSyncOn(val) {
      if (!val) {
        HC_service.sync.init(false);
      } else {
        HC_service.sync.init(true);
      }
    },
    totalActive: function (val) {
      let lastOnlyOneIsActive = this.onlyOneIsActive;
      if (this.totalActive > 1) {
        this.onlyOneIsActive = false;
      } else {
        this.onlyOneIsActive = true;
        if (this.ChartsSyncOn) {
          this.ChartsSyncOn = false;
        }
      }
      // force chart(s) resizing ( on toggle OFF second chart )
      if (this.onlyOneIsActive !== lastOnlyOneIsActive) {
        setTimeout(() => {
          HC_service.reflowAll();
          this.scrollbarDetection();
        }, this.scrollbarDetectionTimeOut);
      }
    },
    path(){
      // When path changes we assume that the projectSelect == the path
      // At the home the path is "", so projectSelected = ""
      this.projectSelected = this.path;
    },
    projectsScope(){

      // Wait until projectsScopes updates, then select the graph
      if (this.$route.name == "export-graph") {

        this.projectSelected = this.$route.params.project
        var mod = this.$route.params.module;
        var item = this.$route.params.item;
        var type = this.$route.params.type;
        var chart_display = this.$route.params.chart_display;
        this.resizebar_display = chart_display;
        var sampling = this.$route.params.sampling;
        var series_show = this.$route.params.series_show;
        this.sharedKey = this.$route.query.sharedKey

        // Set the module item to display on startup
        this.projectSelectedInitModuleItem = {
          id: mod + ":" + item,
          module: mod,
          item: item,
          type: type,
          chart_display: chart_display,
          sampling: sampling,
          series_show: series_show
        };

        // Hide some parts (header / nav)
        $(".col-header").hide();
        $(".col-nav").hide();
        $(".home-content").css("margin-left", "0");
        $(".home-content").css("margin-left", "0").css("padding-top", 0);

      }
      // If the route is add-user-projects we simply redirect to "add-user"
      // The idea is that the store needs to be initialised (if it does exist), or
      // preserved (if it exists already). The store is not persistent (this was a choice to
      // avoid problems with export-graph), so we need to first load home, fill up the store,
      // Then navigate programmatically to the Admin component. This is messy and inelegant,
      // and should be changed with a redesign of Home. The most straitforward option I think
      // is to make the store persisent and make export-graph independent of Home
      else if (this.$route.name == "add-user-projects") {
        let firstName = this.$route.query.FIRST_NAME
        if (typeof(firstName)== "undefined" ) {firstName = ""}

        let lastName = this.$route.query.LAST_NAME
        if (typeof(lastName)== "undefined" ) {lastName = ""}

        let email = this.$route.query.EMAIL
        if (typeof(email)== "undefined" ) {email = ""}

        let phoneNumber = this.$route.query.PHONE_NUMBER
        if (typeof(phoneNumber)== "undefined" ) {phoneNumber = ""}

        let company = this.$route.query.COMPANY
        if (typeof(company)== "undefined" ) {company = ""}

        let projects = this.$route.query.PROJECTS
        if (typeof(projects)== "undefined" ) {projects = ""}

        this.$router.push({ name: "add-user", query: { FIRST_NAME: firstName,
                                                      LAST_NAME: lastName,
                                                      EMAIL: email,
                                                      PHONE_NUMBER: phoneNumber,
                                                      COMPANY: company,
                                                      PROJECTS: projects} })

      }
      else {
      this.projectSelected = this.path;
      }

    },
    projectSelected: function (val) {
      if (this.$route.name != "export-graph"){
        this.$router.push({ path: "/" + val }).catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
        });
      }
      this.moduleItemsActivated = null;
      this.modulesConfig = {};
      this.expandBlock = {};
      this.toggleOn = {};
      // sync variable(s) resetting
      this.ChartsSyncOn = false;
      this.syncBarIsActive = false;
      // project module(s) AND nav variables creation
      let projectSelectedConfig = this.projectsScope.filter(
        (project) => project.data_name === val
      )[0];
      if (projectSelectedConfig) {
        let projectSelectedModules = projectSelectedConfig.modules;
        if (projectSelectedModules) {
          let toggleOnArray = [];
          projectSelectedModules.forEach((module) => {
            if (module.nav_expandable) {
              this.updateReactiveObjectProperty("expandBlock", [
                {
                  key: module.data_name,
                  value: false,
                },
              ]);
            }
            module.items.forEach((i) => {
              toggleOnArray.push({
                key: module.data_name + ":" + i.data_name,
                value: false,
              });
            });
            this.modulesConfig[module.data_name] = module;
          });
          this.updateReactiveObjectProperty("toggleOn", toggleOnArray);
        }
      }
      // get the new project config

      this.projectSelectedConfig = this.projectsScope.filter(
        (project) => project.data_name === val
      )[0];
      //console.log("config du projet " + val + " : ", this.projectSelectedConfig);

      // Load module on startup
      if (this.projectSelectedInitModuleItem != null) {
        this.moduleItemSelector(
          this.projectSelectedInitModuleItem.modulename,
          this.projectSelectedInitModuleItem.module,
          this.projectSelectedInitModuleItem.itemname,
          this.projectSelectedInitModuleItem.item,
          this.projectSelectedInitModuleItem.type,
          this.projectSelectedInitModuleItem.chart_display,
          this.projectSelectedInitModuleItem.sampling,
          this.projectSelectedInitModuleItem.series_show
        );
      }
    },
    moduleItemsActivated: function (val) {
      // process(s) function(s)
      const toggleButtonOff = () => {
        if (this.moduleItemsActivatedPrev) {
          const selectedArray = val || [];
          this.moduleItemsActivatedPrev.forEach((i) => {
            let itemIsActive = selectedArray.find(
              (element) => element.id === i.id
            );
            if (!itemIsActive) {
              // clear loading source
              if (this.moduleItemsActivatedState[i.id].loadingSource) {
                this.moduleItemsActivatedState[i.id].loadingSource.cancel({
                  canceled: "Operation canceled by the user.",
                });
              }
              // update ChartsSynchronized
              this.ChartsSynchronizer("remove", i.module + ":" + i.item);
              // this.moduleItemsActivatedState update
              this.updateReactiveObjectProperty("moduleItemsActivatedState", [
                {
                  key: i.id,
                  delete: true,
                },
              ]);
              // toggle button off
              this.toggleOnOff(i.module + ":" + i.item, false);
              // this.totalActive update
              if (this.totalActive) {
                this.totalActive -= 1;
              }
            }
          });
        }
      };
      // main process
      let lastItemActivated = null;
      if (val) {
        // PUSH VERSION
        //lastItemActivated = val[val.length-1];
        // UNSHIFT VERSION
        lastItemActivated = val[0];
        if (!this.moduleItemsActivatedState[lastItemActivated.id]) {
          this.totalActive += 1;
          let activeModuleConfig = this.projectSelectedConfig.modules.find(
            (element) => element.data_name === lastItemActivated.module
          );
          this.moduleItemsActivatedState[lastItemActivated.id] = {
            loading: true,
            loadingSource: null,
            loadingError: null,
          };
          // toggle button on
          this.toggleOnOff(
            lastItemActivated.module + ":" + lastItemActivated.item,
            true
          );

          // axios get data
            let series_show = activeModuleConfig.series_show
          if (this.projectSelectedInitModuleItem != null){
              series_show = this.projectSelectedInitModuleItem.series_show;

          }

          this.apiCallUrl =
            this.apiCallUrlBase +
            "/" +
            lastItemActivated.module +
            "/" +
            this.projectSelected +
            "/" +
            lastItemActivated.item +
            "/" +
            lastItemActivated.sampling +
            "/" +
            series_show;

          const chartArray = [];

          let moduleItemsActivatedStatePayload = [
            {
              key: "loading",
              value: false,
            },
            {
              key: "loadingSource",
              value: null,
            },
            {
              key: "charts",
              value: chartArray,
            },
          ];
          this.updateReactiveObjectProperty("moduleItemsActivatedState", [
            {
              key: lastItemActivated.id,
              value: moduleItemsActivatedStatePayload,
            },
          ]);
        }
        // toggle button off
        toggleButtonOff();
        // moduleItemsActivatedPrev reset and build to bypass reference to the same Array "val"... do not -> this.moduleItemsActivatedPrev = val;... if object see -> https://vuejs.org/v2/guide/reactivity.html
        this.moduleItemsActivatedPrev = [];
        val.forEach((item) => {
          this.moduleItemsActivatedPrev.push(item);
        });
        //console.log("this.moduleItemsActivatedState: ", this.moduleItemsActivatedState);
      } else {
        // toggle button off
        toggleButtonOff();
        this.moduleItemsActivatedPrev = null;
      }
    },
    downloadModal: function (val) {
      if (!val) {
        this.downloadModalLinks = [];
      }
    },
  },
  methods: {
    // Nick : API call to get json config for all projects for which the user is authorized
    updateProjectsAPI() {
      this.backConnectionError = false; // Reset connection error
      axios
        .get((this.apiCallGetProjects), {
          params: {
            email: this.$store.state.user.email,
          },
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        })
        .then((projects) => {
          if (projects.data === "") {
          axios
          .post(
            process.env.VUE_APP_API_UPDATE_DATABASE,
            {
              projects: {"Soilstab": ["Viewer"]},
              client_first_name: "",
              client_last_name: "",
              client_phone_number:'',
              client_company: "",
              client_email: projects.config.params.email,
              admin_email: 'alexandra.royer@geolithe.com'
            },
            {
              headers: { Authorization: `Bearer ${this.$store.state.token}` },
            }
          )
          .then(() => {
            this.success = true; // à mettre sur OFF pour les tests
                  axios
        .get((this.apiCallGetProjects), {
          params: {
            email: this.$store.state.user.email,
          },
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        })
        .then((projects) => {
          this.$store.dispatch(
            "updateProjectsScope",
            projects.data.projects_scope
          );
          })
          })
          .catch((error) => {
            //console.error(error.response.data);
            let data = error.response.data;
            if (data.errors && data.errors.length) {
              data.errors.forEach((d) => {
                if (this.inputsList.includes(d.input)) {
                  this.$refs["input-" + d.input].classList.add("errored");
                  this.errors.push(d.msg);
                }
              });
            } else if (data.error) {
              this.errors.push(data.error);
              // CUSTOM PART
              for (let i = 0; i < this.inputsList.length; i++) {
                this.$refs["input-" + this.inputsList[i]].classList.add(
                  "errored"
                );
              }
            }
            if (this.errors.length) {
              this.errored = true;
            }
          })
          .finally(() => {
            this.$el.style.pointerEvents = "auto";
            this.loading = false;
          });
          } else {
          this.$store.dispatch(
            "updateProjectsScope",
            projects.data.projects_scope
          );
          (this.userProjectsRoles = projects.data.user_projects_roles),
            (this.userFirstName = projects.data.user_first_name);
          this.userLastName = projects.data.user_last_name;
          this.userCompany = projects.data.user_company;
          this.isAdmin = projects.data.is_admin == "true";
          this.userRimnatAccess = projects.data.user_rimnat_access;
          const ProjectsByName = (targetName) =>
              projects.data.projects_scope.find(({ name }) => name === targetName);
          if (projects.data.projects_scope === undefined || ProjectsByName('Soilstab') === undefined ) {
          axios
          .post(
            process.env.VUE_APP_API_UPDATE_DATABASE,
            {
              projects: {"Soilstab": ["Viewer"]},
              client_first_name: this.userFirstName,
              client_last_name: this.userLastName,
              client_phone_number: this.userPhoneNumber ??= '0',
              client_company: this.userCompanyName ??= "",
              client_email: this.$store.state.user.email,
              admin_email: 'alexandra.royer@geolithe.com'
            },
            {
              headers: { Authorization: `Bearer ${this.$store.state.token}` },
            }
          )
          .then(() => {
            this.success = true; // à mettre sur OFF pour les tests
                  axios
        .get((this.apiCallGetProjects), {
          params: {
            email: this.$store.state.user.email,
          },
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        })
        .then((projects) => {
          this.$store.dispatch(
            "updateProjectsScope",
            projects.data.projects_scope
          );
          })
          })
          .catch((error) => {
            //console.error(error.response.data);
            let data = error.response.data;
            if (data.errors && data.errors.length) {
              data.errors.forEach((d) => {
                if (this.inputsList.includes(d.input)) {
                  this.$refs["input-" + d.input].classList.add("errored");
                  this.errors.push(d.msg);
                }
              });
            } else if (data.error) {
              this.errors.push(data.error);
              // CUSTOM PART
              for (let i = 0; i < this.inputsList.length; i++) {
                this.$refs["input-" + this.inputsList[i]].classList.add(
                  "errored"
                );
              }
            }
            if (this.errors.length) {
              this.errored = true;
            }
          })
          .finally(() => {
            this.$el.style.pointerEvents = "auto";
            this.loading = false;
          });
          }
        }
        })
        .catch((error) => {
            console.error(error);
            this.backConnectionError = true;
        })
    },
    getDataAPI(apiCallUrl) {
      return axios.get(apiCallUrl, {
        headers: {
          Authorization: `Bearer ${this.$store.state.token}`,
          "Cache-Control": "no-cache",
        },
      });
    },
    login() {
      this.$auth.loginWithRedirect();
    },
    updateReactiveObjectProperty(oldObjName, properties) {
      let oldObj = this[oldObjName];
      let newObj = oldObj;
      if (!properties) {
        for (let name in newObj) {
          delete newObj[name];
        }
      } else {
        properties.forEach((i) => {
          if (newObj[i.key]) {
            if (i.delete) {
              delete newObj[i.key];
            } else {
              if (Array.isArray(i.value)) {
                i.value.forEach((j) => {
                  newObj[i.key][j.key] = j.value;
                });
              } else {
                newObj[i.key] = i.value;
              }
            }
          } else {
            newObj[i.key] = i.value;
          }
        });
      }
      this[oldObjName] = Object.assign({}, this[oldObjName], newObj);
    },
    goToHome() {
      this.projectSelected = "";
      this.$router.push({ name: "home" });
      this.$router.go();
    },
    scrollbarDetection() {
      // from width VERSION (most efficient)
      let bodyE = document.body;
      let htmlE = document.documentElement;
      let appWidth = Math.min(
        bodyE.offsetWidth,
        htmlE.clientWidth,
        htmlE.offsetWidth,
        window.innerWidth
      );
      if (!this.scrollbarDetectionSizeRef) {
        this.scrollbarDetectionSizeRef = appWidth;
      }
      if (
        appWidth !== this.scrollbarDetectionSizeRef &&
        appWidth < this.scrollbarDetectionSizeRef &&
        !this.scrollbarIsActive
      ) {
        this.scrollbarIsActive = true;
      } else if (
        appWidth !== this.scrollbarDetectionSizeRef &&
        appWidth >= this.scrollbarDetectionSizeRef &&
        this.scrollbarIsActive
      ) {
        this.scrollbarIsActive = false;
      }
      this.scrollbarDetectionSizeRef = appWidth;
    },
    goToAskProject(e) {
      e.preventDefault();
      this.$router.push({ name: "askproject" });
    },
    goToAskQuote(route) {
      this.$router.push({ name: route });
    },
    expand(entry) {
      if (!this.expandBlock[entry]) {
        this.updateReactiveObjectProperty("expandBlock", [
          {
            key: entry,
            value: true,
          },
        ]);
      } else {
        this.updateReactiveObjectProperty("expandBlock", [
          {
            key: entry,
            value: false,
          },
        ]);
      }
      setTimeout(() => {
        this.scrollbarDetection();
      }, this.scrollbarDetectionTimeOut + 300);
    },
    toggleOnOff(item, val) {
      this.updateReactiveObjectProperty("toggleOn", [
        {
          key: item,
          value: val,
        },
      ]);
    },
    scrollYReset() {
      if (window.innerWidth > window.innerHeight) {
        // <- mode portrait ONLY
        let scrollY = window.scrollY || 0;
        //console.log("scrollY: ", scrollY);
        let screenHeight =
          Math.max(window.screen.height, window.innerHeight) || 0;
        if (scrollY > screenHeight / 3) {
          // more infos -> https://developer.mozilla.org/fr/docs/Web/API/Element/scrollTo
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    },
    moduleItemSelector(modulename, module,itemname, item, type, chart_display, sampling, series_show) {
      if (!this.moduleItemsActivated) {
        this.moduleItemsActivated = [];
        this.moduleItemsActivatedPrev = [];
      }
      let currentId = module + ":" + item;

      let itemRequested = this.moduleItemsActivated.find(
        (element) => element.id === currentId
      );
      if (!itemRequested) {
        // items_switch process
        if (this.modulesConfig[module].items_switch) {
          let itemSwitchFound = this.moduleItemsActivated.find(
            (element) => element.module === module
          );
          if (itemSwitchFound) {
            this.moduleItemsActivated.splice(
              this.moduleItemsActivated.indexOf(itemSwitchFound),
              1
            );
          }
        }

        // UNSHIFT VERSION
        this.moduleItemsActivated.unshift({
          id: currentId,
          modulename: modulename,
          module: module,
          itemname:itemname,
          item: item,
          type: type,
          chart_display: chart_display,
          sampling: sampling,
          series_show: series_show
        });
        this.scrollYReset(); //<- NEW for UNSHIFT VERSION
      } else {
        // remove item
        this.moduleItemsActivated.splice(
          this.moduleItemsActivated.indexOf(
            this.moduleItemsActivated.find(
              (element) => element.id === currentId
            )
          ),
          1
        );
      }
      if (!this.moduleItemsActivated.length) {
        this.moduleItemsActivated = null;
      }
    },
    showDownloadModal(currentDownloadLinks) {
      if (!this.downloadModal) {
        this.downloadModal = true;
        this.downloadModalLinks = currentDownloadLinks;
      }
    },
    downloader(linkConfig) {
      const initLoading = () => {
        axios
          .get(linkConfig.url, {
            headers: { Authorization: `Bearer ${this.$store.state.token}` },
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              let downloadProgress =
                (progressEvent.loaded / progressEvent.total) * 100;
              downloadProgress = downloadProgress.toFixed();
              if (this.activeDownload[activeDownloadId]) {
                this.updateReactiveObjectProperty("activeDownload", [
                  {
                    key: activeDownloadId,
                    value: {
                      progress: downloadProgress,
                    },
                  },
                ]);
              }
            },
          })
          .then((res) => {
            const blob = res.data;
            const contentDisposition = res.headers["content-disposition"];
            let fileName = "unknown.unknown";
            if (contentDisposition) {
              fileName = contentDisposition.split("=")[1];
            }
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.setAttribute("target", "_blank");
              link.setAttribute("rel", "noopener noreferrer");
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(url);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            if (this.activeDownload[activeDownloadId]) {
              this.updateReactiveObjectProperty("activeDownload", [
                {
                  key: activeDownloadId,
                  delete: true,
                },
              ]);
            }
          });
      };
      let activeDownloadId = this.projectSelected + ":" + linkConfig.url;
      if (!this.activeDownload[activeDownloadId]) {
        this.updateReactiveObjectProperty("activeDownload", [
          {
            key: activeDownloadId,
            value: {
              progress: 0,
            },
          },
        ]);
        initLoading();
      }
    },
    ChartsSynchronizer(action, chartOrItemId) {
      // get the right chart from the chartId OR charts from the itemId
      let currentCharts = [];
      this.ChartsSynchronized.forEach((i) => {
        if (i.id === chartOrItemId || i.item_activated_id === chartOrItemId) {
          currentCharts.push(i);
        }
      });
      // action === "add" process
      if (action === "add") {
        currentCharts.forEach((i) => {
          if (!i.isActive) {
            i.isActive = true;
            this.ChartsSynchronizedTotal += 1;
            let container = i.container;
            if (typeof i.container === "string") {
              container = document.getElementById(container);
            }
            ["mousemove", "touchmove", "touchstart"].forEach(function (
              eventType
            ) {
              container.addEventListener(eventType, HC_service.sync.process);
            });
            container = null;
          }
        });
      } else if (action === "remove") {
        currentCharts.forEach((i) => {
          i.isActive = false;
          this.ChartsSynchronizedTotal -= 1;
          let container = i.container;
          if (typeof i.container === "string") {
            container = document.getElementById(container);
          }
          ["mousemove", "touchmove", "touchstart"].forEach(function (
            eventType
          ) {
            container.removeEventListener(eventType, HC_service.sync.process);
          });
          this.ChartsSynchronized.splice(
            this.ChartsSynchronized.indexOf(
              this.ChartsSynchronized.find((element) => element.id === i.id)
            ),
            1
          );
          container = null;
        });
      }
      // this.syncBarIsActive pass
      if (this.ChartsSynchronizedTotal > 1 && !this.syncBarIsActive) {
        this.syncBarIsActive = true;
      } else if (this.ChartsSynchronizedTotal <= 1 && this.syncBarIsActive) {
        this.syncBarIsActive = false;
      }
    },
    syncBarToggleSync(e) {
      e.preventDefault();
      if (this.ChartsSyncOn) {
        this.ChartsSyncOn = false;
      } else {
        this.ChartsSyncOn = true;
      }
    },
    syncBarResetZoom(e) {
      e.preventDefault();
      HC_service.resetZoom();
    },

    // transition vue.js PART
    ts_beforeEnter: function (el) {
      el.style.transition = "all 0.6s ease";
      el.style.maxHeight = 0;
      el.style.transform = "translateX(2rem)";
      el.style.opacity = 0;
    },
    ts_enter: function (el, done) {
      let elHeight = el.scrollHeight;
      //console.log("elHeight: ", elHeight);
      el.style.maxHeight = elHeight + "px";
      el.style.transform = "translateX(0)";
      el.style.opacity = 1;
      setTimeout(() => {
        done();
      }, 600);
    },
    ts_afterEnter: function (el) {},
    ts_enterCancelled: function (el) {},
    ts_beforeLeave: function (el) {},
    ts_leave: function (el, done) {
      el.style.maxHeight = 0;
      el.style.transform = "translateX(2rem)";
      el.style.opacity = 0;
      setTimeout(() => {
        done();
      }, 600);
    },
    ts_afterLeave: function (el) {},
    ts_leaveCancelled: function (el) {},
    openRimnatViewer: function () {
      axios
        .get(process.env.VUE_APP_API_GET_RIMNAT_ACCESS, {
          params: {
            email: this.$store.state.user.email,
            project_id: this.userRimnatAccess[this.projectSelected],
          },
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        })
        .then((rimnatAccess) => {
          if (rimnatAccess.data.url != "") {
            var win = window.open(rimnatAccess.data.url, "_blank");
            win.focus();
          } else {
            alert("Accès impossible");
          }
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isAuthenticated() {
      return (
        typeof this.$store.state.user !== "undefined" &&
        this.$store.state.user.email != null
      );
    },
    authToken() {
      // This token is set in action.js, by a function called in the created hook of App.vue
      return this.$store.state.token;
    },
    projectsScope() {
      return this.$store.state.projectsScope;
    },
    path() {
      // Calculate the current path from the router. We take of the leading slash with substring
      return this.$route.path.substring(1);
    },
    svg() {
      return svg;
    },
    hasRimnatAccess() {
      return (
        this.projectSelected != "" &&
        Object.prototype.hasOwnProperty.call(this.userRimnatAccess, this.projectSelected)
      );
    },
    projectfiltersoilstab() {
      return this.projectsScope.filter(d => d.name ==="Soilstab")
    },
  },
  filters: {
    capitalize(value) {
      if (!value) {
        return "";
      } else {
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
    },
  },
  created: function () {

  },
  mounted: function () {
    this.scrollbarDetection();
    if (this.$route.name == "export-graph") {
      this.apiCallUrlBase = process.env.VUE_APP_API_DATA_SHARED
      this.apiCallGetProjects = process.env.VUE_APP_API_GET_PROJECTS_SHARED
      // This is a dummy address, it isn't used in the back. I impose the email address in the
      // back for security, i.e. a call to the unsecured route will only give the project details
      // for the "shared_link@geolithe.com" user in the db. But getProjectsAPI sends an email address anyway,
      // so this allows us to use the method getProjectsAPI unchanged
      var user = {email: 'shared_link@geolithe.com'}
      this.$store.dispatch('updateUser', user)
      this.updateProjectsAPI();

    }

    // if (this.$route.name == "home-project") {
    //   console.log(this.$route.path)
    //   this.projectSelected = 'vialay';
    //   // this.$nextTick(function () {
    //   //   this.projectSelected = 'vialay';
    //   // })
    // }
  },
};
</script>

<style scoped>
/*---------------------------------------------------------------- WRAPPER */
.wrapper {
  /* background -> App.vue -> .router-wrapper */

  /* - regular version - */
  width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  word-break: break-word;
}
/*---------------------------------------------------------------- main-shadow */
.main-shadow {
  box-shadow: -0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.4);
}
/*---------------------------------------------------------------- toggle-button */
.toggle-button {
  background: rgb(59, 59, 59);
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  border-radius: 2em;
  transition: 0.4s;
}
.toggle-button-activated {
  background: #409dc2;
}
.toggle-button-indicator {
  background: rgb(207, 207, 207);
  display: block;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-100%, -50%);
  border-radius: 50%;
  transition: 0.4s;
  box-shadow: -0.2vh 0.2vh 2vh rgba(0, 0, 0, 0.8);
}
.toggle-button-indicator-activated {
  transform: translate(-0%, -50%);
}
/*---------------------------------------------------------------- MODAL- download */
.modal-download-wrapper {
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  word-break: break-word;
  z-index: 4;
}
.modal-download {
  background: rgba(255, 255, 255, 1);
  display: flex;
  width: auto;
  height: auto;
  box-shadow: -0.2vh 0.2vh 2vh rgba(0, 0, 0, 0.2);
  padding: 4rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.modal-download-close {
  background: red;
  display: flex;
  width: 3rem;
  height: 3rem;
  position: absolute;
  left: 100%;
  top: 0;
  transform: translate(-100%, 0);
  padding: 0.8rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.modal-download-link-wrapper {
  display: flex;
  width: 14rem;
  height: 2rem;
  position: relative;
  padding: 0.2rem;
  justify-content: center;
  align-items: center;
}
.modal-download-link-no-pointer {
  pointer-events: none;
  cursor: default;
}
.modal-download-link {
  background: rgba(0, 0, 0, 0.4);
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  padding: 0.2rem;
  overflow: hidden;
  color: white;
}
.modal-download-link:hover {
  background: rgba(0, 0, 0, 0.2);
}
.modal-download-link-text {
  position: relative;
  z-index: 2;
}
.modal-download-link-progress {
  background: rgba(15, 157, 88, 0.8);
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  transform: translate(0, 0);
  z-index: 1;
}
/*---------------------------------------------------------------- HOME */
.img_included {
  width: 100%;
  display: inline-flex;
}

.home-content {
  /*display: grid;*/
  background: #f8f8f8;
  height: auto;
  min-height: 100vh;
  margin-left: 300px;
  padding-top: 46px;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out,
    margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
}
.nav-closed .home-content {
  margin-left: 0px;
}
.header {
  grid-area: header;
}
.nav {
  grid-area: nav;
}
.main {
  grid-area: main;
}
.footer {
  grid-area: footer;
}
/* .header-content -> BECOME -> Header component */
.col-nav {
  background: #fff;
  -webkit-box-shadow: 0 -15 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 -15 15px 0 rgba(0, 0, 0, 0.05);
  position: absolute;
  width: 300px;
  z-index: 1;
  margin-top: 50px;
  position: fixed;
  height: calc(100% - 50px);
  overflow: auto;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out,
    margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
}
.nav-closed .col-nav {
  margin-left: -400px;
}
.nav-content {
  display: block;
  width: 100%;
  padding: 1rem;
}

/* TOP VERSION */
.main-content {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: relative;
  flex-direction: column;
  word-break: break-word;
  /*
  padding: 2rem;
  padding-top: 1rem;
  */
  padding: 1rem;
}
.footer-content {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-direction: column;
  color: black;
}
/*-------------------------------- (header-content) */
/* .header-content -> BECOME -> Header component */
/*-------------------------------- (footer-content) */
.footer-disclaimer {
  opacity: 0.75;
  font-size: 0.75rem;
}
/*-------------------------------- (nav-content) */
/*---------------- project-selector */
.project-selector {
  display: block;
  width: 100%;
  height: 2rem;
}
.project-selector select {
  width: 100%;
  height: 100%;
  border: none;
  font: inherit;
  cursor: pointer;
}
/*---------------- project-ask-access */
.project-ask-access {
  background: rgba(130, 130, 130, 1);
  display: block;
  width: 100%;
  height: auto;
  min-height: 2rem;
  position: relative;
  margin-bottom: 0.8rem;
  color: white;
  border-radius: 4px;
  padding: 10px 15px;
}
.project-ask-access:hover {
  background: rgba(120, 120, 120, 1);
}
/*---------------- project-nav */
.project-nav {
  display: flex;
  flex-direction: column;
}
.project-nav-item {
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 0.8rem;
  padding: 0.4rem;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  /* IMPORTANT -> .project-nav-module !== .project-nav-item ... même si ils sont très similaires */
}
/*---- module(s) */
.project-nav-modules {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}
.project-nav-module {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  /* overflow: hidden -> used for  -> nav-expandable */
  overflow: hidden;
  padding-top: 0.4rem;
}
.project-nav-module-content {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}
.project-nav-module-title-wrapper {
  background: rgba(0, 0, 0, 0.4);
  color: white;
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;

  /* IMPORTANT -> la version expandable === .project-nav-module-item-expander */
}
.project-nav-module-title {
  font-family: "Roboto Bold";
}
.project-nav-module-subtitle {
  font-size: 0.85rem;
}
.project-nav-module-items {
  background: rgba(255, 255, 255, 0.4);
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.project-nav-module-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  font-size: 0.85rem;
}
.project-nav-module-global-download {
  background: rgba(130, 130, 130, 1);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  margin-top: 0.4rem;
  font-size: 0.85rem;
  color: white;
  border-radius: 2px;
}
.project-nav-module-global-download:hover {
  background: rgba(130, 130, 130, 0.8);
}
.project-nav-item-download {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  position: relative;
  margin-left: 0.8rem;
}
/* nav-expandable */
.project-nav-module-item-expander {
  background: #409dc2; /*#9fc240*/
  color: white;
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  border-radius: 4px;
  padding: 10px 15px;
}
.project-nav-module-item-expander:hover {
  background: #207ba0; /*#9fc240*/
}
.project-nav-module-item-expander-arrow {
  display: flex;
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-100%, -50%) rotate(0deg);
  pointer-events: none;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  transition: transform 0.5s ease;
  opacity: 0.8;
}
.project-nav-module-item-expander-arrow-close {
  transform: translate(-100%, -50%) rotate(90deg);
}
/*
.project-nav-module-item-expander-arrow, svg {
  stroke: red;
}
*/
/*
.project-nav-module-expandable-block {
}
*/
/*---- partners */
.project-nav-partners {
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}
/*---- project-nav-partners-img */
.project-nav-partners-img-1-1 {
  width: 3rem;
  height: 3rem;
  margin: 0.1rem;
}
.project-nav-partners-img-2-1 {
  width: 6rem;
  height: 3rem;
  margin: 0.1rem;
}
.project-nav-partners-img-4-1 {
  width: 12rem;
  height: 3rem;
  margin: 0.1rem;
}
/*---- rimnat */
.project-nav-rimnat {
  height: 3rem;
  background: rgba(0, 0, 0, 0.4);
  color: black;
  border-radius: 4px;
  cursor: pointer;
}
.project-nav-rimnat:hover {
  background: #8bb517;
}
.project-nav-rimnat-logo {
  display: block;
  width: 100%;
  /*height: 100%;*/
  height: 200%;
  position: relative;
  pointer-events: none;
}
/*-------------------------------- (main-content) */
/*---------------- main-welcome */
.main-welcome {
  flex-grow: 1;
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-welcome-text {
  padding: 1rem,1rem,0rem,1rem;
  position: relative;
  text-align: center;
  display: inline-block;
}

.main-welcome-info {
  padding: 1rem;
  position: relative;
  /* text-align: center; */
  border-radius: 4px;
  box-shadow: -2px 3px 3px #bbb;
  background-color: #f1f1f1;
  line-height: 20px;
  font-size: 12px;
  color: #333;
  margin: 50px 40px;
  width: 97%;
}
.main-welcome-info ul {
  margin-bottom: 0;
}
.main-welcome-info li {
  margin-bottom: 8px;
  padding-left: 5px;
  margin-left: 5px;
}

.main-welcome-text-ask-project {
  background: rgba(15, 157, 88, 1);
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.main-welcome-text-ask-project:hover {
  background: rgba(15, 157, 88, 0.4);
}
.main-welcome-svg {
  display: inline-block;
  width: 80%;
  height: 10rem;
  position: relative;
}
/*---------------- main-project */
.main-project {
  /*flex-grow: 1;*/ /* commented for main-content TOP VERSION */
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/*---- main-project-infos */
.main-project-infos {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
}
.main-project-infos-cover {
  display: block;
  width: 100%;
  /*height: calc(100vh - 12rem);*/ /* .home-content VERSION -> grid-template-rows: 4rem 1fr 4rem; */
  height: calc(
    100vh - 10rem
  ); /* .home-content VERSION -> grid-template-rows: 3rem 1fr 3rem; */
  min-height: 22rem;
  position: relative;
  box-shadow: -0.2vh 0.2vh 2vh rgba(0, 0, 0, 0.4);
}
.main-project-infos-cover-text {
  background: rgba(0, 0, 0, 0.4);
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -100%);
  z-index: 2;
  padding: 0rem;
  pointer-events: none;
  color: white;
}
.main-project-infos-cover-text-method {
  margin-top: 0.4rem;
  font-size: 1.1rem;
}
.main-project-infos-cover-text-subtitle {
  margin-top: 0.4rem;
}
.main-project-infos-cover-img {
  /* tag -> img */
  /*
  width: 100%;
  height: auto;
  object-fit: cover;
  */
  /* tag -> div */
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}
/*---- project-content  */
.project-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: relative;
  justify-content: center;
}
.project-content-loading {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.project-content-loading-spinner {
  display: block;
  width: 6rem;
  height: 6rem;
  position: relative;
}
.project-content-loading-text {
  margin-top: 0.8rem;
}
.project-content-error {
  text-align: center;
  font-weight: bold;
  color: red;
  padding: 1rem;
}
/* Chart(s) wrapper(s) */
.project-content-chart-wrapper {
  flex-grow: 1;
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  flex-direction: column;
}
.img.iiz__img {
  width: 100%
}

img {
  width: 100%
}

.project-content-chart {
  display: flex;
  justify-self: center;
  align-self: center;
  width: 100%;
  height: auto;
  position: relative;
  flex-wrap: wrap;
  justify-content: flex-start;
}
/* Chart(s) component-wrapper(s) */
.chart-component-wrapper {
  display: block;
  width: 100%;
  min-width: 0; /* REQUIRED FOR Chart.js -> https://github.com/chartjs/Chart.js/issues/5744 */
  height: auto;
  position: relative;
  /*border: solid 0.1rem white;*/
}

.chart-component-wrapper-flex-row-half-d1 {
  width: 100%;
  height: calc(50vh - 4rem);
  min-height: 11rem;
}
.chart-component-wrapper-flex-col-tier-d1 {
  width: 33.33%;
  height: calc(40vh - 4rem);
  min-height: 22rem;
}
.chart-component-wrapper-flex-row-full-d2 {
  width: 100%;
  height: calc(100vh - 8rem);
  min-height: 22rem;
}
.chart-component-wrapper-flex-row-half-d2 {
  /*background: blue;*/
  width: 100%;
  height: calc(50vh - 5rem);
  min-height: 11rem;
}
.chart-component-wrapper-flex-row-half-d3 {
  width: 100%;
  height: calc(50vh - 5rem);
  min-height: 11rem;
}
/* NEED to verify d3 quart AND d4 col tier */
.chart-component-wrapper-flex-row-quart-d3 {
  width: 100%;
  height: calc(30vh - 3rem);
  min-height: 11rem;
}
.chart-component-wrapper-flex-row-tier-d4 {
  width: 100%;
  height: calc(33vh - 3.5rem);
  min-height: 11rem;
}
.chart-component-wrapper-flex-col-tier-d4 {
  width: 33.33%;
  height: calc(40vh - 2rem);
  min-height: 11rem;
}

.chart-component-wrapper-flex-col-tier-d5 {
  width: 33.33%;
  height: calc(48vh - 2rem);
  min-height: 11rem;
}

.chart-component-wrapper-flex-col-tier2-d5 {
  width: 33.33%;
  height: calc(35vh - 2rem);
  min-height: 11rem;
}

.chart-component-wrapper-flex-col-half-d6 {
  width: 50%;
  height: calc(48vh - 2rem);
  min-height: 11rem;
}

.chart-component-wrapper-flex-col-half2-d6 {
  width: 50%;
  height: calc(40vh - 2rem);
  min-height: 11rem;
}

.chart-component-wrapper-flex-col-full-d7 {
  width: 100%;
  height: calc(48vh - 2rem);
  min-height: 11rem;
}

.chart-component-wrapper-flex-col-full2-d7 {
  width: 100%;
  height: calc(48vh - 2rem);
  min-height: 11rem;
}

.chart-component-wrapper-flex-col-full-d8 {
  width: 100%;
  height: calc(48vh - 2rem);
  min-height: 11rem;
}

.chart-component-wrapper-flex-col-half2-d8 {
  width: 50%;
  height: calc(35vh - 2rem);
  min-height: 11rem;
}

/* Chart(s) component(s) */
.chart-component {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.project-content-syncBar {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  /*justify-content: flex-end;*/
  justify-content: space-between;
  pointer-events: none;
  margin-bottom: 1rem;
  padding: 0.4rem;
}
.project-content-syncBar-toggle-button {
  pointer-events: auto;
}
.project-content-syncBar-resetZoom-button {
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}
p {
  margin-bottom: 0rem;
}

/* -------------------------------------------- PORTRAIT */
@media screen and (orientation: portrait) {
  /* .home-content {
    grid-template-columns: 1fr;
    grid-template-rows: 4rem auto 1fr 4rem;
    grid-template-areas:
      "header"
      "nav"
      "main"
      "footer"
    ;
  }*/
}

.bloc_project_soilstab {
  color: #444;
  font-size: 24px;
  text-align: center;
  background: #fff;
  background-color: #409dc2;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: 0 1px 3px #aaa;
  padding: 5px;
  margin: 20px 0px 5px 0px;
}
.bloc_project_soilstab:hover {
  border: 1px solid #409dc2;
  box-shadow: 0 1px 3px #409dc2;
  background: #fafafa;
  cursor: pointer;
}

.bloc_project {
  color: #444;
  font-size: 24px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: 0 1px 3px #aaa;
  padding: 15px;
  margin: 5px 5px 15px 5px;
  float: left;
  width: 100%;
  transition: box-shadow 0.3s ease-in-out;
}
.bloc_project:hover {
  border: 1px solid #409dc2;
  box-shadow: 0 1px 3px #409dc2;
  background: #fafafa;
  cursor: pointer;
}
.bloc_project .pic {
  border: 1px solid #f1f1f1;
  border-radius: 80px;
  height: 150px;
  width: 150px;
  text-align: center;
  line-height: 150px;
  float: left;
  margin-right: 25px;
  overflow: hidden;
}
.bloc_project .pic img {
  width: 100%;
  height: 100%;
  /* float: left;
  margin-left: -25%;
  margin-top: -25%; */
}

.bloc_project .parameter {
  margin-top: 5px;
  font-size: 15px;
  color: #666;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}
.bloc_project .parameter .icon {
  height: 14px;
  width: 14px;
  margin-right: 10px;
}
h4 {
  color: #555;
  letter-spacing: 1px;
}
/* h4::after {
  border-bottom: 2px solid #409dc2;
  width: 35px;
  content: " ";
  display: block;
  height: 2px;
  margin: 7px 0 25px;
} */
.spec_vol_ext {
  font-size: 14px;
  border:2px solid black;
}
.tab {
       display:inline-block;
       margin-left: 80px;
}
.box-redirection {
    background-color: #f96666;
    font-size: 20px;

}
.URL {
  color: white
}
</style>
