<template>
<div>
                  <div class="bandeau_col_full">
          <p> {{ module.modulename }} - {{ module.itemname }} </p>
        </div>
        <highcharts
          v-for="options in optionsList"
          :key="options.title.text"
          :options="options"
          ref="highcharts"
          class="chart_line_full_col_full2"
        ></highcharts></div>
</template>

<script>

import Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import Highcharts3d from "highcharts/highcharts-3d";
import exportingInit from "highcharts/modules/exporting";
import vectorInit from 'highcharts/modules/vector'
import HeatMapInit from 'highcharts/modules/heatmap'
import offlineExporting from "highcharts/modules/offline-exporting";
import stockInit from "highcharts/modules/stock";
import Annotation from "../../node_modules/highcharts/modules/annotations.js";
import customEvent from "highcharts-custom-events";
import { Chart } from "highcharts-vue";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import { eventBus } from "../main";
import JQuery from "jquery";
import boost from "highcharts/modules/boost";
import _ from "lodash";
import { svg } from "../assets/svg";
let $ = JQuery;

HeatMapInit(Highcharts)
vectorInit(Highcharts);
Highcharts3d(Highcharts);
exportingInit(Highcharts);
offlineExporting(Highcharts);
more(Highcharts);
stockInit(Highcharts);
Annotation(Highcharts);
customEvent(Highcharts);
boost(Highcharts);


    (function (H) {
        var defaultPlotOptions = H.getOptions().plotOptions,
            columnType = H.seriesTypes.column,
            each = H.each;

        defaultPlotOptions.xrange = H.merge(defaultPlotOptions.column, {});
        H.seriesTypes.xrange = H.extendClass(columnType, {
            type: 'xrange',
            parallelArrays: ['x', 'x2', 'y'],
            animate: H.seriesTypes.line.prototype.animate,

            /**
             * Borrow the column series metrics, but with swapped axes. This gives free access
             * to features like groupPadding, grouping, pointWidth etc.
             */
            getColumnMetrics: function () {
                var metrics,
                    chart = this.chart,
                    swapAxes = function () {
                        each(chart.series, function (s) {
                            var xAxis = s.xAxis;
                            s.xAxis = s.yAxis;
                            s.yAxis = xAxis;
                        });
                    };

                swapAxes();

                this.yAxis.closestPointRange = 1;
                metrics = columnType.prototype.getColumnMetrics.call(this);

                swapAxes();

                return metrics;
            },
            translate: function () {
                columnType.prototype.translate.apply(this, arguments);
                var series = this,
                    xAxis = series.xAxis,
                    metrics = series.columnMetrics;

                H.each(series.points, function (point) {
                    var barWidth = xAxis.translate(H.pick(point.x2, point.x + (point.len || 0))) - point.plotX;
                    point.shapeArgs = {
                        x: point.plotX,
                        y: point.plotY + metrics.offset,
                        width: barWidth,
                        height: metrics.width
                    };
                    point.tooltipPos[0] += barWidth / 2;
                    point.tooltipPos[1] -= metrics.width / 2;
                });
            }
        });

        /**
         * Max x2 should be considered in xAxis extremes
         */
        H.wrap(H.Axis.prototype, 'getSeriesExtremes', function (proceed) {
            var axis = this,
                dataMax = Number.MIN_VALUE;

            proceed.call(this);
            if (this.isXAxis) {
                each(this.series, function (series) {
                    each(series.x2Data || [], function (val) {
                        if (val > dataMax) {
                            dataMax = val;
                        }
                    });
                });
                if (dataMax > Number.MIN_VALUE) {
                    axis.dataMax = dataMax;
                }
            }
        });
    }(Highcharts));


export default {
      components: {
    highcharts: Chart
  },
  props: ["module","project"],
  data() {
    return {
        optionsList: [],
        result: null
    };
  },
  mounted() {
  },
      created() {
        this.getLog();
    },
  methods: {
      getLog(){
        axios.get(process.env.VUE_APP_API_GET_TIMEDATA, {
            params:{project: this.$parent.projectSelected,capteurs: this.module.item},
                            headers: {Authorization: `Bearer ${this.$store.state.token}`},
        })
        .then((activity) => {
            this.optionsList = activity.data.datasets.map((dataset, i) => {
            dataset.title = dataset.name
            dataset.yaxis = activity.data.yAxis
            dataset.data = dataset.series;
              dataset.data.forEach((set, index) => {
                set.data = set.data.map((val, j) => {
                    let correctedDate = new Date(activity.data.xAxis[0].categories[j]); 
                    let correctedDate_0 = correctedDate.getTime();
                      return [correctedDate_0, val];
                    })
                  })
            return this.genOptions(dataset);
            })
            this.loading = false;
            });
      },  
genOptions(dataset) {
    return {

        chart: {
            type: 'column'
        },
        credits: {
            enabled: false,
          },
        title: {
            text: 'Tout-Ou-Rien: 0 --> OK, 1--> pas OK'
        },
        xAxis: {
            type: 'datetime',
        },
        yAxis: dataset.yaxis,
        legend: {
            enabled: false,
          },
        series: dataset.data
}
}
}

};
</script>
<style>
.chart_line_full_col_full2 {
  width: 100%;
  height: calc(90vh - 2rem);
  min-height: 11rem;
  display: inline-block;
}
</style>