<template>
  <div>

    <b-form-group label-cols="2"
                  label-align="right"
                  label-text-align="right"
                  label-size="lg"
                  label="Appareil Photo :"
                  label-for="site-selection">
      <b-form-select id="site-selection" v-model="selectedSite" :options="sites"></b-form-select>
    </b-form-group>

    <b-row >
      <b-col cols="6">
        <b-card  bg-variant="light"  class="text-center" footer="Veuillez cliquer sur une vignette pour changer la photo ci-dessous">
          <template #header>
            <b-form-group label-cols="5" label-text-align="center"
              label-size="lg" label="Photo de gauche : " label-for="input-date-left">
              <b-form-datepicker
                id="input-date-left"
                v-model="dateLeft"
                @input="setPhotosChangeDate"
                label-close-button="Fermer"
                label-no-date-selected="Aucune date selectionée"
                label-current-month="Mois en cours"
                label-prev-month="Mois précédent"
                label-next-month="Mois prochain"
                label-prev-year="Année précédente"
                label-next-year="Année prochaine"
                label-help="Aucune photo n'est disponible pour les dates grisées"
                reset-button
                close-button
                :date-disabled-fn="dateDisabled"
                locale="fr">
              </b-form-datepicker>
            </b-form-group>
              Photos disponible pour {{dateLeft}} :
          </template>
          <b-row style="overflow-x:scroll; white-space: nowrap;">
            <table  >
              <th sm v-for="photoMetadata in thumbnailsLeft" :key="photoMetadata.url"
                style="display: inline-block; float: none;">
                <tr>
                  <img alt="" :title="photoMetadata.photo_time" :src="photoMetadata.url"
                    v-on:mousedown="selectedPhotoLeft=photoMetadata" onerror="this.style.display='none'"
                  />
                </tr>
                <tr>
                  {{photoMetadata.photo_time}}
                </tr>
              </th>
            </table>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card  bg-variant="light"  class="text-center" footer="Veuillez cliquer sur une vignette pour changer la photo ci-dessous">
          <template #header>
            <b-form-group label-cols="5"
              label-text-align="center"
              label-size="lg"
              label="Photo de droite : "
              label-for="input-date-right"
              >
              <b-form-datepicker
                id="input-date-right"
                v-model="dateRight"
                @input="setPhotosChangeDate"
                label-close-button="Fermer"
                label-no-date-selected="Aucune date selectionée"
                label-current-month="Mois en cours"
                label-prev-month="Mois précédent"
                label-next-month="Mois prochain"
                label-prev-year="Année précédente"
                label-next-year="Année prochaine"
                label-help="Aucune photo n'est disponible pour les dates grisées"
                reset-button
                close-button
                :date-disabled-fn="dateDisabled"
                locale="fr">
              </b-form-datepicker>
            </b-form-group>
            Photos disponible pour {{dateRight}} :
          </template>
          <b-row style="overflow-x:scroll; white-space: nowrap;">
            <table>
              <th sm v-for="photoMetadata in thumbnailsRight" :key="photoMetadata.url"
                style="display: inline-block; float: none;">
                <tr>
                  <img alt="" :title="photoMetadata.photo_time" :src="photoMetadata.url"
                    v-on:mousedown="selectedPhotoRight=photoMetadata" onerror="this.style.display='none'"
                  />
                </tr>
                <tr>
                  {{photoMetadata.photo_time}}
                </tr>
              </th>
            </table>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-form-group
      label="Traitement d'image :"
      label-for="traitement-buttons"
      label-align="right"
      label-cols="5">

      <b-form-radio-group
        id="traitement-buttons"
        v-model="imageTreatment"
        :options="imageTreatmentsAll_diff"
        class="pt-2"
        value-field="process"
        text-field="processName"
        disabled-field="notEnabled"
        v-if="module.series_show == true"
      ></b-form-radio-group>
        <b-form-radio-group
        id="traitement-buttons"
        v-model="imageTreatment"
        :options="imageTreatmentsAll"
        class="pt-2"
        value-field="process"
        text-field="processName"
        disabled-field="notEnabled"
        v-else
      ></b-form-radio-group>

    </b-form-group>
    <PhotoCompare
      offset="0.5"
      keyboardStep="0.1"
      :selectedPhotoLeft="selectedPhotoLeft"
      :selectedPhotoRight="selectedPhotoRight"
      :imageTreatment="imageTreatment"
    />

  </div>
</template>

<script>

import axios from 'axios';
import PhotoCompare from "../components/PhotoCompare"
  export default {
    name: "GESTIONPHOTO",
    props: ["module","project"],
    components: {PhotoCompare},
    data() {
      return {
      photosMetadata: [], // Priniple data object, array of objects containing photo metadata for all photos in db
      selectedPhotoLeft: {}, // Photo selected by clicking on thumbnail (passed to PhotoCompare)
      selectedPhotoRight: {}, // Photo selected by clicking on thumbnail (passed to PhotoCompare)
      dateLeft:"", // Date of selected left photo
      dateRight: "", // Date of selected right photo
      sites: this.$parent.modulesConfig.photo_analyse.rawdata_folder,
      selectedSite:this.$parent.modulesConfig.photo_analyse.selected_site, // Load large format photos from this site initially
      referencePhotoDeltaJ: this.$parent.modulesConfig.photo_analyse.referencePhotoDeltaJ,  // The "reference" photo is the large format photo on the right. We want to go X days back
      // in time to get it. If we don't have a photo from X days ago we chose the closest in time.
      imageTreatment: "Aucun", // Image treatment by default
      imageTreatmentsAll: [
          { process: 'Aucun', processName: 'Aucun' },
          { process: 'Correlation', processName: 'Correlation', notEnabled: true },
          { process: 'Vector', processName: 'Vector', notEnabled: true}
        ],
        imageTreatmentsAll_diff: [
          { process: 'Aucun', processName: 'Aucun' },
          { process: 'difference', processName: 'Difference'},
          { process: 'Correlation', processName: 'Correlation', notEnabled: true },
          { process: 'Vector', processName: 'Vector', notEnabled: true}
        ]
      }
    },
    mounted() {
      },
    created() {
    this.getLogPhoto(); // Load photo log on creation
    },
    watch: {
      dateLeft(){
        // Update thumbnails when display dates change
        this.updateThumbnails("left")
      },
      dateRight(){
         // Update thumbnails when display dates change
        this.updateThumbnails("right")
      },
      selectedSite(){
        // Change big photos and thumbnails after changing selected site
        this.setPhotosNewSite()
      }
    },
    computed: {
      thumbnailsLeft(){
        // Array of photo objects correspond to the given date and site, sorted earlier to later in time
        // Note: the date in the photo object is in the format YYYY/MM/DD, but we keep the format for
        // dateLeft, dateRight, etc as YYYY-MM-DD to be compatible with the calendar
        return this.photosMetadata.filter(photo => photo.photo_date === this.dateLeft.replaceAll('-', '/')
                                                && photo.site_name === this.selectedSite)
                                                .sort((a, b) => a.photo_time.localeCompare(b.photo_time));
      },
      thumbnailsRight(){
        // Array of photo objects correspond to the given date and site, sorted earlier to later in time
        return this.photosMetadata.filter(photo => photo.photo_date === this.dateRight.replaceAll('-', '/')
                                                && photo.site_name === this.selectedSite)
                                                .sort((a, b) => a.photo_time.localeCompare(b.photo_time));
      }
    },
    methods: {
      dateDisabled(ymd) {
        // This grays out the dates in the calendar for which we don't have photos for the site that is selected
        let date = ymd.replaceAll('-', '/');
        let site = this.selectedSite;
        let filtered = this.photosMetadata.filter(function(photoMetadata)  {return photoMetadata.photo_date === date &&
                                                                           photoMetadata.site_name === site})
        // Return `true` if the date should be disabled
        return filtered.length === 0
      },
      getThumbnail(photo_name) {
          // get thumbnail from back, add it to the photo object
          axios.get(process.env.VUE_APP_API_GET_PHOTO_THUMBNAIL, {
              params:{photo_name: photo_name},
              headers: {Authorization: `Bearer ${this.$store.state.token}`},
              responseType: 'blob'
          })
          .then((photo)  => {
            let photoObj = this.photosMetadata.find(x => x.photo_filname === photo_name);
            let idx = this.photosMetadata.findIndex(x => x.photo_filname === photo_name);
            photoObj.url = URL.createObjectURL(photo.data);
            // This is necessary to force Vue to make the changes in the template as the thumbnails arrive
            // because normally an array in not reactive if we simply set the value with the index, like :
            // this.photosMetadata[idx].url = URL.createObjectURL(photo.data);

            this.$set(this.photosMetadata, idx, photoObj); // Set array for reactivity
          })
      },
      updateThumbnails(side) {
        // Get thumbnails for all photo objects that don't already have them
        if (side === "left"){
          this.thumbnailsLeft.forEach( (photo) => {
          if (photo.url === "")
            {this.getThumbnail(photo.photo_filname);}
          })
        }
        else if (side === "right") {
          this.thumbnailsRight.forEach( (photo) => {
          if (photo.url === "")
            {this.getThumbnail(photo.photo_filname);}
          })
        }
      },
      datetimeString2DatetimeObject(dateString){
        // Helper function to convert the date string to a date object year, month, day, hour, minute
        let dateComponents = dateString.split(/[\s / :]+/);
        dateComponents = dateComponents.map((dateComp) => parseInt(dateComp))
        // The "-1" is necessary b/c the month is zero indexed (e.g. January == 0)
        return new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2],
                        dateComponents[3], dateComponents[4], 0)
      },
      setPhotosChangeDateEachSide(site, newDate, selectedPhoto){
          // Set large photo after date change
          let filtered = this.photosMetadata.filter(function(photoMetadata)
                                                    {return photoMetadata.site_name === site
                                                    && photoMetadata.photo_date === newDate});

          // Get a list of datetime string for the new date, and sort them
          let datetimes = filtered.map(photo => photo.photo_date + " " + photo.photo_time);
          datetimes.sort().reverse();

          // Convert th list of datetime strings to date objects
          let dateObjectsNewDate = datetimes.map(date => this.datetimeString2DatetimeObject(date))

          // Get the datetime object of the current photo (at the old date)
          let dateObjectCurrent = this.datetimeString2DatetimeObject(
                                selectedPhoto.photo_date + " " + selectedPhoto.photo_time)

          // Calculate the diff in minutes between the time of the old photo and all the photos on the new date
          // Note: we are ignoring the date here, we just care about the time of day
          let diffMinutes = dateObjectsNewDate.map(dateobj =>
                Math.abs( (dateobj.getHours() * 60 + dateobj.getMinutes() )
                        - (dateObjectCurrent.getHours() * 60 + dateObjectCurrent.getMinutes())
                        ) );

          // Get the index of the photo that has the least difference in time-of-day with respect to the old photo
          let idx = diffMinutes.indexOf(Math.min(...diffMinutes));

          // Extract the time from the photo object that minimises the diff in time-of-day
          let time = datetimes[idx].split(" ")[1];

          // Set the selected photo base on these criteria, site, new date, time
          return this.photosMetadata.filter(function(photoMetadata)
                                                    {return photoMetadata.site_name === site
                                                    && photoMetadata.photo_date === newDate
                                                    && photoMetadata.photo_time === time})[0]

      },
      setPhotosChangeDate(newDate) {
        // When we change the date we want to change the large photo as well. We chose the photo
        // that has the closest time to the previous photo. So as we change the day the time stays
        // more or less constant
        let site = this.selectedSite;

        // For the left photo
        if (newDate === this.dateLeft){
          // The dateLeft has already changed to the new date
          let newDate = this.dateLeft.replaceAll('-','/');
          this.selectedPhotoLeft = this.setPhotosChangeDateEachSide(site, newDate, this.selectedPhotoLeft)

        }
        // For the right photo
        else if (newDate === this.dateRight){
          // The dateRight has already changed to the new date
          let newDate = this.dateRight.replaceAll('-','/');
          this.selectedPhotoRight = this.setPhotosChangeDateEachSide(site, newDate, this.selectedPhotoRight)
        }
      },
      setPhotosNewSite(){
        // When we change the site we want to change the large photos as well. We chose the photos
        // that have the closest date/times to the previous photos. For example the use case could be
        // switching sites to observe an event from several different angles

        let site = this.selectedSite; // The newly selected site

        // Get a list of the datetime string available for the new site, sort them
        let filtered = this.photosMetadata.filter(function(photoMetadata)
                                                  {return photoMetadata.site_name === site});
        let datetimes = filtered.map(photo => photo.photo_date + " " + photo.photo_time);
        datetimes.sort().reverse();

        // Convert datestrings to date objects
        let dateObjectsNewSite = datetimes.map(date => this.datetimeString2DatetimeObject(date))

        // Get the date objects of the current left and right photos
        let dateObjectLeft = this.datetimeString2DatetimeObject(
                              this.selectedPhotoLeft.photo_date + " " + this.selectedPhotoLeft.photo_time)
        let dateObjectRight = this.datetimeString2DatetimeObject(
                                this.selectedPhotoRight.photo_date + " " + this.selectedPhotoRight.photo_time)

        // Find the index of the date the minimises the delta T with respect to the current photos
        let diffMillisecondsLeft = dateObjectsNewSite.map(dateobj => Math.abs(dateobj - dateObjectLeft));
        let diffMillisecondsRight = dateObjectsNewSite.map(dateobj => Math.abs(dateobj - dateObjectRight));
        let idxLeft = diffMillisecondsLeft.indexOf(Math.min(...diffMillisecondsLeft));
        let idxRight = diffMillisecondsRight.indexOf(Math.min(...diffMillisecondsRight));

        // Extract the date strings and time strings for the photo objects that minimise the delta T
        let dateLeft = datetimes[idxLeft].split(" ")[0];
        let timeLeft = datetimes[idxLeft].split(" ")[1];
        let dateRight = datetimes[idxRight].split(" ")[0];
        let timeRight = datetimes[idxRight].split(" ")[1];

        // Set the current photo dates
        this.dateLeft = dateLeft.replaceAll('/','-');
        this.dateRight = dateRight.replaceAll('/','-');

        // Set the selected photos
        this.selectedPhotoLeft = this.photosMetadata.filter(function(photoMetadata)
                                                  {return photoMetadata.site_name === site
                                                  && photoMetadata.photo_date === dateLeft
                                                  && photoMetadata.photo_time === timeLeft})[0];

        this.selectedPhotoRight = this.photosMetadata.filter(function(photoMetadata)
                                                  {return photoMetadata.site_name === site
                                                  && photoMetadata.photo_date === dateRight
                                                  && photoMetadata.photo_time === timeRight})[0];
        // If we don't change the date while changing the site (normally the case, except for missing data)
        // then we need to update the thumbnails (b/c the watch on the dateLet, dateRight isn't trigggered)
        this.updateThumbnails("left")
        this.updateThumbnails("right")
      },
      setInitialPhotos(){
        // Set initial photos for photoCompare
        // The left photo is taken as the most recent, the right photo is the reference photo
        // We would like to take the reference photo to be referencePhotoDeltaJ days befre the present, but we may not
        // have a photo for that days, so we choose the photo closest to that date

        // Extract date strings for the given site and sort them present => past
        let site = this.selectedSite
        let filtered = this.photosMetadata.filter(function(photoMetadata)
                                                  {return photoMetadata.site_name === site});
        let dates = filtered.map(photo => photo.photo_date);
        dates.sort().reverse();

        // Convert the array of date strings to date objects so we can properly calculate the delta T
        let dateObjects = dates.map(date => {
            let dateComponents = date.split("/");
            dateComponents = dateComponents.map((dateComp) => parseInt(dateComp))
            // The "-1" is necessary b/c the month is zero indexed (e.g. January == 0)
            return new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2])
        })

        // Find the index of the date the minimises the delta T for the target date
        let deltaT =  this.referencePhotoDeltaJ * 24 * 60 *60 * 1000; // subtraction of date objects is in milliseconds
        let diffMilliseconds = dateObjects.map(dateobj => Math.abs(dateobj - dateObjects[0] + deltaT));
        let idx = diffMilliseconds.indexOf(Math.min(...diffMilliseconds))

        let mostRecent = dates[0];
        let refDate = dates[idx]
        this.dateLeft = mostRecent.replaceAll('/','-');
        this.dateRight = refDate.replaceAll('/','-');
        // Get photos that match the given dates and site
        let leftPhotos = this.photosMetadata.filter(function(photoMetadata)  {return photoMetadata.photo_date === mostRecent &&
                                                                           photoMetadata.site_name === site})
        let rightPhotos = this.photosMetadata.filter(function(photoMetadata)  {return photoMetadata.photo_date === refDate &&
                                                                           photoMetadata.site_name === site})

        // Choose the middle photo in the array, which is likely to be close to noon
        // This is less fragile than hard-coding the idex, but be could try harder to match the time
        let idxLeft = Math.floor(leftPhotos.length / 2)
        let idxRight = Math.floor(rightPhotos.length / 2)


        this.selectedPhotoLeft = leftPhotos[idxLeft]
        this.selectedPhotoRight = rightPhotos[idxRight]
      },
      getLogPhoto(){
          // Read in the photo metadata from the db
          axios.get(process.env.VUE_APP_API_GET_PHOTO_LOG, {
              params:{project: this.$parent.projectSelected},
                              headers: {Authorization: `Bearer ${this.$store.state.token}`},
          })
          .then((photoLog) => {

            // Initialize photo metadata (array of photo objects)
            this.photosMetadata = photoLog.data.map(JSON.parse).map((photoObj) => Object.assign(photoObj, {url: ""}));
            this.setInitialPhotos()
          })
        }
      }
  }
</script>

<style>
  table {
    display: table;
    width: 100%;
  }
p.button-table {
  font-size: 0.7rem;
  margin: 0;
  width: 37px
}
.icon-center {
  text-align:center !important;
}
.log-table {
  background: #fff;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 4px 25px 0 rgba(0,0,0,.1);
}
.bandeau_col_full {
  width: 100%;
  height: 30px;
  background-color:#409dc2;
  font-family: "Roboto Bold";
  color: white;
  text-align: center;
  margin-top:5px;
  margin-bottom:5px;
  border-radius: 5px;
  border: solid 2px;
}
th,td{
  text-align:center;
  border:1px solid #ccc;
  margin:0;
  padding:10px;
}
th{
  background:#ccc;
}
.example {
  background: #f2f2f2;
  border: 1px solid #ddd;
  padding: 0em 1em 1em;
  margin-bottom: 2em;
}

</style>
