<template>
  <div class="wrapper">

    
    <header  class="header">
      <Header/>
    </header>

    <div class="not-admin" v-if="!SiteAdmin">
      <br>You must be an administrator to access this site
    </div>

    <div class="not-admin" v-if="SiteAdmin">
      <main class="main">

        
        <div class="add-projects">
        <div v-if="success" class="success">
          <svg class="success-svg" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline stroke="rgb(33, 175, 33)" points="22 4 12 14.01 9 11.01"></polyline></svg>
          <p class="success-message">Evorisk user database was successfully updated</p>
        </div>
        <div v-if="success_email" class="success">
          <svg class="success-svg" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline stroke="rgb(33, 175, 33)" points="22 4 12 14.01 9 11.01"></polyline></svg>
          <p class="success-message">An email was sent to the client to inform them of their updated access rights</p>
        </div>
          <b-container fluid>
            <div>
              <b-jumbotron lead="EVORISK Admin - Add project access right to users" 
                           bg-variant="info" text-variant="white" border-variant="dark">
                <p>
                  Modify and submit the form below to add project access rights. <br> 
                  Note that only valid project names will be submitted <br>
                  If a project name is not valid you can copy the correct project name 
                  from the list of current projects
                </p>
              </b-jumbotron>
            </div>
            <b-button v-if="!mode403" v-on:click="goToAdmin" class="back-btn">Return to Evorisk admin</b-button>

            <b-card-group deck class="mb-3">


              <div class="col-sm-6">
                <b-card title="Info user">
                  <b-input-group>
                    <b-input-group-append>
                      <b-input-group-text>First Name:</b-input-group-text>
                      <!-- <label for="firstName">First Name:</label> -->
                      <b-form-input readonly></b-form-input>
                      <b-form-input 
                        id="firstName"
                        v-model="askerFirstName"
                        :state="askerFirstName.length > 0"
                        aria-describedby="firstName-help firstName-invalid"
                        placeholder="Enter the first name of the client"
                        trim>
                      </b-form-input>
                      <b-form-invalid-feedback id="firstName-invalid">
                        Enter a first name
                      </b-form-invalid-feedback>
                    </b-input-group-append>

                    <b-input-group-append>
                      <b-input-group-text>Last Name:</b-input-group-text>
                      <b-form-input readonly></b-form-input>
                      <b-form-input
                        id="lastName"
                        v-model="askerLastName"
                        :state="askerLastName.length > 0"
                        aria-describedby="lastName-invalid lastName-help"
                        placeholder="Enter the last name of the client"
                        trim>
                      </b-form-input>

                      <b-form-invalid-feedback id="lastName-invalid">
                        Enter a last name
                      </b-form-invalid-feedback>
                    </b-input-group-append>

                    <b-input-group-append>
                      <b-input-group-text>Phone Number:</b-input-group-text>
                      <b-form-input readonly></b-form-input>
                      <b-form-input
                        id="phoneNumber"
                        v-model="askerPhonenumber"
                        :state="askerPhonenumber.length > 0"
                        aria-describedby="phonenumber-invalid phonenumber-help"
                        placeholder="Enter the phone number of the client"
                        trim>
                      </b-form-input>

                      <b-form-invalid-feedback id="phonenumber-invalid">
                        Enter a phone number
                      </b-form-invalid-feedback>
                    </b-input-group-append>

                    <b-input-group-append>  
                      <b-input-group-text>Company</b-input-group-text>
                      <b-form-input readonly></b-form-input>
                      <b-form-input
                        id="companyName"
                        v-model="askerCompany"
                        :state="askerCompany.length > 0"
                        aria-describedby="company-invalid companyName-help"
                        placeholder="Enter the company name of the client"
                        trim>
                      </b-form-input>

                      <b-form-invalid-feedback id="companyName-invalid">
                        Enter a the name of a company or organization
                      </b-form-invalid-feedback>
                    </b-input-group-append>

                    <b-input-group-append>
                      <b-input-group-text>Email:</b-input-group-text>
                      <b-form-input readonly></b-form-input>
                      <b-form-input
                        id="emailAddress"
                        v-model="askerEmail"
                        :state="askerEmail.length > 0"
                        type="email"
                        aria-describedby="emailAddress-invalid emailAddress-help"
                        placeholder="Enter the email address of the client"
                        trim>
                      </b-form-input>

                      <b-form-invalid-feedback id="emailAddress-invalid">
                        Enter an email <address></address>
                      </b-form-invalid-feedback>
                    </b-input-group-append>
                  </b-input-group>
                </b-card>
              </div>

              <div class="col-sm-5">
                <b-card title="Add access rights to user">
                  <!-- <b-row class="my-1" v-for="(project, idx) in askerProjects" :key="project" :id="project"> -->
                        <!-- <b-col sm="7">  -->       
                    <!-- <div role="group"> -->
                      <b-input-group>
                        <b-input-group-append v-for="(project, idx) in askerProjects" :key="project" :id="project">
                      <b-input-group-text>Project:</b-input-group-text>
                      <!-- <b-row> -->
                        <!-- <b-col class="col-sm-1 "></b-col> -->
                        <b-form-input
                          v-model="projectsToSubmit[idx]"
                          :state="allProjects.includes(projectsToSubmit[idx])"
                          aria-describedby="input-live-help input-live-feedback"
                          placeholder="Enter your project name"
                          trim
                          >
                        </b-form-input>

                       
                          <b-form-select 
                          v-model="rolesSelected[idx]" :options="roles">
                          </b-form-select>
                        
                      <!-- </b-row> -->

                      <b-form-invalid-feedback>
                        Enter VALID project name
                      </b-form-invalid-feedback>
                      </b-input-group-append>
                        
                      <!-- <b-form-text>Name of requested project</b-form-text> -->
                    <!-- </div> -->
<!--                     </b-col>
                  </b-row> -->

                  <!-- <b-row class="my-1" > -->
                    <!-- <b-col sm="7"> -->
                  <button v-on:click="submit" class="submit" type="submit">
                    <span>Submit</span>
                    <span v-if = loading class="spinner-w">
                      <Spinner v-if = loading />
                    </span>
                  </button>
                   <!-- </b-col> -->
                  <!-- </b-row> -->
                  </b-input-group>
                </b-card>
              </div>

              <div class="col-sm-4">
                <b-card title="Current projects">
                  <b-input-group v-for="valueWrapper in allProjects" :key="valueWrapper">
                    <b-input-group-text>{{valueWrapper}}</b-input-group-text>
                    <b-form-input readonly></b-form-input>
                    <b-input-group-append>
                      <b-button squared
                        v-clipboard="valueWrapper"
                        >Copy to clipboard</b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-textarea
                    id="textarea"
                    v-model="texttest"
                    placeholder="copy projects"
                    rows="1"
                    max-rows="1"
                  ></b-form-textarea>
                </b-card>
              </div>


              <div class="col-sm-4">
                <b-card title="Send email to user">
                  <b-input-group v-for="type in alltypes" :key="type.name">
                    <!-- <b-form-input readonly></b-form-input> -->
                    <b-input-group-append>
                      <b-input-group-text>{{type.what}}</b-input-group-text>
                      <b-form-input readonly></b-form-input>
                      <b-button squared
                        @click="selectedType=type.num">{{type.num}}</b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-row class="my-1" >
                    <b-col sm="7">
                  <button v-on:click="SendEmailUser" class="submit" type="submit">
                    <span>Send Email to User</span>
                  </button>
                   </b-col>
                  </b-row>
                </b-card>
              </div>

              <div class="col-sm-4">
                  <b-form-textarea
                    id="textarea"
                    v-model="body"
                    placeholder="copy type text for visualisation..."
                    rows="10"
                    max-rows="10"
                  ></b-form-textarea>

                </div>
            </b-card-group>

            <div v-if="errored && errors && errors.length" class="error-zone">
              <p v-for="error in errors" :key="error">* {{error}}</p>
            </div>
         
            </b-container>


        </div>        
      </main>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import { svg } from '../assets/svg'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BIconArrowReturnLeft } from 'bootstrap-vue';

export default {
  name: "AskProject",
  components: {
    Header,
		Spinner
	},
  data: function () {
    return {
      success_email: false,
      selectedType: null,
      updater: 0,
      success: false,
      loading: false,
      errored: false,
      errors: [],
      isSiteAdmin: false,


      askerFirstName: this.$route.query.FIRST_NAME, 
      askerLastName: this.$route.query.LAST_NAME, 
      askerPhonenumber: this.$route.query.PHONE_NUMBER, 
      askerEmail: this.$route.query.EMAIL, 
      askerCompany: this.$route.query.COMPANY, 
      askerProjects: this.$route.query.PROJECTS.split(','), 
      allProjects: [],
      projectsToSubmit: this.$route.query.PROJECTS.split(','),

      roles: [],
      rolesSelected: Array(this.$route.query.PROJECTS.split(',').length),

      inputsList: ["project"],
      inputs: {
        project: ""
      },
      mode403: false,
      texttest: "",
      alltypes : [
        {
        name: "type1",
        num :1,
         what: "accès à Evorisk avec projets",
          },
                {
        name: "type2",
        num:2,
         what: "accès à Evorisk sans projet",
        },
                {
        name: "type3",
        num:3,
         what: "pas d'accés à Evorisk",
        }
        ]
    }
  },
  methods: {
    goToAdmin(){
            if (process.env.VUE_APP_URL == "http://localhost:5000" ){
                var url = process.env.VUE_APP_ADMIN_URL
                window.open(url, "_target");
            } else {
            this.$router.push({ name: 'admin' });
            this.$router.go()}
    },
    goToHome () {
      this.$router.push({ name: 'home' });
      this.$router.go();
    },
    inputIsEmpty () {
      let isErrored = false;

      if (!this.askerFirstName.length) {
        this.errors.push("Client's first name cannot be blank")
      }

      if (!this.askerLastName.length) {
        this.errors.push("Client's last name cannot be blank")
      }

      if (!Object.keys(this.validatedProjects).length ) {
        this.errors.push("You must submit at least one valid project name")
      }
      if (this.errors.length) {
        this.errored = true;
        isErrored = true;
      }
      return isErrored;
    },
    SendEmailUser() {
            axios
            .post(process.env.VUE_APP_API_SEND_EMAIL_USER, 
            {
              client_first_name: this.askerFirstName,
              client_last_name: this.askerLastName, 
              client_email: this.askerEmail,
              client_projects: this.texttest,
              email_type:this.selectedType
            },
            {
            headers: {Authorization: `Bearer ${this.$store.state.token}`}
        })
        .then((success) => {
          if (success.data == "True"){
          this.success_email = true}
        })

    },
    submit: function(e) {
      e.preventDefault();
      this.errors= [];
      this.errored = false;
      let inputIsEmpty = this.inputIsEmpty();
      if (!inputIsEmpty) {
        this.$el.style.pointerEvents = "none";
        this.loading = true;
        axios
        .post(process.env.VUE_APP_API_UPDATE_DATABASE, 
            {
              projects: this.validatedProjects,
              client_first_name: this.askerFirstName,
              client_last_name: this.askerLastName, 
              client_phone_number: this.askerPhonenumber,
              client_company: this.askerCompany, 
              client_email: this.askerEmail, 
              admin_email: this.$store.state.user.email
            },
            {
              headers: {Authorization: `Bearer ${this.$store.state.token}`}
            },
        )
        .then(() => {
          this.success = true; // à mettre sur OFF pour les tests
        })
        .catch(error => {
          let data = error.response.data;
          if (data.errors && data.errors.length) {
            data.errors.forEach(d => {
              if (this.inputsList.includes(d.input)) {
                this.$refs['input-' + d.input].classList.add("errored");
                this.errors.push(d.msg);
              }
            });
          } else if (data.error) {
            this.errors.push(data.error);
              // CUSTOM PART
            for (let i=0; i < this.inputsList.length; i++) {
              this.$refs['input-' + this.inputsList[i]].classList.add("errored");
            }
          }
          if (this.errors.length) {
            this.errored = true;
          }
        })
        .finally(() => {
          this.$el.style.pointerEvents = "auto";
          this.loading = false;
        });
      }
    },
    getAllProjectsAPI() {
      axios.get(process.env.VUE_APP_API_GET_PROJECTS, {
        params : {
          email: this.$store.state.user.email
          },
          headers: {Authorization: `Bearer ${this.$store.state.token}`},
      })
      .then((projects) => {
        this.allProjects = projects.data.projects_scope.map(a => a.name);
      })    
    },

    checkAdminStatus () {

      axios.get(process.env.VUE_APP_API_CHECK_ADMIN_STATUS, {
        params : {
          email: this.$store.state.user.email
          },
          headers: {Authorization: `Bearer ${this.$store.state.token}`},
        })
      .then((adminStatus) => {
        this.isSiteAdmin = adminStatus.data
        
      })
    },

    getAllRoles () {

      axios.get(process.env.VUE_APP_API_GET_ALL_ROLES, {

          headers: {Authorization: `Bearer ${this.$store.state.token}`},
        })
      .then((roles) => {
        this.roles = roles.data;
        this.rolesSelected.fill(this.roles[1])
      })
    },
},

  computed: {
    body () {
      if (this.selectedType == 1){
        return "bonjour " + this.askerFirstName + ' ' + this.askerLastName + ",\nVotre demande a bien été prise en compte, vous pouvez désormais vous connecter au portail de surveillance EVORISK via le lien suivant:\n https://evorisk.fr, et accéder aux projets suivants:\n"+ this.texttest + "\n\nCordialement,\nL'équipe du portail de surveillance EVORISK";    
      }
      else if (this.selectedType==2){
        return "bonjour " + this.askerFirstName + ' ' + this.askerLastName + ",\nVotre demande a bien été prise en compte, vous pouvez désormais vous connecter au portail de surveillance EVORISK\nvia le lien suivant: https://evorisk.fr.\nPar contre, vous ne m'avez pas indiqué à quel(s) projets(s) vous aimeriez accéder.\nPour cela, vous pouvez vous connecter au portail de surveillance, cliquer sur <<demande d'accès>> et remplir le formulaire.\nVous recevrez un email de confirmation de demande d'accès au(x) projets(s) demandé(s).\n\nCordialement,\nL'équipe du portail de surveillance EVORISK"

      }
        else if (this.selectedType==3){
        return "bonjour " + this.askerFirstName + ' ' + this.askerLastName + ",\nVotre demande d'accès au portail de surveillance ne peut pas être prise en compte.\nVeuillez me contacter, contact@evorisk.fr pour préciser votre demande.\n\nCordialement,\nL'équipe du portail de surveillance EVORISK"

      }
      else {
        return "selectionner type de l'email"
      }
      },
    svg () {
      return svg;
    },
    validatedProjects (){
      let validated = {};
      for (const [idx, project] of this.projectsToSubmit.entries()){
        if (this.allProjects.includes(project)){
          if (project in validated){
            validated[project].push(this.rolesSelected[idx])
          }
          else{
            validated[project] = new Array();
            validated[project].push(this.rolesSelected[idx])
          }
        }
      }
      
      return validated;
    },
    SiteAdmin (){
      this.checkAdminStatus ()
      return this.isSiteAdmin;
    },



  },

  created: function(){
    if (this.$router.currentRoute.name === '403') {
      this.mode403 = true;
    }
    
    this.getAllRoles();
    
  },
  mounted: function (){
    this.getAllProjectsAPI();
  }
}
</script>

<style scoped>
/*--------------------------------------------- WRAPPER */
.wrapper {

  /* - regular version - */
  display: block;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  word-break: break-word;
}
.header {
  display: block;
  width: 100%;
  /*height: 4rem;*/
  height: 3rem;
  /* position: absolute;
  left: 0;
  top: 0;
  z-index: 2; */
}
.main {
  display: block;
  width: 100%;
  height: auto;
  min-height: 100%;
  /* position: absolute;
  left: 50%;
  top: 94%;
  transform: translate(-50%, -50%);
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}
/* -------------------------------------------- header */
/* .header-content -> BECOME -> Header component */
/* -------------------------------------------- main */
/* -------------------------------------------- success */
.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90vw;
}
.success-svg {
  width: 4rem;
  height: 4rem;
}
.success-message {
  margin-top: 1rem;
}
/* -------------------------------------------- ask-element */
.ask-element {
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 19rem;
  max-width: 90vw;
  padding: 1rem;
}
/* main-title */
.main-title {
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  text-align: center;
  color: white;
}
/* main-subtitle */
.main-subtitle {
  width: 100%;
  margin-top: 0.8rem;
  font-size: 0.81rem;
  color: white;
}
/* form */
.form {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 1rem;
}
.form-input {
  display: block;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0.8rem;
  color: rgba(0, 0, 0, 1);
  font-family: "Roboto Bold";
  border: solid 0.1rem rgba(255, 0, 0, 0.0); /* set here too... to prevent glitch from .errored */
  text-align: center;
}
.form-input:focus {
  background: rgba(255, 255, 255, 1);
}
.submit {
  width: 70%;
  background: rgb(33, 175, 33);
  margin-top: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  box-shadow: -0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.4);
}
.submit:hover {
  box-shadow: -0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.2);
}
.errored {
  border: solid 0.1rem rgba(255, 0, 0, 1.0);
  color: red;
}
.error-zone {
  display: block;
  position: relative;
  padding-top: 0.4rem;
  color: red;
}
.spinner-w {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  margin-left: 0.4rem;
}
/* -------------------------------------------- back-btn */
.back-btn {
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  width: 19rem;
  font-family: "Roboto Bold";
  max-width: 90vw;
  height: 2rem;
  position: relative;
  margin-top: 0.8rem;
  justify-content: center;
  align-items: center;
  color: white;
}

.jumbotron {
    padding: 0.5rem;
    text-align: center;
}
/*
.back-btn:hover {
  box-shadow: -0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.4);
}
*/
/* -------------------------------------------- PORTRAIT */
@media screen and (orientation: portrait) {
}
</style>