<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-header">
        <header class="header">
          <Header :onClickCallback="goToHome" />
        </header>
      </div>

      <div class="col-12 col-centent">
        <div class="row">
          <div class="col-12">
            <h1>Vibrations</h1>
            <b-badge variant="secondary" class="badge-analyse">
              <span>Projet :</span> {{ itemDetection.project }}
            </b-badge>
            <b-badge variant="secondary" class="badge-analyse">
              <span>Date :</span> {{ itemDetection.data_date_time }}
            </b-badge>
            <b-badge variant="secondary" class="badge-analyse">
              <span>Station :</span> {{ itemDetection.station_name }}
            </b-badge>
            <b-badge variant="secondary" class="badge-analyse">
              <span>Capteur :</span> {{ itemDetection.chan_max }}
            </b-badge>
            <b-badge variant="secondary" class="badge-analyse">
              <span>Vitesse Max (mm/s) :</span>
              {{ itemDetection.chan_max_value }}
            </b-badge>
          </div>
        </div>

        <!-- FILTERS -->
        <div class="filters">
          <div class="row">
            <div class="col-12 col-sm-2 col-lg-1"><h5>Signal</h5></div>
            <div class="col-12 col-sm-10 col-lg-11">
              <div
                v-for="(value, key, index) in this.signal_list"
                :key="index"
                class="form-check form-check-inline"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="value.key"
                  :value="value.key"
                  v-model="filtersSignalsChecked"
                  v-on:change="onFilterChecked"
                />
                <label class="form-check-label" :for="value.key">{{
                  value.name
                }}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-2 col-lg-1"><h5>Capteur</h5></div>
            <div class="col-12 col-sm-10 col-lg-11">
              <div
                v-for="(value, key, index) in this.capteur_list_st1"
                :key="index"
                class="form-check form-check-inline"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="value"
                  :value="value"
                  v-model="filtersCaptorsChecked"
                  v-on:change="onFilterChecked"
                />
                <label class="form-check-label" :for="value">{{ value }}</label>
              </div>
              <div
                v-for="(value, key, index) in this.capteur_list_st2"
                :key="index"
                class="form-check form-check-inline"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="value"
                  :value="value"
                  v-model="filtersCaptorsChecked"
                  v-on:change="onFilterChecked"
                />
                <label class="form-check-label" :for="value">{{ value }}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-2 col-lg-1"><h5>Canal</h5></div>
            <div class="col-12 col-sm-10 col-lg-11">
              <div
                v-for="(value, key, index) in this.channel_list"
                :key="index"
                class="form-check form-check-inline"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="value"
                  :value="value"
                  v-model="filtersChannelsChecked"
                  v-on:change="onFilterChecked"
                />
                <label class="form-check-label" :for="value">{{ value }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            Filters ->> {{ filtersSignalsChecked }} --
            {{ filtersCaptorsChecked }} -- {{ filtersChannelsChecked }} <br />
            Graphs ->> {{ graphsDisplayed }}
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div
              v-for="graph in graphsDisplayed"
              :key="graph"
              class="project-content-chart-wrapper"
            >
              <AnalyseVibrationChart :apiCallUrl="apiCallUrl" :item="graph" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Header from "../components/Header";
import { svg } from "../assets/svg";
import AnalyseVibrationChart from "../components/AnalyseVibrationChart";
export default {
  name: "AnalyseVibration",
  components: {
    Header,
    AnalyseVibrationChart,
  },
  data: function () {
    return {
      projectName: "",
      idDetection: "",
      itemDetection: [],
      signal_list: [
        { key: "acceleration", name: "Accélération" },
        { key: "deplacement", name: "Déplacement" },
        { key: "vitesse", name: "Vitesse" },
      ],
      capteur_list_st1: [], // Array
      capteur_list_st2: [], // Array
      channel_list: [], // Array
      filtersSignalsChecked: [], // Array
      filtersCaptorsChecked: [], // Array
      filtersChannelsChecked: [], // Array
      graphsDisplayed: [], // Array
      apiCallUrl: "",
      mode403: false,
    };
  },
  methods: {
    goToHome() {
      this.$router.push({ name: "home" });
      this.$router.go()
    },
    getLog() {
      let project = this.$route.params.project;
      let itemPosition = this.$route.params.item;
      axios
        .get(process.env.VUE_APP_API_GET_VIB_LOG, {
          params: { project: project },
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        })
        .then((vibLog) => {

          this.itemDetection = JSON.parse(vibLog.data[itemPosition]);
          this.idDetection = this.itemDetection.pdf_filename.replace(
            ".pdf",
            ""
          );
          this.apiCallUrl =
            process.env.VUE_APP_API_ANALYSE +
            "/vib_log/" +
            this.projectName +
            "/" +
            this.idDetection +
            "/";
        });
    },
    onFilterChecked() {
      let expectedGraphs = [];
      for (let i in this.filtersSignalsChecked) {
        let signalItem = this.filtersSignalsChecked[i];
        for (let j in this.filtersCaptorsChecked) {
          let captorItem = this.filtersCaptorsChecked[j];
          this.filtersChannelsChecked.forEach(function (channelItem) {
            expectedGraphs.push(
              signalItem + "_raw_" + captorItem + "-" + channelItem
            );
          });
        }
      }

      for (let i in expectedGraphs) {
        let expectedGraph = expectedGraphs[i];
        if (!this.graphsDisplayed.includes(expectedGraph)) {
          this.appendGraph(expectedGraph);
          console.log("ADD > " + expectedGraph);
        }
      }
      for (let i in this.graphsDisplayed) {
        let graphDisplayed = this.graphsDisplayed[i];
        if (!expectedGraphs.includes(graphDisplayed)) {
          console.log("REMOVE > " + graphDisplayed);
        }
      }

      this.graphsDisplayed = expectedGraphs;
    },
    getDataAPI(apiCallUrl) {
      return axios.get(apiCallUrl, {
        headers: {
          Authorization: `Bearer ${this.$store.state.token}`,
          "Cache-Control": "no-cache",
        },
      });
    },
    appendGraph(graphKey) {
      /*
        console.log("refreshFilter = " + this.filtersCaptorsChecked + " -- " + this.filtersChannelsChecked)
        this.apiCallUrl = process.env.VUE_APP_API_ANALYSE + '/vib_log/' + this.projectName + '/' + this.idDetection + '/acceleration_raw_C1_H1';
        //console.log(this.apiCallUrl)
        this.getDataAPI(this.apiCallUrl)
        .then(res => {
            console.log(res);

            let expectedGraphs = [];
            for(var i = 0; i < this.filtersSignalsChecked.length; i++) {
                let signalItem = this.filtersSignalsChecked[i];
                for(var j = 0; j < this.filtersCaptorsChecked.length; j++) {
                    let captorItem = this.filtersCaptorsChecked[j];
                    this.filtersChannelsChecked.forEach(function(channelItem){
                        expectedGraphs.push(signalItem + "_raw_" + captorItem + "_" + channelItem);
                    });
                }
            }
        });*/
    },
  },
  computed: {},
  created: function () {
    if (this.$router.currentRoute.name === "403") {
      //console.log("run this vue in 403 mode");
      this.mode403 = true;
    }
    this.getLog();
  },
  mounted: function () {
    this.projectName = this.$route.params.project;
    let projectSelectedConfig = this.$store.state.projectsScope.filter(
      (project) => project.data_name === this.projectName
    )[0];
    let moduleVib = projectSelectedConfig.modules.filter(
      (module) => module.data_name === "vib"
    );
    this.capteur_list_st1 = moduleVib[0].capteur_list_st1;
    this.capteur_list_st2 = moduleVib[0].capteur_list_st2;
    this.channel_list = moduleVib[0].channel_list;
  },
};
</script>

<style scoped>
.col-centent {
  background: #fff;
  padding: 15px;
}
.badge-analyse {
  margin-right: 10px;
  font-size: 14px;
  padding: 5px 10px;
}
.badge-analyse span {
  font-weight: normal;
}
.filters {
  margin: 10px 0;
  padding: 15px;
  background: #eee;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
}
</style>