
// TODO:
  // need to rework and fix icon

<template>
  <div :style="style.wrapper">
    <svg class="svg-circles" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g class="bg" :style="style.background">
        <circle cx="24" cy="24" r="24" />
      </g>
      <g class="stroke-bg" :style="style.strokeBg">
        <circle cx="24" cy="24" r="18" />
      </g>
      <g class="stroke-w" :style="style.strokeWrapper" :class="{ 'stroke-w-animate':isAnimate || isRotate }">
        <g class="stroke" :style="style.stroke" :class="{ 'stroke-animate':isAnimate }">
          <circle cx="24" cy="24" r="18" />
        </g>
      </g>
    </svg>
    <!-- icon need a fix and a rework -->
    <!--
    <div v-if=icon>
      <div v-if=icon.svg v-html=icon.svg :style="style.icon" :class="{ 'icon-animate':icon.animate }"></div>
      <img v-else-if=icon.img :src=icon.img alt="Spinner icon" :style="style.icon" :class="{ 'icon-animate':icon.animate }">
    </div>
    -->

    <div v-if="svg" v-html=svg :style="style.icon"></div>

  </div>
</template>

<script>
export default {
  props: {
    progress: Number,
    animate: Boolean,
    speedCoef: Object,
    size: Number,
    zIndex: Number,
    shadow: String,
    background: Object,
    stroke: Object,
    pointerEvents: Boolean,
    svg: String
  },
  data: function(){
    return {
      isRotate: true, // -> false if manual progress
      isAnimate:  false, // -> false if manual progress
      style: {
        wrapper: {
          display: "block",
          width: 100 + "%",
          height: 100 + "%",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
          borderRadius: "50%",
          pointerEvents: "none",
          cursor: "default",
          boxShadow: "none"
        },
        strokeWrapper: {
          animationDuration: "1s"
        },
        stroke: {
          strokeDasharray: 114,
          strokeDashoffset: 114,
          strokeLinecap: 'round',
          stroke: "white",
          opacity: 0.8,
          animationDuration: "1.4s"
        },
        strokeBg: {
          stroke: "white"
        },
        background: {
          fill: "white",
          opacity: 0.0
        },
        icon: {
          //background: "purple",
          display: "block",

          width: 44 + "%",
          height: 44 + "%",
          //width: 44 + "%",
          //height: "100%",

          position: "absolute",
          left: "50%",
          top: "50%",
          transformOrigin: "center center",
          transform: "translate(-50%, -50%) scale(1)",
          zIndex: 1
        }
      }
    }
  },
  watch:{
    progress: function(val){
      if (val >= 0 && val <= 100) {
        this.style.stroke.strokeDashoffset = (114-((114*val)/100));
      }
    }
  },
  /*
  beforeDestroy: function(){
  },
  */
  created: function() {

    // icon part







    // spinner setup
      // animate
    if (this.animate) {
      this.isAnimate = true;
    }
      // speedCoef
    if (this.speedCoef) {
      if (this.speedCoef.rotate) {
        this.style.strokeWrapper.animationDuration = (1/this.speedCoef.rotate) + "s";
      }
      if (this.speedCoef.animate) {
        this.style.stroke.animationDuration = (1.4/this.speedCoef.animate) + "s";
      }
    }
      // manual progress
    if (this.progress !== null && this.progress !== undefined) {
      this.isRotate = false;
      if (this.progress >= 0 && this.progress <= 100) {
        this.style.stroke.strokeDashoffset = (114-((114*this.progress)/100));
      }
    }
      // stroke
    let strokeSetup = this.stroke;
    if (strokeSetup) {
      if (strokeSetup.value && !this.progress) {
        this.style.stroke.strokeDashoffset = (114-((114*strokeSetup.value)/100));
      } else {
        this.style.stroke.strokeDashoffset = (114-((114*69)/100));
      }
      if (strokeSetup.notRounded) {
        this.style.stroke.strokeLinecap = "butt";
      }
      if (strokeSetup.color) {
        this.style.stroke.stroke = strokeSetup.color;
      }
      if (strokeSetup.opacity) {
        this.style.stroke.opacity = strokeSetup.opacity;
      }
      if (strokeSetup.bgColor) {
        this.style.strokeBg.stroke = strokeSetup.bgColor;
      }
    } else {
      if (this.progress == null || this.progress == undefined) {
        this.style.stroke.strokeDashoffset = (114-((114*69)/100));
      }
    }
      // size
    if (this.size) {
      this.style.wrapper.width = this.size + "%";
      this.style.wrapper.height = this.size + "%";
    }
      // zIndex
    if (this.zIndex) {
      this.style.wrapper.zIndex = this.zIndex;
    }
      // background
    if (this.background) {
      if (this.background.color) {
        this.style.background.fill = this.background.color;
      }
      if (this.background.opacity) {
        this.style.background.opacity = this.background.opacity;
      }
    }
      // pointerEvents
    if (this.pointerEvents) {
      this.style.wrapper.pointerEvents = true;
      this.style.wrapper.cursor = "pointer";
    }
      // shadow
    if (this.shadow) {
      this.style.wrapper.boxShadow = this.shadow;
    }
  }
};
</script>

<style scoped>
  .svg-circles {
    display: block;
    width: 100%;
    height: 100%;
  }
  .bg {
    stroke: none;
    stroke-width: 4;
    z-index: 0;
  }
  .stroke-bg {
    fill: none;
    opacity: 0.4;
    stroke-width: 4;
    z-index: 1;
  }
  .stroke-w {
    transform-origin: center;
    transform: translate(0, 0) rotateZ(-90deg);
  }
  .stroke-w-animate {
    animation-name: stroke-w-animation;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .stroke {
    fill: none;
    stroke-width: 4;
    transform-origin: center;
  }
  .stroke-animate {
    animation-name: stroke-animation;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  /*
  .icon-animate {
    animation: 0.7s ease-in-out infinite alternate icon-animation;
  }
  @keyframes icon-animation {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      transform: translate(-50%, -50%) scale(0.9);
    }
  }
  */
  @keyframes stroke-animation {
    0%,
    25% {
      stroke-dashoffset: 114;
      transform: rotate(0);
    }
    50%,
    75% {
      stroke-dashoffset: 22;
      transform: rotate(45deg);
    }
    100% {
      stroke-dashoffset: 114;
      transform: rotate(360deg);
    }
  }
  @keyframes stroke-w-animation {
    0% {
      transform: translate(0, 0) rotateZ(0deg);
    }
    100% {
      transform: translate(0, 0) rotateZ(360deg);
    }
  }
</style>