<template>
  <div>

  <div v-for="download in download_link" :key="download.name">
    {{ download.title}}
      <button
        class="project-nav-module-global-download"
        v-on:click="downloader(download)"
      >
        <b-icon-download
          style="
            width: 16px;
            height: 16px;
            margin-right: 12px;
          "
        ></b-icon-download>
        {{ $t("download") }}
      </button>
  </div>
    </div>
</template>

<script>

import axios from 'axios';
  export default {
    name: "DOWNLOAD",
    props: ["module","project"],
    data() {
      return {
      download_link: this.$parent.modulesConfig.download_data.download_link,
      activeDownload: {},
      }
    },
    mounted() {
      },
    created() {
    },
    watch: {
    },
    computed: {
    },
    methods: {
    updateReactiveObjectProperty(oldObjName, properties) {
      let oldObj = this[oldObjName];
      let newObj = oldObj;
      if (!properties) {
        for (let name in newObj) {
          delete newObj[name];
        }
      } else {
        properties.forEach((i) => {
          if (newObj[i.key]) {
            if (i.delete) {
              delete newObj[i.key];
            } else {
              if (Array.isArray(i.value)) {
                i.value.forEach((j) => {
                  newObj[i.key][j.key] = j.value;
                });
              } else {
                newObj[i.key] = i.value;
              }
            }
          } else {
            newObj[i.key] = i.value;
          }
        });
      }
      this[oldObjName] = Object.assign({}, this[oldObjName], newObj);
    },
    downloader(download) {
      const initLoading = () => {
        axios
          .get(download.url, {
            headers: { Authorization: `Bearer ${this.$store.state.token}` },
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              let downloadProgress =
                (progressEvent.loaded / progressEvent.total) * 100;
              downloadProgress = downloadProgress.toFixed();
              if (this.activeDownload[activeDownloadId]) {
                this.updateReactiveObjectProperty("activeDownload", [
                  {
                    key: activeDownloadId,
                    value: {
                      progress: downloadProgress,
                    },
                  },
                ]);
              }
            },
          })
          .then((res) => {
            const blob = res.data;
            const contentDisposition = res.headers["content-disposition"];
            let fileName = "unknown.unknown";
            if (contentDisposition) {
              fileName = contentDisposition.split("=")[1];
            }
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.setAttribute("target", "_blank");
              link.setAttribute("rel", "noopener noreferrer");
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(url);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            if (this.activeDownload[activeDownloadId]) {
              this.updateReactiveObjectProperty("activeDownload", [
                {
                  key: activeDownloadId,
                  delete: true,
                },
              ]);
            }
          });
      };
      let activeDownloadId = this.project + ":" + download.url;
      if (!this.activeDownload[activeDownloadId]) {
        this.updateReactiveObjectProperty("activeDownload", [
          {
            key: activeDownloadId,
            value: {
              progress: 0,
            },
          },
        ]);
        initLoading();
      }
    },
  }
  }
</script>

<style>

.bandeau_col_full {
  width: 100%;
  height: 30px;
  background-color:#409dc2;
  font-family: "Roboto Bold";
  color: white;
  text-align: center;
  margin-top:5px;
  margin-bottom:5px;
  border-radius: 5px;
  border: solid 2px;
}
th,td{
  text-align:center;
  border:1px solid #ccc;
  margin:0;
  padding:10px;
}
th{
  background:#ccc;
}
.project-nav-module-global-download {
  background: rgba(130, 130, 130, 1);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  margin-top: 0.4rem;
  font-size: 0.85rem;
  color: white;
  border-radius: 2px;
}
.project-nav-module-global-download:hover {
  background: rgba(130, 130, 130, 0.8);
}

</style>
