<template>

  <b-container fluid class="log-table">
      <div class="bandeau_col_full">
          <p> {{ module.modulename }} - {{ module.itemname }} </p>
        </div>
    <!-- User Interface controls -->
    <!-- <h1 class= 'commentCol'>Hi mom !</h1> -->
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select v-model="sortBy" id="sortBySelect" :options="sortOptions" class="w-75">
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Initial sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="initialSortSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="sortDirection"
            id="initialSortSelect"
            size="sm"
            :options="['asc', 'desc', 'last']"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter On"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          description="Leave all unchecked to filter on all data"
          class="mb-0">
          <b-form-checkbox-group v-model="filterOn" class="mt-1">
            <b-form-checkbox value="comment">Commentaire</b-form-checkbox>
            <b-form-checkbox value="alert_level">Niveau de l'alerte</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      show-empty
      small
      stacked: true
      sticky-header
      striped
      bordered
      responsive
      id="my-table"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    style= "font-size: 0.8rem; max-height: 850px;">

      <template #cell(alert_equipement)="row">
        <li v-for="item in row.item.alert_equipement" :key="item">{{ item }}</li>
      </template>
      
      <!-- <template #cell(alert_targets)="row">
        <li v-for="item in row.item.alert_targets" :key="item">{{ item }}</li>
      </template>

      <template #cell(alert_quantity)="row">
        <li v-for="item in row.item.alert_quantity" :key="item">{{ item }}</li>
      </template>

      <template #cell(alert_unit)="row">
        <li v-for="item in row.item.alert_unit" :key="item">{{ item }}</li>
      </template>

      <template #cell(alert_threshold)="row">
        <li v-for="item in row.item.alert_threshold" :key="item">{{ item }}</li>
      </template> -->

      <template #cell(alert_details)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Cache' : 'Montre'}} Details
        </b-button>
      </template>



      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Cible(s):</b></b-col>
            <b-col>{{ row.item.alert_targets }}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Quantité(s) Evaluée(s):</b></b-col>
            <b-col>{{ row.item.alert_quantity }}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Unité(s)</b></b-col>
            <b-col>{{ row.item.alert_unit }}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Seuils:</b></b-col>
            <b-col>{{ row.item.alert_threshold }}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Valeurs:</b></b-col>
            <b-col>{{ row.item.alert_values }}</b-col>
          </b-row>

        </b-card>
      </template>

      <template v-slot:cell(addComment)="row" class='icon-center'>
        <b-button size="sm" @click="info(row.item, row.index, $event.target)"
        :disabled="$parent.userProjectsRoles[$parent.projectSelected].toLowerCase()=='viewer'">
        <b-icon-chat-square-text-fill style="width: 20px; height: 20px;"></b-icon-chat-square-text-fill>
        </b-button>
      </template>

      <template v-slot:cell(validated)="row" class='icon-center'>
         <div v-if=row.item.validated>
            <b-icon-check variant="success" style="width: 30px" class="h2 mb-0">
            </b-icon-check>
         </div>
          <div v-else>
            <b-icon-x variant="danger"  style="width: 30px" class="h2 mb-0">
            </b-icon-x>
         </div>
      </template>

      <template v-slot:cell(seen_geophy)="row" class='icon-center'>
         <div v-if=row.item.seen_geophy>
            <b-icon-check variant="success" style="width: 30px" class="h2 mb-0">
            </b-icon-check>
         </div>
          <div v-else>
            <b-icon-x variant="danger"  style="width: 30px" class="h2 mb-0">
            </b-icon-x>
         </div>
      </template>

      <template v-slot:cell(changeValidation)="row" class='icon-center'>
        <b-button size="sm" @click="changeStatus(row.item, 'validated')"
        :disabled="$parent.userProjectsRoles[$parent.projectSelected].toLowerCase()=='viewer' || $parent.userProjectsRoles[$parent.projectSelected].toLowerCase()=='viewer_client'">
          <p class="button-table">{{ row.item.validated ? 'De-v' : 'V' }}alide</p>
        </b-button>
      </template>

    </b-table>

    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal" @ok="updateComment">
      <pre><strong>{{ infoModal.title }}</strong></pre>
        <b-form-input  id="inputText1" ref="inputText1" v-model="comment" autofocus :maxlength=500></b-form-input>
    </b-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import dateFormat, { masks } from "dateformat";

  export default {
    props: ["module"],
    data() {
      return {
        itemsAll: [],
        items: [],

        fields: [
          { key: 'alert_timestamp', label: 'Date-Heure', class: 'text-center text-nowrap', sortable: true, sortDirection: 'desc' },
          { key: 'alert_equipement', label: 'Equipement', sortable: true, class: 'text-center text-nowrap', thStyle: {'width':'8%'} },
          //{ key: 'alert_targets', label: 'Cible(s)', sortable: true, class: 'text-center text-nowrap', thStyle: {'width':'8%'} },
          //{ key: 'alert_quantity', label: 'Quantité(s) évaluée(s)', sortable: true, class: 'text-center text-nowrap', thStyle: {'width':'8%'} },
          //{ key: 'alert_unit', label: 'Unité(s)', sortable: true, class: 'text-center text-nowrap', thStyle: {'width':'8%'} },
          { key: 'alert_level', label: 'Niveau de l\'alerte', sortable: true, class: 'text-center text-nowrap', thStyle: {'width':'8%'},tdClass: 'getColor' },
          //{ key: 'alert_threshold', label: 'Seuil(s)', sortable: true, class: 'text-center text-nowrap', thStyle: {'width':'8%'},tdClass: 'getColor' },
          //{ key: 'alert_values', label: 'Valeurs', sortable: true, class: 'text-center text-nowrap' ,thStyle: {'width':'8%'}},
          { key: 'alert_details', label: 'Détails', sortable: true, class: 'text-center text-nowrap' ,thStyle: {'width':'8%'}},

          { key: 'comment', label: 'Commentaire', sortable: false, class: 'text-center text-nowrap', thStyle: {'width':'20%'} },
          { key: 'addComment', label: '', sortable: false,class: 'text-center text-center text-nowrap',thStyle: {'width':'8%'}}  ,
          { key: 'validated', label: 'Validé par Pôle Surveillance', sortable: true, class: 'text-center text-center text-nowrap' },
          { key: 'changeValidation', label: '', sortable: false, class: 'text-center text-center text-nowrap',thStyle: {'width':'8%'}} 

  ],

        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, 20],
        sortBy: 'alert_timestamp',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        commentID: 0,
        comment: "",
        infoModal: {
          id: 'info-modal-alert',
          title: '',
          content: ''
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields  
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
      },
    created() {
        this.getLog();
    },
    methods: {
        getColor(status) {
        if(status === 3) return "background-orange"
        if(status === 5) return "background-red"
      
        return "";
      },
      getLog(){
        axios.get(process.env.VUE_APP_API_GET_ALERTE_LOG, {
            params:{project: this.$parent.projectSelected},
                            headers: {Authorization: `Bearer ${this.$store.state.token}`},
        })
        .then((alerteLog) => {
          this.itemsAll = [];
          this.item_parse={};
          
          for (let item of alerteLog.data){
              var threshold = []
              var equipement = []
              var targets = []
              var quantity = []
              var unit = []
              this.item_parse = JSON.parse(item)
              this.item_parse.alert_timestamp = new Date(Math.floor(this.item_parse.alert_timestamp)*1000)//.toLocaleString("fr",{timeZone: 'UTC'})
              this.item_parse.alert_timestamp = dateFormat(this.item_parse.alert_timestamp, "yyyy-mm-dd HH:MM")
              var alert_equipement = Object.keys(JSON.parse(this.item_parse.alert_values.replaceAll("NaN",null)))
              for(var key0 in alert_equipement){
                equipement.push(alert_equipement[key0].split('_')[0])
                targets.push(alert_equipement[key0].split('_')[1])
                quantity.push(alert_equipement[key0].split('_')[2] + '_' + alert_equipement[key0].split('_')[3])
                unit.push(alert_equipement[key0].split('_')[4])
              }
              this.item_parse.alert_equipement = equipement.filter((x, i, a) => a.indexOf(x) === i)
              this.item_parse.alert_targets = targets
              this.item_parse.alert_quantity = quantity
              this.item_parse.alert_unit = unit

              var alert_threshold = JSON.parse(this.item_parse.threshold_hist)
              for(var key1 in alert_threshold){
                for(var key2 in alert_threshold[key1]){
              threshold.push(key1 +':' + alert_threshold[key1][key2])
              }}
              this.item_parse.alert_threshold = threshold

              this.itemsAll.push(this.item_parse);
          }
          this.filterItemsByRole();
          this.totalRows = this.itemsAll.length

        })  
      },  
      filterItemsByRole(){
        // Is there anything that ensures that the same user will not have multiple roles ?
        // TODO : Check this
        //console.log(this.$parent.userProjectsRoles[this.$parent.projectSelected].toLowerCase().substr(0,6))
        this.items = [];
          for (let item of this.itemsAll){
            this.items.push(item)
        }
      },
      info(item, index, button) {
        this.commentID = item.id;
        this.comment =item.comment;
        this.infoModal.title = "Ajouter ou modifier une commentaire";
        this.infoModal.content = item.comment
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      updateComment(){ 
        var i = this.items.map(o => o.id).indexOf(this.commentID);
        this.items[i]["comment"] = this.comment; 

        axios.post(process.env.VUE_APP_API_ADD_ALERTE_COMMENT, {
            id: this.commentID,
            comment: this.comment},
            {
            headers: {Authorization: `Bearer ${this.$store.state.token}`}
        })
        .then((success) => {
          console.log(success)
        })
      },
      openPageAnalyse(item, index, button) {
          var i = this.items.map(o => o.id).indexOf(item.id);
          var project = this.$parent.projectSelected;
          var url = process.env.VUE_APP_URL + "/" + project + "/analyse-vibration/" + i;
          window.open(url, "_target");
      },
      changeStatus(item, key){
        var i = this.items.map(o => o.id).indexOf(item.id);

        if (this.items[i][key]){
          this.items[i][key] = false;
        }
        else { this.items[i][key] = true; }

        axios.post(process.env.VUE_APP_API_CHANGE_ALERTE_STATUS, {
            id: this.items[i]["id"], 
            key: key,
            status: this.items[i][key]},
            {
            headers: {Authorization: `Bearer ${this.$store.state.token}`}
        })
        .then((success) => {
          console.log(success)
        })

      },
      resetInfoModal() {
        this.infoModal.title = '';
        this.infoModal.content = '';
        this.commentID = 0;
        this.comment = '';
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
  }
  }
</script>
<style>

.background-orange {
  background-color: orange;
}
.background-red {
  background-color: red;
}
.commentCol {
   max-width: 80% !important;
   min-width: 100%;
   /* color: red;
   border: 2px solid green; */
}
/* .b-table-sticky-header {
  max-height: 1000px !important;
  font-size: 0.8rem;
} */

p.button-table {
  font-size: 0.7rem;
  margin: 0;
  width: 37px
}
.icon-center {
  text-align:center !important;
}
.log-table {
  background: #fff;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 4px 25px 0 rgba(0,0,0,.1);
}
.bandeau_col_full {
  width: 100%;
  height: 30px;
  background-color:#409dc2;
  font-family: "Roboto Bold";
  color: white;
  text-align: center;
  margin-top:5px;
  margin-bottom:5px;
  border-radius: 5px;
  border: solid 2px;
}
</style>
