<template>
  <div class="wrapper">
    <header v-if="!mode403" class="header">
      <Header :onClickCallback="goToHome" />
    </header>
    <header v-else class="header">
      <Header />
    </header>

    <main class="main">
      <div v-if="success" class="success">
        <svg
          class="success-svg"
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="black"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline
            stroke="rgb(33, 175, 33)"
            points="22 4 12 14.01 9 11.01"
          ></polyline>
        </svg>
        <p class="success-message">
          {{ $t("email_send_with_succes") }}
        </p>
      </div>
      <div v-else class="ask-element">
        <h1 class="main-title">{{ $t("ask_quote") }}</h1>

<form @submit.prevent="handleSubmit" class="form">

<div class='row'>
            <label for="FirstName">{{ $t("soilstab.first_name") }}:</label>
            <input
              v-model="inputs.firstname"
              class="form-input"
              ref="input-firstname"
              type="text"
              name="FirstName"
              autocomplete="off"
              :placeholder="$t('soilstab.first_name')"
            />
</div>
<div class='row'>
            <label for="LastName">{{ $t("soilstab.last_name") }}:</label>
            <input
              v-model="inputs.lastname"
              class="form-input"
              ref="input-lastname"
              type="text"
              name="LastName"
              autocomplete="off"
              :placeholder="$t('soilstab.last_name')"
              required
            />
</div>
<div class='row'>
            <label for="CompanyName">{{ $t("soilstab.company") }}:</label>
            <input
              v-model="inputs.company"
              class="form-input"
              ref="input-company"
              type="text"
              name="CompanyName"
              autocomplete="off"
              :placeholder='$t("soilstab.company")'
              required
            />
</div>
<div class='row'>
            <label for="CompanyAddress">{{ $t("soilstab.company_address") }}:</label>
            <input
              v-model="inputs.company_address"
              class="form-input"
              ref="input-company_address"
              type="text"
              name="CompanyAddress"
              autocomplete="off"
              :placeholder='$t("soilstab.company_address")'
              required
            />
</div>

<div class='row'>
            <label for="Email">{{ $t("soilstab.email") }}:</label>
            <input
              v-model="inputs.email"
              class="form-input"
              ref="input-email"
              type="text"
              name="EMail"
              autocomplete="off"
              :placeholder='$t("soilstab.email")'
              required
            />
</div>

<div class='row'>
    <label>{{ $t("soilstab.language") }}: </label>
    <div class="row-input">
    <select id="language" v-model="language" @change="PriceControl">
          <option value="french" >{{ $t("soilstab.french") }}</option>
          <option value="english" >{{ $t("soilstab.english") }}</option>
    </select>
  </div>
</div>

<div class='row'>
            <label for="SiteName">{{ $t("soilstab.site_name") }}:</label>
            <input
              v-model="inputs.sitename"
              class="form-input"
              ref="input-sitename"
              type="text"
              name="SiteName"
              autocomplete="off"
              :placeholder='$t("soilstab.site_name")'
            />
</div>

<div class='row'>
    <label>{{ $t("soilstab.site_description") }}: </label>
    <textarea v-model="text_description_site"></textarea>
</div>
<br>
<div class='title'>
{{ $t("soilstab.title_1") }}
<br>
{{ $t("soilstab.title_2") }}
<br>
{{ $t("soilstab.title_3") }}
</div>
<br>
<div>
<h5 style="color: blue;text-align: center"> {{ price }} Euros HT</h5>
</div>
<br>
<div class='row'>
    <label>{{ $t("soilstab.amount_of_data") }}: </label>
    <div class="row-input">
    <select id="amount_data" v-model="amount_data" @change="PriceControl">
          <option value="30go" >30 Go</option>
          <option value="40go" >40 Go (+ 25€ HT)</option>
          <option value="50go">50 Go (+ 50€ HT)</option>
          <option value="60go">60 Go (+ 75€ HT)</option>
    </select>
    </div>
</div>

<div class='row'>
    <label>{{ $t("soilstab.time_stockage_of_data") }} : </label>
    <div class="row-input">
    <select id="amount_data" v-model="time_stockage" @change="PriceControl">
          <option value="1month" >{{ $t("soilstab.1month") }}</option>
          <option value="6month" >{{ $t("soilstab.6month") }} (+ 25€ HT)</option>
          <option value="1year" >{{ $t("soilstab.1year") }} (+ 50€ HT)</option>
    </select>
    </div>
</div>

<div class='row'>
    <label>{{ $t("soilstab.evorisk_visualisation") }}: </label>
    <div class="row-input">
    <select id="amount_data" v-model="evorisk_visualisation" @change="PriceControl">
          <option value="1month" >{{ $t("soilstab.1month") }}</option>
          <option value="6month" >{{ $t("soilstab.6month") }} (+ 250€ HT)</option>
          <option value="1year" >{{ $t("soilstab.1year") }} (+ 500€ HT)</option>
    </select>
    </div>

</div>

<div class='row'>
    <label>{{ $t("soilstab.nb_configuration") }}: </label>
    <div class="row-input">
    <select id="amount_data" v-model="nb_configuration" @change="PriceControl">
          <option value="3" >3</option>
          <option value="4" >4 (+ 450€ HT)</option>
          <option value="5" >5 (+ 900€ HT)</option>
    </select>
    </div>

</div>

<div class='row'>
    <label>{{ $t("soilstab.choice_configuration") }}: </label>
    <div class="row-input">
    <select id="amount_data" v-model="choice_configuration" @change="PriceControl">
          <option value="default" >{{ $t("soilstab.default") }}</option>
          <option value="other" >{{ $t("soilstab.other") }}</option>
    </select>
    </div>  
</div>
<div class='row' v-if="choice_configuration == 'other'">
    <label>{{ $t("soilstab.editable_parameters") }}: </label>
    <textarea v-model="text_configuration"></textarea>
</div>

<div class='row'>
    <label>{{ $t("soilstab.add_comment") }}: </label>
    <textarea v-model="text_comment"></textarea>
</div>

          <button v-on:click="submit" class="submit" type="submit">
            <span>{{ $t("soilstab.ask_quote") }}</span>
            <span v-if="loading" class="spinner-w">
              <Spinner v-if="loading" />
            </span>
          </button>
  </form>
            <div v-if="errored && errors && errors.length" class="error-zone">
            <p v-for="error in errors" :key="error">* {{ error }}</p>
          </div>
      </div>

    </main>
          <button v-if="!mode403" v-on:click="goToHome" class="back-btn">
        {{ $t("soilstab.soilstab_landing_page") }}
      </button>
      <img  v-if="navigator_language == 'fr'" class="img_config" src="/project/data/image/soilstab_service/config_dvv_fr.jpeg" width="40%">
      <img  v-else class="img_config" src="/project/data/image/soilstab_service/config_dvv_en.jpeg" width="40%">
      <div class="information_contact">
        <p> {{ $t("soilstab.info_p1") }}
        <br>
        {{ $t("soilstab.info_p2") }} 
        <br>
        {{ $t("soilstab.info_p3") }} </p>
        </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import { svg } from "../assets/svg";
import { i18n } from '../i18n-setup.js';
export default {
  name: "Soilstab",
  components: {
    Header,
    Spinner,
  },
  data: function () {
    return {
      success: false,
      loading: false,
      errored: false,
      errors: [],
      text_description_site: "",
      text_comment: "",

      // prix décidés 
      price_control: { amount_data: {
                            '30go': 0,
                            '40go': 25,
                            '50go': 50,
                            '60go': 75
                        },
                        time_stockage: {
                          '1month' : 0,
                          '6month' : 25,
                          '1year': 50,
                        },
                        evorisk_visualisation: {
                          '1month': 0,
                          '6month': 250,
                          '1year': 500
                        },
                        nb_configuration: {
                          '3' : 0,
                          '4' : 450,
                          '5' : 900
                        }

      },

      // by default
      amount_data: '30go',
      time_stockage: '1month',
      evorisk_visualisation: '1month',
      nb_configuration: '3',
      choice_configuration: 'default',
      text_configuration : '',
      price: 1200,
    
      language: "french",
      
      inputsList: ["firstname", "lastname", "company", "company_address", "email", "sitename"],
      inputs: {
        firstname: "",
        lastname: "",
        company: "",
        company_address: "",
        email: "",
        sitename: "",
      },

      mode403: false,
      navigator_language : ""
    };
  },
  methods: {
    goToHome() {
      this.$router.push({ path: "/soilstab_service" });
      this.$router.go()
    },
    inputIsEmpty() {
      let isErrored = false;

        if (!this.inputs.firstname.length) {
          this.errors.push(i18n.t("soilstab.error_first_name")); 
        }
        if (!this.inputs.lastname.length) {
          this.errors.push(i18n.t("soilstab.error_last_name"));
        }
        if (!this.inputs.company.length) {
          this.errors.push(i18n.t("soilstab.error_company"));
        }
        if (!this.inputs.company_address.length) {
          this.errors.push(i18n.t("soilstab.error_company_address"));
        }
        if (!this.inputs.email.length) {
          this.errors.push(i18n.t("soilstab.error_email"));
        }
        if (!this.inputs.sitename.length) {
          this.errors.push(i18n.t("soilstab.error_site_name"));
        }
      for (let i = 0; i < this.inputsList.length; i++) {
        if (!this.inputs[this.inputsList[i]].length) {
          this.$refs["input-" + this.inputsList[i]].classList.add("errored");
        }
      }
      if (this.errors.length) {
        this.errored = true;
        isErrored = true;
      }
      return isErrored;
    },
    clearInputsError() {
      for (let i = 0; i < this.inputsList.length; i++) {
        this.$refs["input-" + this.inputsList[i]].classList.remove("errored");
      }
    },
    submit: function (e) {
      e.preventDefault();
      this.clearInputsError();
      this.errors = [];
      this.errored = false;
      let inputIsEmpty = this.inputIsEmpty();
      if (!inputIsEmpty) {
        this.$el.style.pointerEvents = "none";
        this.loading = true;
        axios
          .post(
            process.env.VUE_APP_API_REQUEST_QUOTE_SOILSTAB,
            {
              sitename: this.inputs.sitename,
              description_site: this.text_description_site,
              user_email: this.inputs.email,
              user_first_name: this.inputs.firstname,
              user_last_name: this.inputs.lastname,
              user_company: this.inputs.company,
              user_company_address: this.inputs.company_address,
              language: this.language,
              amount_data: this.amount_data,
              time_stockage: this.time_stockage,
              nb_configuration: this.nb_configuration,
              choice_configuration: this.choice_configuration,
              text_configuration: this.text_configuration,
              evorisk_visualisation: this.evorisk_visualisation,
              comment: this.text_comment,
              price: this.price
            },
            {
              headers: { Authorization: `Bearer ${this.$store.state.token}` },
            }
          )
          .then(() => {
            this.success = true; // à mettre sur OFF pour les tests
          })
          .catch((error) => {
            let data = error.response.data;
            if (data.errors && data.errors.length) {
              data.errors.forEach((d) => {
                if (this.inputsList.includes(d.input)) {
                  this.$refs["input-" + d.input].classList.add("errored");
                  this.errors.push(d.msg);
                }
              });
            } else if (data.error) {
              this.errors.push(data.error);
              // CUSTOM PART
              for (let i = 0; i < this.inputsList.length; i++) {
                this.$refs["input-" + this.inputsList[i]].classList.add(
                  "errored"
                );
              }
            }
            if (this.errors.length) {
              this.errored = true;
            }
          })
          .finally(() => {
            this.$el.style.pointerEvents = "auto";
            this.loading = false;
          });
      }
    },
    PriceControl () {

      const sup_price_amount_data = Object.fromEntries(Object.entries(this.price_control.amount_data).filter(([key]) => key === this.amount_data))[this.amount_data];
      const sup_price_time_stockage = Object.fromEntries(Object.entries(this.price_control.time_stockage).filter(([key]) => key === this.time_stockage))[this.time_stockage];
      const sup_price_evorisk_visualisation = Object.fromEntries(Object.entries(this.price_control.evorisk_visualisation).filter(([key]) => key === this.evorisk_visualisation))[this.evorisk_visualisation];
      const sup_price_nb_configuration = Object.fromEntries(Object.entries(this.price_control.nb_configuration).filter(([key]) => key === this.nb_configuration))[this.nb_configuration];
      
      this.price = 1200 + sup_price_amount_data + sup_price_time_stockage + sup_price_evorisk_visualisation + sup_price_nb_configuration
    },

    handleSubmit () {
      console.log('you are going to pay: ', this.price)
    }
  },
  computed: {
    svg() {
      return svg;
    },
  },
  created: function () {
    if (this.$router.currentRoute.name === "403") {
      //console.log("run this vue in 403 mode");
      this.mode403 = true;
    }
  },
  mounted: function () {
    this.navigator_language = navigator.language || navigator.userLanguage; 
    // this.getUserInfo();
    
  },
};
</script>

<style scoped>
/*--------------------------------------------- WRAPPER */
.wrapper {
  /* - regular version - */
  display: block;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  word-break: break-word;
}
.header {
  display: block;
  width: 100%;
  /*height: 4rem;*/
  height: 3rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.main {
  display: flex;
  /* width: 100%; */
  /* height: auto; */
  /* min-height: 100%; */
  position: absolute;
  left: 5%;
  top: 10%;
  /* transform: translate(-50%, -50%); */
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* -------------------------------------------- header */
/* .header-content -> BECOME -> Header component */
/* -------------------------------------------- main */
/* -------------------------------------------- success */
.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90vw;
}
.success-svg {
  width: 4rem;
  height: 4rem;
}
.success-message {
  margin-top: 1rem;
}
/* -------------------------------------------- ask-element */
.ask-element {
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 35rem;
  max-width: 90vw;
  padding: 1rem;
}
/* main-title */
.main-title {
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  text-align: center;
  color: white;
}
.title {
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  text-align: center;
  color: blue;
}
/* main-subtitle */
.main-subtitle {
  width: 100%;
  margin-top: 0.8rem;
  font-size: 0.81rem;
  color: white;
}
.row-input {
  margin-left: 10px;
}
/* form */
.form {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 1rem;
}
.form-input {
  display: block;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0.8rem;
  color: rgba(0, 0, 0, 1);
  font-family: "Roboto Bold";
  border: solid 0.1rem rgba(255, 0, 0, 0); /* set here too... to prevent glitch from .errored */
  text-align: center;
}
.form-input:focus {
  background: rgba(255, 255, 255, 1);
}
.submit {
  width: 100%;
  background: rgb(33, 175, 33);
  margin-top: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  box-shadow: -0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.4);
}
.submit:hover {
  box-shadow: -0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.2);
}
.errored {
  border: solid 0.1rem rgba(255, 0, 0, 1);
  color: red;
}
.error-zone {
  display: block;
  position: relative;
  padding-top: 0.4rem;
  color: red;
}
.spinner-w {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  margin-left: 0.4rem;
}
/* -------------------------------------------- back-btn */
.back-btn {
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  width: 19rem;
  font-family: "Roboto Bold";
  max-width: 90vw;
  height: 2rem;
  position: absolute;
  top: 10%;
  left:45%;
  z-index: 3;
  /* margin-top: 0.8rem; */
  justify-content: center;
  align-items: center;
  color: white;
}

.img_config {
  display: flex;
  font-family: "Roboto Bold";
  max-width: 90vw;
  position: absolute;
  top: 10%;
  left:40%;
  margin-top: 0.8rem;
  justify-content: center;
  align-items: center;
}
.information_contact {
    display: flex;
  font-family: "Roboto Bold";
  max-width: 90vw;
  position: absolute;
  top: 60%;
  left:50%;
  margin-top: 0.8rem;
  justify-content: center;
  align-items: center;
}
textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}
/* -------------------------------------------- PORTRAIT */
@media screen and (orientation: portrait) {
}
</style>