<template>
  <div>
    <div v-if="loading">
      <Spinner size="large" :message="'Chargement . . .'"></Spinner>
    </div>
    <div class="twentytwenty-container"
      v-bind:style="containerStyle"
      @touchstart="startSlide"
      @mousedown="startSlide">


      <img :src="rightPhotoUrl" alt="rightPhoto"
        v-on:mousedown.prevent
        v-on:load="setDimensions" />

      <img :src="leftPhotoUrl" alt="leftPhoto"
        v-on:mousedown.prevent
        v-bind:style="beforeImgStyle" />

      <div class="twentytwenty-overlay"
        v-bind:style="overlayStyle">
        <div v-if="leftPhotoLabel" style="white-space: pre-line" class="twentytwenty-before-label">{{leftPhotoLabel}}</div>
        <div v-if="rightPhotoLabel" style="white-space: pre-line" class="twentytwenty-after-label">{{rightPhotoLabel}}</div>
      </div>

      <div class="twentytwenty-handle"
        v-bind:style="handleStyle"
        tabindex="0"
        v-on:keydown="handleArrowNavigation">
          <div class="twentytwenty-arrow-left"></div>
          <div class="twentytwenty-arrow-right"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Spinner from "vue-simple-spinner";
export default {
  name: "PhotoCompare",
  components: {
    Spinner
  },
  props: {
    selectedPhotoLeft:{
      type: Object
    },
    selectedPhotoRight:{
      type: Object
    },
    imageTreatment:{
      type: String
    },
    offset: {
      type: [String, Number],
      default: 0.5,
      validator: (value) => (value > 0 && value <= 1)
    },
    keyboardStep: {
      type: [String, Number],
      default: 0.2,
      validator: (value) => (value > 0 && value <= 1)
    }
  },
  data () {
    return {
      imgOffset: null,
      slideOffset: this.offset,
      sliding: false,
      containerStyle: {},
      overlayStyle: {},
      leftPhoto: {},
      leftPhotoUrl: '',
      rightPhoto: {},
      rightPhotoUrl: '',
      loading: false
    }
  },
  watch: {
    leftPhoto(){
      this.getImageLeft();
    },
    rightPhoto(){
      this.getImageRight();
    },
    selectedPhotoLeft(){
      this.leftPhoto = this.selectedPhotoLeft;
    },
    selectedPhotoRight(){
      this.rightPhoto = this.selectedPhotoRight;
    },
    imageTreatment(){
      this.getImageRight();
    }
  },
  methods: {
    getImageLeft() {
        this.loading = true;
        axios.get(process.env.VUE_APP_API_GET_PHOTO_IMAGE, {
            params: {photo_name: this.leftPhoto.photo_filname,
                     photo_name_other: this.rightPhoto.photo_filname + "$left",
                     processing: "Aucun"},
            headers: {Authorization: `Bearer ${this.$store.state.token}`},
            responseType: 'blob'
        })
        .then((photo)  => {
          this.leftPhotoUrl = URL.createObjectURL(photo.data)
          this.loading = false;
        })
        .catch(error=>{
          console.log(error.response)
        })
    },
    getImageRight() {
      this.loading = true;
        axios.get(process.env.VUE_APP_API_GET_PHOTO_IMAGE, {
            params: {photo_name: this.rightPhoto.photo_filname,
                     photo_name_other: this.leftPhoto.photo_filname + "$right",
                     processing: this.imageTreatment},
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
              "Content-Type": "image/jpeg"},
             responseType: 'blob'
        })
        .then((photo)  => {
          this.rightPhotoUrl = URL.createObjectURL(photo.data)
          this.loading = false;
        })
        .catch(error=>{
          console.log(error.response)
        })
    },
    setDimensions () {
      const img = this.$el.querySelector("img")
      this.imgOffset = img.getBoundingClientRect()
      this.containerStyle = { width: `${this.w}px`, height: `${this.h}px` };
    },
    startSlide (event) {
      this.sliding = true
      this.moveSlide(event)
      this.overlayStyle = { opacity: 0 }
    },
    handleArrowNavigation(event) {
      return this.moveSlide(event)
    },
    moveSlide (event) {
      if (this.sliding) {
        var x = (event.touches ? event.touches[0].pageX : event.pageX) - this.imgOffset.left
        x = (x < 0) ? 0 : ((x > this.w) ? this.w : x)
        return this.slideOffset = (x / this.w)
      }
      if (event.key) {
        switch(event.key) {
          case "Left":     // IE/Edge key
          case "ArrowLeft":  this.slideOffset = ((this.floatOffset - this.floatKeyboardStep) >= 0) ? this.floatOffset - this.floatKeyboardStep : 0 ; break;
          case "Right":    // IE/Edge key
          case "ArrowRight": this.slideOffset = ((this.floatOffset + this.floatKeyboardStep) <= 1) ? this.floatOffset + this.floatKeyboardStep : 1 ; break;
          default: return;
        }
      }
    },
    endSlide () {
      this.sliding = false
      this.overlayStyle = {}
    },
    resize () {
      this.containerStyle = {};
      this.$nextTick(() => this.setDimensions());
    }
  },
  computed: {
    rightPhotoLabel() {
      return (this.rightPhoto.site_name +"\n"+ this.rightPhoto.photo_time +"\n"+ this.rightPhoto.photo_date);
    },
    leftPhotoLabel() {
      return (this.leftPhoto.site_name +"\n"+ this.leftPhoto.photo_time +"\n"+ this.leftPhoto.photo_date);
    },
    beforeImgStyle () {
      return { clip: `rect(0, ${this.x}px, ${this.h}px, 0)` }
    },
    handleStyle () {
      const size = 40;
      return {
        width: `${size}px`,
        height: `${size}px`,
        top:  `calc(50% - ${size/2}px)`,
        left: `calc(${this.slideOffset*100}% - ${size/2}px)`
      }
    },
    x () {
      return this.w * this.slideOffset
    },
    w () {
      return this.imgOffset ? this.imgOffset.width : null
    },
    h () {
      return this.imgOffset ? this.imgOffset.height : null
    },
    floatOffset () {
      return parseFloat(this.slideOffset)
    },
    floatKeyboardStep () {
      return parseFloat(this.keyboardStep)
    }
  },
  mounted () {
    document.addEventListener("touchmove", this.moveSlide)
    document.addEventListener("touchend", this.endSlide)
    document.addEventListener("mousemove", this.moveSlide)
    document.addEventListener("mouseup", this.endSlide)
    window.addEventListener("resize", this.resize)
    this.getImageLeft()
    this.getImageRight()
  },
  beforeDestroy () {
    document.removeEventListener("touchmove", this.moveSlide)
    document.removeEventListener("touchend", this.endSlide)
    document.removeEventListener("mousemove", this.moveSlide)
    document.removeEventListener("mouseup", this.endSlide)
    window.removeEventListener("resize", this.resize)
  }
}
</script>

<style>
.twentytwenty-container {
  z-index: 50;
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
}
.twentytwenty-container img {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  user-select: none;
  z-index: 20;
}
.twentytwenty-container .twentytwenty-overlay {
  z-index: 25;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.05);
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
}
.twentytwenty-container .twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container .twentytwenty-overlay .twentytwenty-after-label {
  user-select: none;
  position: absolute;
  font-size: 0.8em;
  top: calc(50% - 0.4em - 5px);
  padding: 10px;
  background: rgba(255, 255, 255, 0.4);
  color: white;
}
.twentytwenty-container .twentytwenty-overlay .twentytwenty-before-label {
  left: 0;
}
.twentytwenty-container .twentytwenty-overlay .twentytwenty-after-label {
  right: 0;
}
.twentytwenty-container:hover > .twentytwenty-overlay {
  opacity: 1;
}
.twentytwenty-container .twentytwenty-handle {
  cursor: move;
  z-index: 30;
  position: absolute;
  background: none;
  border: 4px solid white;
  border-radius: 50px;
  margin-left: -4px;
  margin-top: -4px;
  user-select: none;
}
.twentytwenty-container .twentytwenty-handle:active,
.twentytwenty-container .twentytwenty-handle:focus {
  outline: 0;
}
.twentytwenty-container .twentytwenty-handle:before, .twentytwenty-container .twentytwenty-handle:after {
  content: "";
  border: 2px solid white;
  height: 9999px;
  position: absolute;
  left: calc(50% - 2px);
}
.twentytwenty-container .twentytwenty-handle:before {
  top: 40px;
}
.twentytwenty-container .twentytwenty-handle:after {
  bottom: 40px;
}
.twentytwenty-container .twentytwenty-arrow-right,
.twentytwenty-container .twentytwenty-arrow-left {
  user-select: none;
  position: relative;
  width: 0;
  height: 0;
}
.twentytwenty-container .twentytwenty-arrow-right {
  bottom: 14px;
  left: 18px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid white;
}
.twentytwenty-container .twentytwenty-arrow-left {
  top: 6px;
  left: 4px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
}
</style>
