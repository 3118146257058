<template>
  <div>
    <div v-if="loading">
      <Spinner size="large" :message="$t('loading')"></Spinner>
    </div>

    <div v-else>

      <div v-if="item !== '3d'">
              <div v-if="item !== '2d'" class="bandeau bandeau_col_full" ref="bandeau">
        <p> {{ module.modulename }} - {{ module.itemname }} </p>
        </div>
        <div v-else class="bandeau_squared" ref="highcharts-bandeau">
          <p> {{ module.modulename }} - {{ module.itemname }} </p>
        </div>

        <highcharts
          v-for="options in optionsList"
          :key="options.title.text"
          :options="options"
          ref="highcharts-container"
          :class="{
            chart_line_tier_col_full: chart_display === 'd3',
            chart_line_half_col_full: chart_display === 'd2',
            chart_line_full_col_half: chart_display === 'd4',
            chart_line_full_col_full: chart_display === 'd1',
            chart_line_half_col_half: chart_display === 'd5',
            chart_squared: chart_display === 'square'
            }"
        ></highcharts>
      </div>
      <div v-else id="3d">
        <highcharts
          v-for="options in optionsList"
          :key="options.title.text"
          :options="options"
          ref="highcharts"
          :class="{
            chart_line_tier_col_full: chart_display === 'd3',
            chart_line_half_col_full: chart_display === 'd2',
            chart_line_full_col_half: chart_display === 'd4',
            chart_line_full_col_full: chart_display === 'd1',
            chart_line_half_col_half: chart_display === 'd5',
            chart_squared: chart_display === 'square',
          }"
          :callback="addEvents"
        ></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import Highcharts3d from "highcharts/highcharts-3d";
import exportingInit from "highcharts/modules/exporting";
import vectorInit from 'highcharts/modules/vector'
import HeatMapInit from 'highcharts/modules/heatmap'
import offlineExporting from "highcharts/modules/offline-exporting";
import stockInit from "highcharts/modules/stock";
import Annotation from "../../node_modules/highcharts/modules/annotations.js";
import customEvent from "highcharts-custom-events";
import { Chart } from "highcharts-vue";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import { eventBus } from "../main";
import JQuery from "jquery";
import boost from "highcharts/modules/boost";
import _ from "lodash";
import HC1ResizeBar from "../components/HC1ResizeBar";
import { svg } from "../assets/svg";
let $ = JQuery;

HeatMapInit(Highcharts)
vectorInit(Highcharts);
Highcharts3d(Highcharts);
exportingInit(Highcharts);
offlineExporting(Highcharts);
more(Highcharts);
stockInit(Highcharts);
Annotation(Highcharts);
customEvent(Highcharts);
boost(Highcharts);


function copyText(text) {
  navigator.clipboard.writeText(text);
}


Highcharts.SVGRenderer.prototype.symbols.download = function(x, y, w, h) {
  var path = [
    // Arrow stem
    'M', x + w * 0.5, y,
    'L', x + w * 0.5, y + h * 0.7,
    // Arrow head
    'M', x + w * 0.3, y + h * 0.5,
    'L', x + w * 0.5, y + h * 0.7,
    'L', x + w * 0.7, y + h * 0.5,
    // Box
    'M', x, y + h * 0.9,
    'L', x, y + h,
    'L', x + w, y + h,
    'L', x + w, y + h * 0.9
  ];
  return path;
};

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: "",
    rangeSelectorFrom: "Début",
    rangeSelectorTo: "Fin",
  },
});

Highcharts.seriesTypes.line.prototype.drawLegendSymbol =
  Highcharts.seriesTypes.column.prototype.drawLegendSymbol;

let isCtrlClick = false;
$(document).keydown(function (event) {
  isCtrlClick = event.which == "17";
});
$(document).keyup(function () {
  isCtrlClick = false;
});
var seriesIndex_past = null;

function setChartSize(e,skipAxes) {

      var chart = e,
        inverted = chart.inverted,
        renderer = chart.renderer,
        chartWidth = chart.chartWidth,
        chartHeight = chart.chartHeight,
        optionsChart = chart.options.chart,
        spacing = chart.spacing,
        clipOffset = chart.clipOffset,
        clipX,
        clipY,
        plotLeft,
        plotTop,
        plotWidth,
        plotHeight,
        plotBorderWidth,
        plotAreaWidth = chart.options.chart.plotAreaWidth,
        plotAreaHeight = chart.options.chart.plotAreaHeight;

      if (plotAreaWidth) {
        chart.plotWidth = plotWidth = plotAreaWidth;
        chart.plotLeft = plotLeft = Math.round((chartWidth - plotAreaWidth) / 2);
      } else {
        chart.plotLeft = plotLeft = Math.round(chart.plotLeft);
        chart.plotWidth = plotWidth = Math.max(0, Math.round(chartWidth - plotLeft - chart.marginRight));
      }
      if (plotAreaHeight) {
        chart.plotTop = plotTop = Math.round((chartHeight - plotAreaHeight) / 2);
        chart.plotHeight = plotHeight = plotAreaHeight;
      } else {
        chart.plotTop = plotTop = Math.round(chart.plotTop);
        chart.plotHeight = plotHeight = Math.max(0, Math.round(chartHeight - plotTop - chart.marginBottom));
      }

      chart.plotSizeX = inverted ? plotHeight : plotWidth;
      chart.plotSizeY = inverted ? plotWidth : plotHeight;

      chart.plotBorderWidth = optionsChart.plotBorderWidth || 0;

      // Set boxes used for alignment
      chart.spacingBox = renderer.spacingBox = {
        x: spacing[3],
        y: spacing[0],
        width: chartWidth - spacing[3] - spacing[1],
        height: chartHeight - spacing[0] - spacing[2]
      };
      chart.plotBox = renderer.plotBox = {
        x: plotLeft,
        y: plotTop,
        width: plotWidth,
        height: plotHeight
      };

      plotBorderWidth = 2 * Math.floor(chart.plotBorderWidth / 2);
      clipX = Math.ceil(Math.max(plotBorderWidth, clipOffset[3]) / 2);
      clipY = Math.ceil(Math.max(plotBorderWidth, clipOffset[0]) / 2);
      chart.clipBox = {
        x: clipX,
        y: clipY,
        width: Math.floor(chart.plotSizeX - Math.max(plotBorderWidth, clipOffset[1]) / 2 - clipX),
        height: Math.max(0, Math.floor(chart.plotSizeY - Math.max(plotBorderWidth, clipOffset[2]) / 2 - clipY))
      };

      if (!skipAxes) {
        Highcharts.each(chart.axes, function(axis) {
          axis.setAxisSize();
          axis.setAxisTranslation();
        });
      }

    }
(function(H) {
  H.seriesTypes.vector.prototype.translate = function() {
    H.Series.prototype.translate.call(this);
    this.lengthMax = H.arrayMax(this.lengthData);
  }

  H.seriesTypes.vector.prototype.arrow = function(point) {
    var path,
      fraction = point.length / 100,
      u = fraction * this.chart.plotHeight / 140,
      o = {
        start: 10 * u,
        center: 0,
        end: -10 * u
      }[this.options.rotationOrigin] || 0;

    // The stem and the arrow head. Draw the arrow first with rotation 0,
    // which is the arrow pointing down (vector from north to south).
    path = [
      'M', 0, 7 * u + o, // base of arrow
      'L', -1.5 * u, 7 * u + o,
      0, 10 * u + o,
      1.5 * u, 7 * u + o,
      0, 7 * u + o,
      0, -10 * u + o // top
    ];

    return path;
  }
})(Highcharts)


function tooltipformatter_selected(e,name) {
  if (e.target.series.userOptions.type != "arearange" &&  e.target.series.userOptions.type != "scatter" ){
        var y = e.target.y.toFixed(2) + e.target.series.userOptions.tooltip.valueSuffix
        var text = '<b>' + Highcharts.dateFormat('%d %b %Y %H:%M', new Date(e.target.x)) + '</b><br>';
        text += ( '<span style="color:' + e.target.series.color + '">\u25CF</span> ' + e.target.series.name + ':' + y  + '<br>');
  }
 return text
}

function tooltipformatter(e,name) {
        var y = null
        var text = '<b>' + Highcharts.dateFormat('%d %b %Y %H:%M', new Date(e.x)) + '</b><br>';

        //const chart = e
        const chart = e.series.chart;

        $.each(chart.series, function(i,serie) {
        if (serie.visible == true){
        if (serie.userOptions.type != "arearange" &&  serie.userOptions.type != "scatter" ){
        const pointind = serie.points.filter(point => point.x === e.x)[0];

        if (pointind != undefined){
        if (pointind.y == undefined){
          pointind.y = serie.yData[pointind.i]
        }
        else if (pointind.y == null){
            y = "NaN"
        } else {
            y = pointind.y.toFixed(2) + serie.userOptions.tooltip.valueSuffix
        }
        text += ( '<span style="color:' + pointind.color + '">\u25CF</span> ' +serie.name + ':' + y  + '<br>');
        }
        }
        }
        })
 return text
}


function sync(ref, event, type,name) {
  Highcharts.charts.forEach((chart, index, array) => {
    if (chart === undefined) {
      return;
    } else {
      chart.series.forEach((series) => {
        if (series.options.type != "arearange"){
        series.data.forEach((point, index) => {
          if (point.x === this.x) {
            if (type === "over") {
              point.setState("hover");

              chart.tooltip.refresh(point);
              series.xAxis.drawCrosshair(event, point);
            } else {
              point.setState();
              chart.tooltip.hide();
              series.xAxis.hideCrosshair();
            }
          }
        });
        }
      });
    }
  });
}

function syncExtremes(e) {
  var thisChart = this.chart;
  if (e.trigger !== "syncExtremes") {
    Highcharts.charts.forEach((chart) => {
      if (chart !== undefined) {
        if (chart.xAxis[0].setExtremes) {
          chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
            trigger: "syncExtremes",
          });
        }
      }
    });
  }
}

// Add mouse and touch events for rotation
var chart3d = [];
var alpha = [];
var beta = [];
var sensitivity = [];
var posX = [];
var posY = [];
var handlers = [];

function dragStart(eStart) {
  Highcharts.charts.forEach((chart, index, array) => {
    if (chart === undefined) {
      return;
    } else if (chart.options.chart.type == "scatter3d") {
      eStart = chart.pointer.normalize(eStart);
      chart3d = chart;
      posX = eStart.chartX;
      posY = eStart.chartY;
      alpha = chart.options.chart.options3d.alpha;
      beta = chart.options.chart.options3d.beta;
      sensitivity = 5; // lower is more sensitive
      handlers = [];
      handlers.push(Highcharts.addEvent(document, "mousemove", drag));
      //handlers.push(Highcharts.addEvent(document, 'touchmove', drag(eStart,chart,alpha,beta,sensitivity,posX,posY)));
      handlers.push(Highcharts.addEvent(document, "mouseup", unbindAll));
      //handlers.push(Highcharts.addEvent(document, 'touchend', unbindAll(handlers)));
    }
  });
}

function drag(e) {
  // Get e.chartX and e.chartY
  e = chart3d.pointer.normalize(e);

  chart3d.update(
    {
      chart: {
        options3d: {
          alpha: alpha + (e.chartY - posY) / sensitivity,
          beta: beta + (posX - e.chartX) / sensitivity,
        },
      },
    },
    undefined,
    undefined,
    false
  );
}

function unbindAll() {
  handlers.forEach(function (unbind) {
    if (unbind) {
      unbind();
    }
  });
  handlers.length = 0;
}

export default {
  components: {
    highcharts: Chart,
    Spinner,
  },
  props: ["apiCallUrl",
          "module",
          "item",
          "type",
          "chart_display",
          "project",
          "sampling",
          "sharedKey"],
  data() {
    return {
      seriesIndex_past: null,
      loading: true,
      optionsList: [],
      ref: 0,
      defaultChartSize: { height: "", width: "" },
    };
  },
  mounted() {
    // if (this.chart_display == "square"){
    //   this.defaultChartSize = { height: "calc(90vh - 2rem)", width: "90vh" }
    // }
    // if (this.type == "heatmap"){
    //   this.defaultChartSize = { height: "calc(90vh - 2rem)", width: "100%" }
    // }
    if (typeof this.$store.state.chartDefaultSize !== "undefined") {
      this.defaultChartSize.height = this.$store.state.chartDefaultSize.styleHeight;
      //this.defaultChartSize.width = this.$store.state.chartDefaultSize.styleWidth;
    }
    //if (typeof this.$store.state.chartDefaultSize !== "undefined") {
    //  this.defaultChartSize.height = this.$store.state.chartDefaultSize.styleHeight;
      //this.defaultChartSize.width = this.$store.state.chartDefaultSize.styleWidth;
    //}
    this.fetchData()

  },
  methods: {


    addEvents(i) {
      Highcharts.addEvent(i.container, "mousedown", dragStart);
      //Highcharts.addEvent(i.container, "touchstart", dragStart);
    },
    fetchData() {
      axios
        .get(this.apiCallUrl, {
          params: {sharedKey: this.sharedKey},
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
          "Cache-Control": "no-cache",
        })
        .then((activity) => {
          if (this.type != "3D" && this.type != "2d" && this.type != "heatmap" && this.type != "heatmap2" && this.type != "non_time_series") {
            this.optionsList = activity.data.datasets.map((dataset, i) => {
              if (activity.data.series_show != undefined){
              dataset.series_unique = activity.data.series_show
            }
              dataset.chartnum = i;
              if (dataset.ShowLegend == false) {
                dataset.showlegend = dataset.ShowLegend;
              }
              else {
                dataset.showlegend = true;
              }
              if (dataset.ShowNavigator == false) {
                dataset.shownavigator = dataset.ShowNavigator;
              }
              else {
                dataset.shownavigator = true;
              }
              if (dataset.TickInterval) {
                dataset.tickinterval = dataset.TickInterval;
              } else {
                dataset.tickinterval = undefined;
              }
              if (dataset.plotLines) {
                dataset.plotline = dataset.plotLines;
                dataset.yaxismax = dataset.plotline[0].value + 1;
                if (dataset.plotline[1]) {
                  dataset.yaxismax = dataset.plotline[1].value + 1;
                }
              } else {
                (dataset.plotline = []), (dataset.yaxismax = null);
              }
              if (dataset.plotBands) {
                dataset.plotbandy = dataset.plotBands;
              }
              if (dataset.plotBandsx) {
                dataset.plotbandx = dataset.plotBandsx.map((val, j) => {
                let correctedDate = new Date(val["from"]);
                let correctedDate_0 = correctedDate.getTime();
                let correctedDate2 = new Date(val["to"]);
                let correctedDate_2 = correctedDate2.getTime();
                return {
                        from: correctedDate_0,
                        to: correctedDate_2,
                        color: val["color"],
                        borderWidth:val["borderWidth"],
                        borderColor: val["borderColor"],
                        label: val["label"]
                      };
                });
              }
              if (dataset.LegendTitle) {
                dataset.legendtitle = dataset.LegendTitle;
              } else {
                dataset.legendtitle = "";
              }
              dataset.yAxistitle = dataset.yAxisLabel;
              if(activity.data.xAxis){
                if(activity.data.xAxis[0].xAxisLabel){
                  dataset.xAxistitle = activity.data.xAxis[0].xAxisLabel;
                }
              }
              dataset.data = dataset.series;
              if (activity.data.yAxismin || activity.data.yAxismax) {
                dataset.yaxismin = activity.data.yAxismin;
                dataset.yaxismax = activity.data.yAxismax;
              }
              if (dataset.yAxismin || dataset.yAxismax) {
                dataset.yaxismin = dataset.yAxismin;
                dataset.yaxismax = dataset.yAxismax;
              }
              if (dataset.data[0].yAxismin || dataset.data[0].yAxismax) {
                dataset.yaxismin = dataset.data[0].yAxismin;
                dataset.yaxismax = dataset.data[0].yAxismax;
              }
              if (dataset.TypeGraph) {
                this.type = dataset.TypeGraph;
              }
              dataset.data.forEach((set, index) => {

                set.data = set.data.map((val, j) => {
                  if (
                    this.item != "vib_hist" &&
                    typeof activity.data.xAxis != "undefined"
                  ) {
                    if (set.type == "scatter"){
                      let correctedDate = new Date(val["x"]);
                      let correctedDate_0 = correctedDate.getTime();
                      return {
                        x: correctedDate_0,
                        y: val["y"],
                        dataLabels: val["dataLabels"],
                      }
                    }
                    if (set.type == "arearange"){
                      if (val["x"]) {
                      let correctedDate = new Date(val["x"]);
                      let correctedDate_0 = correctedDate.getTime();
                      return {
                        x: correctedDate_0,
                        low: val["low"],
                        high: val["high"],
                        //dataLabels: val["dataLabels"],
                      }}
                      else if (val[2]){
                        let correctedDate = new Date(val[0]);
                        let correctedDate_0 = correctedDate.getTime();
                        return [correctedDate_0, val[1],val[2]];
                      } else {
                    let correctedDate = new Date(
                      activity.data.xAxis[0].categories[j]
                    );
                    let correctedDate_0 = correctedDate.getTime();
                    return [correctedDate_0, val[0][0],val[0][1]];}
                    } else {
                    let correctedDate = new Date(
                      activity.data.xAxis[0].categories[j]
                    );
                    let correctedDate_0 = correctedDate.getTime();
                      if (j == 0 && i == 0) {
                        dataset.XminAll = correctedDate_0;
                      }
                      if (set.data.length === j + 1 && i == 0) {
                        dataset.XmaxAll = correctedDate_0;
                      }

                    return [correctedDate_0, val];
                    }
                    //return [val];
                  } else {
                    if (set.type == "arearange"){
                        let correctedDate = new Date(val[0][0]);
                        let correctedDate_0 = correctedDate.getTime();
                        return [correctedDate_0, val[0][1],val[0][2]];
                    }  else if (val["x"]) {
                      let correctedDate = new Date(val["x"]);
                      let correctedDate_0 = correctedDate.getTime();
                      if (j == 0 && i == 0) {
                        dataset.XminAll = correctedDate_0;
                      }
                      if (set.data.length === j + 1 && i == 0) {
                        dataset.XmaxAll = correctedDate_0;
                      }
                      return {
                        x: correctedDate_0,
                        y: val["y"],
                        color: val["color"]
                      };
                    } else if (val[0][0]["x"]) {
                      let correctedDate = new Date(val[0][0]["x"]);
                      let correctedDate_0 = correctedDate.getTime();
                      if (j == 0 && i == 0) {
                        dataset.XminAll = correctedDate_0;
                      }
                      if (set.data.length === j + 1 && i == 0) {
                        dataset.XmaxAll = correctedDate_0;
                      }
                      return {
                        x: correctedDate_0,
                        y: val[0][0]["y"],
                        label: val[0][0]["label"],
                        dataLabels: val[0][0]["dataLabels"],
                      };
                    } else if (val.length==2) {
                      let correctedDate = new Date(val[0]);
                      let correctedDate_0 = correctedDate.getTime(); //- correctedDate.getTimezoneOffset() * 60000;
                      if (j == 0 && i == 0) {
                        dataset.XminAll = correctedDate_0;
                      }
                      if (set.data.length === j + 1 && i == 0) {
                        dataset.XmaxAll = correctedDate_0;
                      }
                      return [correctedDate_0, val[1]];
                    } else {
                      let correctedDate = new Date(val[0][0]);
                      let correctedDate_0 = correctedDate.getTime(); //- correctedDate.getTimezoneOffset() * 60000;
                      if (j == 0 && i == 0) {
                        dataset.XminAll = correctedDate_0;
                      }
                      if (set.data.length === j + 1 && i == 0) {
                        dataset.XmaxAll = correctedDate_0;
                      }
                      return [correctedDate_0, val[0][1]];
                    }
                  }
                });

              });

              dataset.color = Highcharts.getOptions().colors[i];
              return this.genOptions(dataset);
            });
            this.loading = false;
            eventBus.$emit("HC1Loaded", Date.now());
          } else if (this.type == "3D") {
            this.optionsList = activity.data.datasets.map((dataset, i) => {
              dataset.yAxistitle = dataset.yAxisLabel;
              dataset.xtickInterval = activity.data.xtickInterval;
              dataset.ytickInterval = activity.data.ytickInterval;
              dataset.ztickInterval = activity.data.ztickInterval;
              dataset.gridLineWidth = activity.data.gridLineWidth;
              dataset.xaxismin = activity.data.xAxismin;
              dataset.xaxismax = activity.data.xAxismax;
              dataset.yaxismin = activity.data.yAxismin;
              dataset.yaxismax = activity.data.yAxismax;
              dataset.yaxismin = activity.data.zAxismin;
              dataset.yaxismax = activity.data.zAxismax;
              dataset.data = dataset.series;
              return this.genOptions(dataset);
            });
            this.loading = false;
          } else if (this.type == "2d") {
            this.optionsList = activity.data.datasets.map((dataset, i) => {
              dataset.xAxisheight = activity.data.xAxisHeight;
              dataset.xAxiswidth = activity.data.xAxisWidth;
              dataset.yAxisheight = activity.data.yAxisHeight;
              dataset.yAxiswidth = activity.data.yAxisWidth;
              dataset.xaxismin = dataset.xAxismin;
              dataset.xaxismax = dataset.xAxismax;
              dataset.yaxismin = dataset.yAxismin;
              dataset.yaxismax = dataset.yAxismax;
              dataset.tooltiptext = dataset.TooltipText;
              if (dataset.ExtraLegend ){
               dataset.extra_legend = dataset.ExtraLegend
               dataset.extra_legend_unit = dataset.ExtraLegendUnit
               dataset.extra_legend_unit2 = dataset.ExtraLegendUnit2
               dataset.extra_legend_title = dataset.ExtraLegendTitle
               dataset.extra_legend_deparrow = dataset.ExtraLegendDeparrow
              }
               if (dataset.BackgroundImage){
                  dataset.backgroundimage = dataset.BackgroundImage
               } else {
                  dataset.backgroundimage = undefined
               }
              dataset.xtitle = dataset.xTitle;
              dataset.ytitle = dataset.yTitle;
              dataset.showlegend = dataset.ShowLegend;
              dataset.plotLines = dataset.plotlines;
              dataset.data = dataset.series;
              dataset.tickinterval = dataset.TickInterval;
              dataset.title = dataset.Title;
              return this.genOptions(dataset);
            });
            this.loading = false;
          } else if (this.type == "heatmap") {
            this.optionsList = activity.data.datasets.map((dataset, i) => {
              dataset.data = dataset.series;
              dataset.data.forEach((set, index) => {
                set.data = set.data.map((val, j) => {
                   if (set.type == "scatter"){
                    let correctedDate = new Date(val[0]);
                    let correctedDate_0 = correctedDate.getTime();
                      return [correctedDate_0, val[1]];
                   } else {
                    let correctedDate = new Date(val[0]);
                    let correctedDate_0 = correctedDate.getTime();
                      return [correctedDate_0, val[1],val[2]];
                   }
                    })
                  })
              dataset.minscale = dataset.MinScale
              dataset.maxscale = dataset.MaxScale
              dataset.title = dataset.name;
              dataset.ycategories = activity.data.Axis[0]["ycategories"]
              return this.genOptions(dataset);
            });
            this.loading = false;
          } else if (this.type == "heatmap2") {
              this.optionsList = activity.data.datasets.map((dataset, i) => {
              dataset.data = dataset.series;
              dataset.minscale = dataset.MinScale
              dataset.maxscale = dataset.MaxScale
              dataset.title = dataset.name;
              dataset.xcategories = activity.data.Axis[0]["xcategories"]
              dataset.ycategories = activity.data.Axis[0]["ycategories"]
              return this.genOptions(dataset);
              })
          } else if (this.type == "non_time_series") {
            this.optionsList = activity.data.datasets.map((dataset, i) => {
              if (activity.data.series_show != undefined){
              dataset.series_unique = activity.data.series_show
            }
              dataset.chartnum = i;
              if (dataset.ShowLegend == false) {
                dataset.showlegend = dataset.ShowLegend;
              }
              else {
                dataset.showlegend = true;
              }
              // if (dataset.ShowNavigator == false) {
              //   dataset.shownavigator = dataset.ShowNavigator;
              // }
              // else {
              //   dataset.shownavigator = true;
              // }
              if (dataset.TickInterval) {
                dataset.tickinterval = dataset.TickInterval;
              } else {
                dataset.tickinterval = undefined;
              }

              if (dataset.LegendTitle) {
                dataset.legendtitle = dataset.LegendTitle;
              } else {
                dataset.legendtitle = "";
              }
              dataset.yAxistitle = dataset.yAxisLabel;
              dataset.xAxistitle = dataset.xAxisLabel;

              dataset.data = dataset.series;
              if (activity.data.yAxismin || activity.data.yAxismax) {
                dataset.yaxismin = activity.data.yAxismin;
                dataset.yaxismax = activity.data.yAxismax;
              }
              if (dataset.yAxismin || dataset.yAxismax) {
                dataset.yaxismin = dataset.yAxismin;
                dataset.yaxismax = dataset.yAxismax;
              }
              if (dataset.data[0].yAxismin || dataset.data[0].yAxismax) {
                dataset.yaxismin = dataset.data[0].yAxismin;
                dataset.yaxismax = dataset.data[0].yAxismax;
              }
              if (dataset.TypeGraph) {
                this.type = dataset.TypeGraph;
              }

              dataset.color = Highcharts.getOptions().colors[i];
              return this.genOptions(dataset);
            });
            this.loading = false;
            eventBus.$emit("HC1Loaded", Date.now());
          }
        });
    },
    genOptions(dataset) {
      var token = "Bearer " + this.$store.state.token;
      var ref = this.$refs;
      var type = this.type;
      var apiCallUrl = this.apiCallUrl;
      var sampling = this.sampling;
      var module_name = this.module.module;
      var item = this.item;
      var project = this.project;
      var display = this.chart_display;
      var series_show = this.$attrs.series_show;
      var sharedKey = this.sharedKey
      var yAxisconf = null;
      var xAxisconf = null;
      var testX = true;

      if (type == "multi_axes" || type == "time_series") {
        if (type == "multi_axes") {
          yAxisconf = dataset.yAxis;
          if (dataset.xAxis) {
            xAxisconf = dataset.xAxis;
            xAxisconf[0]["min"] = dataset.XminAll;
            xAxisconf[1]["min"] = dataset.XminAll;
            xAxisconf[0]["max"] = dataset.XmaxAll;
            xAxisconf[1]["max"] = dataset.XmaxAll;
            xAxisconf[0]["dateTimeLabelFormats"] = {
              day: "%d %b %y",
              month: "%b %y",
              year: "%y",
            };
            xAxisconf[1]["dateTimeLabelFormats"] = {
              day: "%d %b %y",
              month: "%b %y",
              year: "%y",
            };
            xAxisconf[0]["crosshair"] = {
              width: 2,
              color: "gray",
              dashStyle: "shortdot",
            };
            xAxisconf[1]["crosshair"] = {
              width: 2,
              color: "gray",
              dashStyle: "shortdot",
            };
            xAxisconf[0]["maxPadding"] = 0;
            xAxisconf[1]["maxPadding"] = 0;
            xAxisconf[1]["events"] = {
              setExtremes: function (event) {
                syncExtremes;
                Highcharts.charts.forEach((chart) => {
                  if (
                    typeof chart !== "undefined" &&
                    chart.title.textStr == dataset.name
                  ) {
                    if (
                      chart.resetZoomButton != undefined &&
                      chart.resetZoomButton.visibility == "hidden"
                    ) {
                      chart.showResetZoom();
                    }

                    if (event.min == undefined) {
                      // Reset zoom

                      if (event.trigger == "zoom") {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                        testX = true;
                      }
                    } else {
                      if (
                        event.trigger == "zoom" &&
                        dataset.XminAll == event.min
                      ) {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                        testX = true;
                      } else if (
                        event.trigger == "syncExtremes" &&
                        dataset.XminAll == event.min
                      ) {
                        //chart.resetXButton.hide();
                        chart.resetXButton.hide();

                        testX = false;
                      } else if (
                        event.trigger == "zoom" &&
                        dataset.XminAll != event.min
                      ) {
                        // Start zoom
                        chart.resetXButton.show();
                        chart.resetYButton.show();
                        //chart.showResetZoom();
                        testX = false;
                      } else if (event.trigger == "test") {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                      } else {
                        // Start zoom
                        if (chart.resetXButton) {
                        chart.resetXButton.show();
                        }
                        if (chart.resetYButton) {
                        chart.resetYButton.show();
                        }
                        //chart.showResetZoom();
                        testX = false;
                      }
                    }
                  }
                });
              },
              afterSetExtremes: function (event) {
                let start = null;
                let stop = null;
                // After a zooming event, which resets the extreme values, we reload new data
                if (
                  event.trigger == "zoom" ||
                  event.trigger == "rangeSelectorButton" ||
                  event.trigger == "navigator"
                ) {
                  // Get timestamps of the start and stop of the zoom window, format them, build query string for api
                  if (event.userMax == undefined || testX == true) {
                    start = encodeURIComponent(
                      Highcharts.dateFormat(
                        "%Y-%m-%d %H:%M:%S",
                        dataset.XminAll
                      )
                    );
                    stop = encodeURIComponent(
                      Highcharts.dateFormat(
                        "%Y-%m-%d %H:%M:%S",
                        dataset.XmaxAll
                      )
                    );
                  } else {
                    start = encodeURIComponent(
                      Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.min)
                    );
                    stop = encodeURIComponent(
                      Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.max)
                    );
                  }

                  // We passed in the F from Home through props to the HC component, then through the
                  //genOptions function call
                  const apiCallUrlWindow = `${apiCallUrl}?start=${start}&stop=${stop}`;

                  // Get chart in question
                  const { chart } = event.target;
                  chart.showLoading("Loading data from server..."); // Show reload message (replace with spinner)

                  // Call api with the query string
                  // Returns JSON data of the same format as the original, but cut to
                  // the time window and downsampled

                  axios
                    .get(apiCallUrlWindow, {
                      params: {sharedKey: sharedKey},
                      headers: { Authorization: token },
                      "Cache-Control": "no-cache",
                    })
                    .then((jsonData) => {
                      var correctedTime = null;
                      if (typeof jsonData.data.xAxis != "undefined") {
                        // get vector of formated timestamps
                        correctedTime = jsonData.data.xAxis[0].categories.map(
                          (date) => new Date(date).getTime()
                        );
                      } else if (jsonData.data.datasets[0].series[0].data[0]['x']) {
                          correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val['x']).getTime()
                        );
                      } else {
                        correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val[0][0]).getTime()
                        );
                      }

                      // Loop over series in "datasets"

                      let jsonSeries = jsonData.data.datasets[dataset.chartnum];
                      for (var jsonSerie of jsonSeries.series) {
                        if (!jsonSerie.name.includes("seuil")) {
                          if(!jsonSerie.name.includes("Préc.")) {
                            if(!jsonSerie.name.includes("rms")) {
                          // Build new series array with like :
                          // [[formated timestamp, datapoint], ...]

                          var newSeries = null;
                          if (typeof jsonData.data.xAxis != "undefined") {
                            newSeries = correctedTime.map((timePoint, i) => {
                              return [timePoint, jsonSerie.data[i]];
                            });
                          } else {
                            newSeries = correctedTime.map((timePoint, i) => {
                              if (jsonSerie.data[i]['x']) {
                            return [new Date(jsonSerie.data[i]['x']).getTime(),jsonSerie.data[i]['y']]
                              } else {
                           return [new Date(jsonSerie.data[i][0][0]).getTime(),jsonSerie.data[i][0][1]]
                              }
                            });
                          }

                          //Get index of series in chart that matches the series name from
                          // the json data
                          let seriesName = jsonSerie.name;
                          let idx = chart.series.findIndex(
                            (x) => x.name === seriesName
                          );

                          // setData is important, as simply setting the series data equal to the newSeries doesn't work
                          // console.time('someFunction')
                          chart.series[idx].setData(newSeries, false);
                          // console.timeEnd('someFunction')
                        }}}
                      }
                      chart.redraw();
                      chart.hideLoading();
                    });
                }
              },
            };
            xAxisconf[0]["events"] = {
              setExtremes: function (event) {
                syncExtremes;
                Highcharts.charts.forEach((chart) => {
                  if (
                    typeof chart !== "undefined" &&
                    chart.title.textStr == dataset.name
                  ) {
                    if (
                      chart.resetZoomButton != undefined &&
                      chart.resetZoomButton.visibility == "hidden"
                    ) {
                      chart.showResetZoom();
                    }

                    if (event.min == undefined) {
                      // Reset zoom

                      if (event.trigger == "zoom") {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                        testX = true;
                      }
                    } else {
                      if (
                        event.trigger == "zoom" &&
                        dataset.XminAll == event.min
                      ) {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                        testX = true;
                      } else if (
                        event.trigger == "syncExtremes" &&
                        dataset.XminAll == event.min
                      ) {
                        //chart.resetXButton.hide();
                        chart.resetXButton.hide();

                        testX = false;
                      } else if (
                        event.trigger == "zoom" &&
                        dataset.XminAll != event.min
                      ) {
                        // Start zoom
                        chart.resetXButton.show();
                        chart.resetYButton.show();
                        //chart.showResetZoom();
                        testX = false;
                      } else if (event.trigger == "test") {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                      } else {
                        // Start zoom
                        if (chart.resetXButton) {
                        chart.resetXButton.show();
                        }
                        if (chart.resetYButton) {
                        chart.resetYButton.show();
                        }
                        //chart.showResetZoom();
                        testX = false;
                      }
                    }
                  }
                });
              },
              afterSetExtremes: function (event) {
                let start = null;
                let stop = null;
                // After a zooming event, which resets the extreme values, we reload new data
                if (
                  event.trigger == "zoom" ||
                  event.trigger == "rangeSelectorButton" ||
                  event.trigger == "navigator"
                ) {
                  // Get timestamps of the start and stop of the zoom window, format them, build query string for api
                  if (event.userMax == undefined || testX == true) {
                    start = encodeURIComponent(
                      Highcharts.dateFormat(
                        "%Y-%m-%d %H:%M:%S",
                        dataset.XminAll
                      )
                    );
                    stop = encodeURIComponent(
                      Highcharts.dateFormat(
                        "%Y-%m-%d %H:%M:%S",
                        dataset.XmaxAll
                      )
                    );
                  } else {
                    start = encodeURIComponent(
                      Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.min)
                    );
                    stop = encodeURIComponent(
                      Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.max)
                    );
                  }

                  // We passed in the F from Home through props to the HC component, then through the
                  //genOptions function call
                  const apiCallUrlWindow = `${apiCallUrl}?start=${start}&stop=${stop}`;

                  // Get chart in question
                  const { chart } = event.target;
                  chart.showLoading("Loading data from server..."); // Show reload message (replace with spinner)

                  // Call api with the query string
                  // Returns JSON data of the same format as the original, but cut to
                  // the time window and downsampled

                  axios
                    .get(apiCallUrlWindow, {
                      params: {sharedKey: sharedKey},
                      headers: { Authorization: token },
                      "Cache-Control": "no-cache",
                    })
                    .then((jsonData) => {
                      var correctedTime = null;
                      if (typeof jsonData.data.xAxis != "undefined") {
                        // get vector of formated timestamps
                        correctedTime = jsonData.data.xAxis[0].categories.map(
                          (date) => new Date(date).getTime()
                        );
                      } else if (jsonData.data.datasets[0].series[0].data[0]['x']) {
                          correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val['x']).getTime()
                        );
                      } else {
                        correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val[0][0]).getTime()
                        );
                      }

                      // Loop over series in "datasets"

                      let jsonSeries = jsonData.data.datasets[dataset.chartnum];
                      for (var jsonSerie of jsonSeries.series) {
                        if (!jsonSerie.name.includes("seuil")) {
                          if(!jsonSerie.name.includes("rms")) {
                          // Build new series array with like :
                          // [[formated timestamp, datapoint], ...]

                          var newSeries = null;
                          if (typeof jsonData.data.xAxis != "undefined") {
                            newSeries = correctedTime.map((timePoint, i) => {
                              return [timePoint, jsonSerie.data[i]];
                            });
                          } else {
                            newSeries = correctedTime.map((timePoint, i) => {
                              if (jsonSerie.data[i]['x']) {
                            return [new Date(jsonSerie.data[i]['x']).getTime(),jsonSerie.data[i]['y']]
                              } else {
                           return [new Date(jsonSerie.data[i][0][0]).getTime(),jsonSerie.data[i][0][1]]
                              }
                            });
                          }

                          //Get index of series in chart that matches the series name from
                          // the json data
                          let seriesName = jsonSerie.name;
                          let idx = chart.series.findIndex(
                            (x) => x.name === seriesName
                          );

                          // setData is important, as simply setting the series data equal to the newSeries doesn't work
                          // console.time('someFunction')
                          chart.series[idx].setData(newSeries, false);
                          // console.timeEnd('someFunction')
                        }}
                      }
                      chart.redraw();
                      chart.hideLoading();
                    });
                }
              },
            };
          } else {
            xAxisconf = {
            type: "datetime",
            plotBands: dataset.plotbandx,
            min: dataset.XminAll,
            max: dataset.XmaxAll,
            dateTimeLabelFormats: {
              day: "%d %b %y",
              month: "%b %y",
              year: "%y",
            },
            crosshair: {
              width: 2,
              color: "gray",
              dashStyle: "shortdot",
            },
            maxPadding: 0,
            events: {
              setExtremes: function (event) {
                syncExtremes;
                Highcharts.charts.forEach((chart) => {
                  if (
                    typeof chart !== "undefined" &&
                    chart.title.textStr == dataset.name
                  ) {
                    if (
                      chart.resetZoomButton != undefined &&
                      chart.resetZoomButton.visibility == "hidden"
                    ) {
                      chart.showResetZoom();
                    }

                    if (event.min == undefined) {
                      // Reset zoom

                      if (event.trigger == "zoom") {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                        testX = true;
                      }
                    } else {
                      if (
                        event.trigger == "zoom" &&
                        dataset.XminAll == event.min
                      ) {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                        testX = true;
                      } else if (
                        event.trigger == "syncExtremes" &&
                        dataset.XminAll == event.min
                      ) {
                        //chart.resetXButton.hide();
                        chart.resetXButton.hide();

                        testX = false;
                      } else if (
                        event.trigger == "zoom" &&
                        dataset.XminAll != event.min
                      ) {
                        // Start zoom
                        chart.resetXButton.show();
                        chart.resetYButton.show();
                        //chart.showResetZoom();
                        testX = false;
                      } else if (event.trigger == "test") {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                      } else {
                        // Start zoom
                        if (chart.resetXButton) {
                        chart.resetXButton.show();
                        }
                        if (chart.resetYButton) {
                        chart.resetYButton.show();
                        }
                        //chart.showResetZoom();
                        testX = false;
                      }
                    }
                  }
                });
              },
              afterSetExtremes: function (event) {
                let start = null;
                let stop = null;
                // After a zooming event, which resets the extreme values, we reload new data
                if (
                  event.trigger == "zoom" ||
                  event.trigger == "rangeSelectorButton" ||
                  event.trigger == "navigator"
                ) {
                  // Get timestamps of the start and stop of the zoom window, format them, build query string for api
                  if (event.userMax == undefined || testX == true) {
                    start = encodeURIComponent(
                      Highcharts.dateFormat(
                        "%Y-%m-%d %H:%M:%S",
                        dataset.XminAll
                      )
                    );
                    stop = encodeURIComponent(
                      Highcharts.dateFormat(
                        "%Y-%m-%d %H:%M:%S",
                        dataset.XmaxAll
                      )
                    );
                  } else {
                    start = encodeURIComponent(
                      Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.min)
                    );
                    stop = encodeURIComponent(
                      Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.max)
                    );
                  }

                  // We passed in the F from Home through props to the HC component, then through the
                  //genOptions function call
                  const apiCallUrlWindow = `${apiCallUrl}?start=${start}&stop=${stop}`;

                  // Get chart in question
                  const { chart } = event.target;
                  chart.showLoading("Loading data from server..."); // Show reload message (replace with spinner)

                  // Call api with the query string
                  // Returns JSON data of the same format as the original, but cut to
                  // the time window and downsampled

                  axios
                    .get(apiCallUrlWindow, {
                      params: {sharedKey: sharedKey},
                      headers: { Authorization: token },
                      "Cache-Control": "no-cache",
                    })
                    .then((jsonData) => {
                      var correctedTime = null;
                      if (typeof jsonData.data.xAxis != "undefined") {
                        // get vector of formated timestamps
                        correctedTime = jsonData.data.xAxis[0].categories.map(
                          (date) => new Date(date).getTime()
                        );
                        } else if (jsonData.data.datasets[0].series[0].data[0]['x']) {
                          correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val['x']).getTime()
                        );
                      } else {
                        correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val[0][0]).getTime()
                        );
                      }

                      // Loop over series in "datasets"

                      let jsonSeries = jsonData.data.datasets[dataset.chartnum];
                      for (var jsonSerie of jsonSeries.series) {
                        if (!jsonSerie.name.includes("seuil")) {
                          if(!jsonSerie.name.includes("Préc.")) {
                            if(!jsonSerie.name.includes("rms")) {

                          // Build new series array with like :
                          // [[formated timestamp, datapoint], ...]

                          var newSeries = null;
                          if (typeof jsonData.data.xAxis != "undefined") {
                            newSeries = correctedTime.map((timePoint, i) => {
                              return [timePoint, jsonSerie.data[i]];
                            });
                          } else {
                            newSeries = correctedTime.map((timePoint, i) => {
                              if (jsonSerie.data[i]['x']) {
                            return [new Date(jsonSerie.data[i]['x']).getTime(),jsonSerie.data[i]['y']]
                              } else {
                           return [new Date(jsonSerie.data[i][0][0]).getTime(),jsonSerie.data[i][0][1]]
                              }
                            });
                          }

                          //Get index of series in chart that matches the series name from
                          // the json data
                          let seriesName = jsonSerie.name;
                          let idx = chart.series.findIndex(
                            (x) => x.name === seriesName
                          );

                          // setData is important, as simply setting the series data equal to the newSeries doesn't work
                          // console.time('someFunction')
                          chart.series[idx].setData(newSeries, false);
                          // console.timeEnd('someFunction')
                        }}}
                      }
                      chart.redraw();
                      chart.hideLoading();
                    });
                }
              },
            },
            labels: {
            style: {
                fontSize: '12px',
                fontWeight: "bold",
            }
          }
          }
          }
        } else {
          yAxisconf = {
            min: dataset.yaxismin,
            max: dataset.yaxismax,
            plotLines: dataset.plotline,
            plotBands: dataset.plotbandy,
            TickInterval: dataset.tickinterval,
            title: {
              margin: 10,
              text: dataset.yAxistitle,
              style: {
                fontSize: "16px",
                fontWeight: "bold",
              },
              events: {
                click: function (e, test) {
                  let testMin = window.prompt("Y min ?");
                  let testMax = window.prompt("Y max ?");
                  Highcharts.charts.forEach((chart) => {
                    if (
                      typeof chart !== "undefined" &&
                      chart.title.textStr == dataset.name
                    ) {
                      if(!dataset.name.includes("+ meteo")) {
                      chart.yAxis[0].setExtremes(
                        testMin,
                        testMax,
                        undefined,
                        false,
                        {
                          trigger: "syncExtremes",
                        }
                      );
                      chart.resetYButton.show();
                    }}
                  });
                },
              },
            },
            labels: {
            style: {
                fontSize: '12px',
                fontWeight: "bold",
            }
            }
          };
          xAxisconf = {
            type: "datetime",
            plotBands: dataset.plotbandx,
            min: dataset.XminAll,
            max: dataset.XmaxAll,
            dateTimeLabelFormats: {
              day: "%d %b %y",
              month: "%b %y",
              year: "%y",
            },
            crosshair: {
              width: 2,
              color: "gray",
              dashStyle: "shortdot",
            },
            maxPadding: 0,
            events: {
              setExtremes: function (event) {
                syncExtremes;
                Highcharts.charts.forEach((chart) => {
                  if (
                    typeof chart !== "undefined" &&
                    chart.title.textStr == dataset.name
                  ) {
                    if (
                      chart.resetZoomButton != undefined &&
                      chart.resetZoomButton.visibility == "hidden"
                    ) {
                      chart.showResetZoom();
                    }

                    if (event.min == undefined) {
                      // Reset zoom

                      if (event.trigger == "zoom") {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                        testX = true;
                      }
                    } else {
                      if (
                        event.trigger == "zoom" &&
                        dataset.XminAll == event.min
                      ) {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                        testX = true;
                      } else if (
                        event.trigger == "syncExtremes" &&
                        dataset.XminAll == event.min
                      ) {
                        //chart.resetXButton.hide();
                        chart.resetXButton.hide();

                        testX = false;
                      } else if (
                        event.trigger == "zoom" &&
                        dataset.XminAll != event.min
                      ) {
                        // Start zoom
                        chart.resetXButton.show();
                        chart.resetYButton.show();
                        //chart.showResetZoom();
                        testX = false;
                      } else if (event.trigger == "test") {
                        chart.resetXButton.hide();
                        chart.resetYButton.hide();
                      } else {
                        // Start zoom
                        if (chart.resetXButton) {
                        chart.resetXButton.show();
                        }
                        if (chart.resetYButton) {
                        chart.resetYButton.show();
                        }
                        //chart.showResetZoom();
                        testX = false;
                      }
                    }
                  }
                });
              },
              afterSetExtremes: function (event) {
                let start = null;
                let stop = null;
                // After a zooming event, which resets the extreme values, we reload new data
                if (
                  event.trigger == "zoom" ||
                  event.trigger == "rangeSelectorButton" ||
                  event.trigger == "navigator"
                ) {
                  // Get timestamps of the start and stop of the zoom window, format them, build query string for api
                  if (event.userMax == undefined || testX == true) {
                    start = encodeURIComponent(
                      Highcharts.dateFormat(
                        "%Y-%m-%d %H:%M:%S",
                        dataset.XminAll
                      )
                    );
                    stop = encodeURIComponent(
                      Highcharts.dateFormat(
                        "%Y-%m-%d %H:%M:%S",
                        dataset.XmaxAll
                      )
                    );
                  } else {
                    start = encodeURIComponent(
                      Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.min)
                    );
                    stop = encodeURIComponent(
                      Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.max)
                    );
                  }

                  // We passed in the F from Home through props to the HC component, then through the
                  //genOptions function call
                  const apiCallUrlWindow = `${apiCallUrl}?start=${start}&stop=${stop}`;

                  // Get chart in question
                  const { chart } = event.target;
                  chart.showLoading("Loading data from server..."); // Show reload message (replace with spinner)

                  // Call api with the query string
                  // Returns JSON data of the same format as the original, but cut to
                  // the time window and downsampled

                  axios
                    .get(apiCallUrlWindow, {
                      params: {sharedKey: sharedKey},
                      headers: { Authorization: token },
                      "Cache-Control": "no-cache",
                    })
                    .then((jsonData) => {

                      var correctedTime = null;
                      if (typeof jsonData.data.xAxis != "undefined") {
                        // get vector of formated timestamps
                        correctedTime = jsonData.data.xAxis[0].categories.map(
                          (date) => new Date(date).getTime()
                        );
                      } else if (jsonData.data.datasets[0].series[0].data[0]['x']) {
                          correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val['x']).getTime()
                        );
                      } else {
                        correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val[0][0]).getTime()
                        );
                      }

                      // Loop over series in "datasets"

                      let jsonSeries = jsonData.data.datasets[dataset.chartnum];
                      for (var jsonSerie of jsonSeries.series) {
                        if (!jsonSerie.name.includes("seuil")) {
                          if(!jsonSerie.name.includes("Préc.")) {
                              if(!jsonSerie.name.includes("rms")) {

                          // Build new series array with like :
                          // [[formated timestamp, datapoint], ...]

                          var newSeries = null;
                          if (typeof jsonData.data.xAxis != "undefined") {
                            newSeries = correctedTime.map((timePoint, i) => {
                              return [timePoint, jsonSerie.data[i]];
                              })
                          } else {
                            newSeries = correctedTime.map((timePoint, i) => {
                              if (jsonSerie.data[i]['x']) {
                            return [new Date(jsonSerie.data[i]['x']).getTime(),jsonSerie.data[i]['y']]
                              } else {
                           return [new Date(jsonSerie.data[i][0][0]).getTime(),jsonSerie.data[i][0][1]]
                              }
                            });
                          }


                          //Get index of series in chart that matches the series name from
                          // the json data
                          let seriesName = jsonSerie.name;
                          let idx = chart.series.findIndex(
                            (x) => x.name === seriesName
                          );

                          // setData is important, as simply setting the series data equal to the newSeries doesn't work
                          // console.time('someFunction')
                          chart.series[idx].setData(newSeries, false);
                          // console.timeEnd('someFunction')
                        }}}
                      }
                      chart.redraw();
                      chart.hideLoading();
                    });
                }
              },
            },
            labels: {
            style: {
                fontSize: '16px',
                fontWeight: "bold",
                }
            }
          }
        }
        return {
          chart: {
            marginLeft: 80, // Keep all charts left aligned
            spacingTop: 40,
            spacingBottom: 20,
            zoomType: "xy",
            resetZoomButton: {
              theme: {
                display: "none",
              },
            },
            resetXButton: null,
            resetYButton: null,
            resetAllButton: null,
            testX: false,
            apiCallUrl: apiCallUrl, // Added for downsampling POC
            token: token, // Added for downsampling POC (maybe not ideal, as it isn't the normal way of accessing the token ... )
            events: {
              load: function () {

                if (dataset.series_unique){
                  var chart = this
                  chart.series.map((ser, i) => {
                      if (ser.name == dataset.series_unique){
                        ser.setVisible(true, false)
                      } else {
                      ser.setVisible(false, false)
                      }
                  })
                }
                this.resetXButton = this.renderer
                  .button("Reset X", 70, 0)
                  .attr({
                    padding: 4,
                    zIndex: 3,
                  })
                  .on("click", function () {
                    testX = true;
                    let test = Highcharts.charts;
                    test = _.without(Highcharts.charts, undefined);
                    let idchart = test.findIndex(
                      (x) => x.title.textStr === dataset.name
                    );
                    //let idchart = Highcharts.charts.findIndex(x => x.title.textStr === dataset.name)
                    let chart = test[idchart];
                    //Highcharts.charts.forEach((chart) => {
                    if (
                      typeof chart !== "undefined" &&
                      chart.title.textStr == dataset.name
                    ) {
                      chart.xAxis[0].setExtremes(
                        dataset.XminAll,
                        dataset.XmaxAll,
                        true,
                        false,
                        {
                          trigger: "syncExtremes",
                        }
                      );

                      let start = encodeURIComponent(
                        Highcharts.dateFormat(
                          "%Y-%m-%d %H:%M:%S",
                          dataset.XminAll
                        )
                      );
                      let stop = encodeURIComponent(
                        Highcharts.dateFormat(
                          "%Y-%m-%d %H:%M:%S",
                          dataset.XmaxAll
                        )
                      );

                      // We passed in the apiCallUrl from Home through props to the HC component, then through the
                      //genOptions function call
                      const apiCallUrlWindow = `${apiCallUrl}?start=${start}&stop=${stop}`;

                      // Get chart in question
                      chart.showLoading("Loading data from server..."); // Show reload message (replace with spinner)

                      // Call api with the query string
                      // Returns JSON data of the same format as the original, but cut to
                      // the time window and downsampled
                      axios
                        .get(apiCallUrlWindow, {
                          params: {sharedKey: sharedKey},
                          headers: { Authorization: token },
                          "Cache-Control": "no-cache",
                        })
                        .then((jsonData) => {
                          var correctedTime = null;
                          if (typeof jsonData.data.xAxis != "undefined") {
                            // get vector of formated timestamps
                            correctedTime = jsonData.data.xAxis[0].categories.map(
                              (date) => new Date(date).getTime()
                            );
                      } else if (jsonData.data.datasets[0].series[0].data[0]['x']) {
                          correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val['x']).getTime()
                        );
                      } else {
                        correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val[0][0]).getTime()
                        );
                      }
                          // Loop over series in "datasets"
                          let jsonSeries =
                            jsonData.data.datasets[dataset.chartnum];
                          //for (let i = 0; i < jsonData.data.datasets.length; i++ ){
                          //let jsonSeries = jsonData.data.datasets[i];
                          //let jsonSeries = jsonData.data.datasets[0];
                          for (var jsonSerie of jsonSeries.series) {
                            if (!jsonSerie.name.includes("seuil")) {
                              if(!jsonSerie.name.includes("Préc.")) {
                                if(!jsonSerie.name.includes("rms")) {
                              // Build new series array with like :
                              // [[formated timestamp, datapoint], ...]
                              var newSeries = null;
                              if (typeof jsonData.data.xAxis != "undefined") {
                                newSeries = correctedTime.map(
                                  (timePoint, i) => {
                                    return [timePoint, jsonSerie.data[i]];
                                  }
                                );
                          } else {
                            newSeries = correctedTime.map((timePoint, i) => {
                              if (jsonSerie.data[i]['x']) {
                            return [new Date(jsonSerie.data[i]['x']).getTime(),jsonSerie.data[i]['y']]
                              } else {
                           return [new Date(jsonSerie.data[i][0][0]).getTime(),jsonSerie.data[i][0][1]]
                              }
                            });
                          }

                              //Get index of series in chart that matches the series name from
                              // the json data
                              let seriesName = jsonSerie.name;
                              let idx = chart.series.findIndex(
                                (x) => x.name === seriesName
                              );

                              // setData is important, as simply setting the series data equal to the newSeries doesn't work
                              chart.series[idx].setData(newSeries, false);
                            }}}
                          }
                          chart.redraw();
                          chart.hideLoading();
                        });
                      chart.resetXButton.hide();
                      if (chart.resetZoomButton != undefined) {
                        chart.resetZoomButton.hide();
                      }
                    }
                    //});
                  })
                  .add();
                this.resetYButton = this.renderer
                  .button("Reset Y", 130, 0)
                  .attr({
                    padding: 4,
                    zIndex: 3,
                  })
                  .on("click", function () {
                    let test = Highcharts.charts;
                    test = _.without(Highcharts.charts, undefined);
                    let idchart = test.findIndex(
                      (x) => x.title.textStr === dataset.name
                    );
                    //let idchart = Highcharts.charts.findIndex(x => x.title.textStr === dataset.name)
                    let chart = test[idchart];
                    //Highcharts.charts.forEach((chart) => {
                    if (
                      typeof chart !== "undefined" &&
                      chart.title.textStr == dataset.name
                    ) {
                      chart.yAxis.map((yAxisnum) =>
                        yAxisnum.setExtremes(
                          undefined,
                          undefined,
                          undefined,
                          false,
                          {
                            trigger: "syncExtremes",
                          }
                        )
                      );
                      chart.resetYButton.hide();

                      if (chart.resetZoomButton != undefined) {
                        chart.resetZoomButton.hide();
                      }
                    }
                    //});
                  })
                  .add();
                this.resetAllButton = this.renderer
                  .button("Reset All", 0, 0)
                  .attr({
                    padding: 4,
                    zIndex: 0,
                  })
                  .on("click", function () {
                    testX = true;
                    let test = Highcharts.charts;
                    test = _.without(Highcharts.charts, undefined);
                    let idchart = test.findIndex(
                      (x) => x.title.textStr === dataset.name
                    );
                    //let idchart = Highcharts.charts.findIndex(x => x.title.textStr === dataset.name)
                    let chart = test[idchart];
                    //Highcharts.charts.forEach((chart) => {
                    if (
                      typeof chart !== "undefined" &&
                      chart.title.textStr == dataset.name
                    ) {
                      chart.yAxis.map((yAxisnum) =>
                        yAxisnum.setExtremes(
                          undefined,
                          undefined,
                          undefined,
                          false,
                          {
                            trigger: "test",
                          }
                        )
                      );
                      chart.xAxis[0].setExtremes(
                        dataset.XminAll,
                        dataset.XmaxAll,
                        true,
                        false,
                        {
                          trigger: "test",
                        }
                      );
                      //this.resetXButton.hide(); // Hide by default
                      //this.resetYButton.hide(); // Hide by default
                      let start = encodeURIComponent(
                        Highcharts.dateFormat(
                          "%Y-%m-%d %H:%M:%S",
                          dataset.XminAll
                        )
                      );
                      let stop = encodeURIComponent(
                        Highcharts.dateFormat(
                          "%Y-%m-%d %H:%M:%S",
                          dataset.XmaxAll
                        )
                      );

                      // We passed in the apiCallUrl from Home through props to the HC component, then through the
                      //genOptions function call
                      const apiCallUrlWindow = `${apiCallUrl}?start=${start}&stop=${stop}`;

                      // Get chart in question
                      chart.showLoading("Loading data from server..."); // Show reload message (replace with spinner)

                      // Call api with the query string
                      // Returns JSON data of the same format as the original, but cut to
                      // the time window and downsampled
                      axios
                        .get(apiCallUrlWindow, {
                          params: {sharedKey: sharedKey},
                          headers: { Authorization: token },
                          "Cache-Control": "no-cache",
                        })
                        .then((jsonData) => {
                          var correctedTime = null;
                          if (typeof jsonData.data.xAxis != "undefined") {
                            // get vector of formated timestamps
                            correctedTime = jsonData.data.xAxis[0].categories.map(
                              (date) => new Date(date).getTime()
                            );
                      } else if (jsonData.data.datasets[0].series[0].data[0]['x']) {
                          correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val['x']).getTime()
                        );
                      } else {
                        correctedTime = jsonData.data.datasets[0].series[0].data.map(
                          (val) => new Date(val[0][0]).getTime()
                        );
                      }
                          // Loop over series in "datasets"
                          //for (let i = 0; i < jsonData.data.datasets.length; i++ ){
                          let jsonSeries =
                            jsonData.data.datasets[dataset.chartnum];
                          //let jsonSeries = jsonData.data.datasets[0];
                          for (var jsonSerie of jsonSeries.series) {
                            if (!jsonSerie.name.includes("seuil")) {
                              if(!jsonSerie.name.includes("Préc.")) {
                                if(!jsonSerie.name.includes("rms")) {
                              // Build new series array with like :
                              // [[formated timestamp, datapoint], ...]
                              var newSeries = null;
                              if (typeof jsonData.data.xAxis != "undefined") {
                                newSeries = correctedTime.map(
                                  (timePoint, i) => {
                                    return [timePoint, jsonSerie.data[i]];
                                  }
                                );
                          } else {
                            newSeries = correctedTime.map((timePoint, i) => {
                              if (jsonSerie.data[i]['x']) {
                            return [new Date(jsonSerie.data[i]['x']).getTime(),jsonSerie.data[i]['y']]
                              } else {
                           return [new Date(jsonSerie.data[i][0][0]).getTime(),jsonSerie.data[i][0][1]]
                              }
                            });
                          }

                              //Get index of series in chart that matches the series name from
                              // the json data
                              let seriesName = jsonSerie.name;
                              let idx = chart.series.findIndex(
                                (x) => x.name === seriesName
                              );

                              // setData is important, as simply setting the series data equal to the newSeries doesn't work
                              chart.series[idx].setData(newSeries, false);
                            }}}
                          }
                          chart.redraw();
                          chart.hideLoading();
                        });
                      chart.resetXButton.hide();
                      if (chart.resetZoomButton != undefined) {
                        chart.resetZoomButton.hide();
                      }
                    }
                    //});
                  })
                  .add();
                this.resetXButton.hide(); // Hide by default
                this.resetYButton.hide(); // Hide by default
              },
            },
          },
          title: {
            text: dataset.name,
          },
          credits: {
            enabled: false,
          },
          navigator: {
            enabled: dataset.shownavigator,
            adaptToUpdatedData: false,
            series: {
                    id: 'nav'
                }
            //series: {
            //  color: "#FF00FF",
            //  linewidth: 2,
            //},
          },
          rangeSelector: {
            verticalAlign: "top",
            enabled: true,
            inputEnabled: true,

            buttons: [
              {
                type: "month",
                count: 1,
                text: "1mois",
              },
              {
                type: "month",
                count: 3,
                text: "3mois",
              },
              {
                type: "month",
                count: 6,
                text: "6mois",
              },
              {
                type: "year",
                count: 1,
                text: "1an",
              },
              {
                type: "all",
                text: "All",
              },
            ],
            buttonSpacing: 30,
            buttonTheme: {
              style: {
                fill: "none",
                color: "#595959",
              },
              stroke: "none",
              //padding: 10,
              fontWeight: "bold",
              //height: 18,
              width: null,
              "stroke-width": 0,
              r: 10,
              states: {
                hover: {
                  fill: "#0ec3ee",
                  style: {
                    color: "white",
                  },
                },
                select: {
                  fill: "#16aed2",
                  style: {
                    color: "white",
                  },
                },
              },
            },
            //selected: 0,
            inputStyle: {
              background: "#fff",
            },
          },
          xAxis: xAxisconf,
          yAxis: yAxisconf,
          tooltip: {
            shared: false,
            style: {
              fontSize: "0.65rem",
            },
            // positioner: function () {
            //   return { x: 0, y: 0 };
            // },
            shadow: false,
            borderWidth: 0,
            backgroundColor: "rgba(255,255,255,0.0)",
            valueDecimals: 2,
            xDateFormat: "%d %b %Y %H:%M",
          },
          plotOptions: {
            arearange: {
              turboThreshold: 10000,
              states:{
                inactive:{
                  opacity:0.3
                },
                hover:{enabled:false}
              }
            },
            line: {
              turboThreshold: 10000,
              lineWidth: 1.5,
              opacity: 1,
            },
            column: {
              turboThreshold: 10000,
              pointWidth: 4,
              minPointLength: 3,
              borderColor: '#303030'
            },
            series: {
              turboThreshold: 10000,
              stickyTracking: false,
              marker: {
                enabled: true,
                radius: 1,
              },
              animation: {
                duration: 1000,
              },
              states: {
                inactive: {
                  opacity: 1,
                },
              },
              point: {
                events: {
                  mouseOver: function (event) {
                    sync.call(this, ref, event, "over");
                  },
                  mouseOut: function (event) {
                    sync.call(this, ref, event, "out");
                  },
                },
              },
              events: {
                legendItemClick: function (e) {
                  let _this = e.target;
                  let SeriesLinkedIindex = []
                  if (_this.linkedSeries) {
                    _this.linkedSeries.forEach((i) => {
                      SeriesLinkedIindex.push(i.index)
                    })
                  }
                  let seriesIndex = _this.index;
                  SeriesLinkedIindex.push(seriesIndex)
                  let seriesName = _this.name;
                  let series = _this.chart.series;
                  let currentChart = _this.chart;

                  if (!currentChart.seriesOnFocus) {
                    currentChart.seriesOnFocus = [];
                  }
                  if (_this.visible) {
                    if (!currentChart.seriesOnFocus.length) {
                      if (isCtrlClick) {
                        _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                      } else {

                        series.forEach((i) => {

                          if (i.index !== seriesIndex && SeriesLinkedIindex.includes(i.index)==false) {
                            i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                          }

                        });

                        currentChart.seriesOnFocus.push(seriesIndex);

                      }

                      //currentChart.extraChart.serieOnFocus = seriesIndex;
                      //var test = seriesIndex;
                    } else if (currentChart.seriesOnFocus.length === 1) {
                      series.forEach((i) => {
                        if (i.index !== seriesIndex) {
                          i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                        }
                      });
                      currentChart.seriesOnFocus.splice(
                        currentChart.seriesOnFocus.indexOf(seriesIndex),
                        1
                      );
                      //var test = null;
                      //currentChart.extraChart.serieOnFocus = null;
                    } else {
                      _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                      currentChart.seriesOnFocus.splice(
                        currentChart.seriesOnFocus.indexOf(seriesIndex),
                        1
                      );
                      //currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                      //var test = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                    }
                  } else {
                    if (isCtrlClick) {
                      _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                    } else {
                      _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                      currentChart.seriesOnFocus.push(seriesIndex);
                      //currentChart.extraChart.serieOnFocus = seriesIndex;
                      //var test = seriesIndex;
                    }
                  }
                  currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
                  return false; //<- IMPORTANT -> block the "next()" process
                },
              },
            },
          },
          legend: {
            enabled: dataset.showlegend,
            itemDistance: 8,
            symbolRadius: 4,
            title: dataset.legendtitle,
            maxHeight: 80,
          },
          series: dataset.data,
          exporting: {
            enabled: true,
            allowHTML: true,
            fallbackToExportServer: true,
            scale: 1,
            sourceWidth: 1024,
            sourceHeight: 800,
            width: 1024,
            //filename: this.project,
            chartOptions: {
              caption: {
                align: "center",
                text:
                  "<b><span> data are provided by </span>" +
                  "<b>https://evorisk.fr<b>", // + '<img src="/project/logo/geolithe_web.png">'
              },
              // chart: {
              //   events: {
              //     load: function () {
              //       var chart = this;
              //       chart.renderer
              //         .image("/project/logo/geolithe_web.png", 950, 740, 55, 55)
              //         .add()
              //         .toFront();
              //     },
              //   },
              // },
            },
            buttons: {
              contextButton: {
              enabled: true,
              menuItems: ['downloadPNG', 'downloadSVG']
              },
              customButton:{
              symbol: "download",
              menuItems: [{
                          titleKey: 'test',
                          text: 'URL (copied to clipboard)',
                          cursor: 'pointer',
                          onclick: function () {
                            if (this.seriesOnFocus && this.seriesOnFocus.length == 1){
                              series_show = this.series[this.seriesOnFocus[0]].name
                            } else {
                              series_show = false
                            }
                            let requestedGraph = project + '-' + module_name + "-" + item
                            function sharedKey() {
                              axios
                                .get(process.env.VUE_APP_API_GET_SHARED_KEY + '/' + requestedGraph)
                                .then((response) => {
                                  let sharedKey = response.data
                            var url = 'https://' + location.host + "/" + "export-graph/" + project + "/" + module_name + "/" + item + "/" + type + "/" + display + "/" + sampling + "/" + series_show
                            let shareableURL = url + "?sharedKey=" + sharedKey

                            copyText(shareableURL)
                                })
                            }
                            sharedKey()
                            }
                            },
                            ]
              }
            }
          },
        };
      } else if (type == "3D") {
        return {
          credits: {
            enabled: false,
          },
          chart: {
            margin: 100,
            type: "scatter3d",
            animation: false,
            options3d: {
              enabled: true,
              alpha: 10,
              beta: 30,
              depth: 250,
              viewDistance: 5,
              fitToPlot: false,
              frame: {
                bottom: { size: 1, color: "rgba(0,0,0,0.02)" },
                back: { size: 1, color: "rgba(0,0,0,0.04)" },
                side: { size: 1, color: "rgba(0,0,0,0.06)" },
              },
            },
          },
          title: {
            text: "Représentation 3D",
          },
          subtitle: {
            text: "Click and drag the plot area to rotate in space",
          },
          plotOptions: {
            scatter: {
              width: 2,
              height: 2,
              depth: 2,
            },
            series: {
              allowPointSelect: true,
              events: {
            legendItemClick: function (e) {
              let _this = e.target;
              let SeriesLinkedIindex = []
              if (_this.linkedSeries) {
                _this.linkedSeries.forEach((i) => {
                  SeriesLinkedIindex.push(i.index)
                })
              }
              let seriesIndex = _this.index;
              SeriesLinkedIindex.push(seriesIndex)
              let seriesName = _this.name;
              let series = _this.chart.series;
              let currentChart = _this.chart;

              if (!currentChart.seriesOnFocus) {
                currentChart.seriesOnFocus = [];
              }
              if (_this.visible) {
                if (!currentChart.seriesOnFocus.length) {
                  if (isCtrlClick) {
                    _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  } else {

                    series.forEach((i) => {

                      if (i.index !== seriesIndex && SeriesLinkedIindex.includes(i.index)==false) {
                        i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                      }

                    });

                    currentChart.seriesOnFocus.push(seriesIndex);

                  }

                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                } else if (currentChart.seriesOnFocus.length === 1) {
                  series.forEach((i) => {
                    if (i.index !== seriesIndex) {
                      i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                    }
                  });
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //var test = null;
                  //currentChart.extraChart.serieOnFocus = null;
                } else {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                  //var test = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                }
              } else {
                if (isCtrlClick) {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                } else {
                  _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.push(seriesIndex);
                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                }
              }
              currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
              return false; //<- IMPORTANT -> block the "next()" process
            },
              },
            },
          },
          yAxis: {
            title: {
              text: "z",
            },
          },
          xAxis: {
            title: {
              text: "x",
            },
            gridLineWidth: 1,
          },
          zAxis: {
            title: {
              text: "y",
            },
            showFirstLabel: false,
          },
          legend: {
            enabled: false,
            itemDistance: 8,
            symbolRadius: 4,
          },
          series: dataset.data,
          exporting: {
            enabled: true,
            allowHTML: true,
            fallbackToExportServer: true,
            scale: 1,
            sourceWidth: 1024,
            sourceHeight: 800,
            width: 1024,
            //filename: this.project,
            chartOptions: {
              caption: {
                align: "center",
                text:
                  "<b><span> data are provided by </span>" +
                  "<b>https://evorisk.fr<b>", // + '<img src="/project/logo/geolithe_web.png">'
              },
              // chart: {
              //   events: {
              //     load: function () {
              //       var chart = this;
              //       chart.renderer
              //         .image("/project/logo/geolithe_web.png", 950, 740, 55, 55)
              //         .add()
              //         .toFront();
              //     },
              //   },
              // },
            },
            buttons: {
              contextButton: {
              enabled: true,
              menuItems: ['downloadPNG', 'downloadSVG']
              },
              customButton:{
              symbol: "download",
              menuItems: [{
                          titleKey: 'test',
                          text: 'URL (copied to clipboard)',
                          cursor: 'pointer',
                          onclick: function () {
                            if (this.seriesOnFocus && this.seriesOnFocus.length == 1){
                              series_show = this.series[this.seriesOnFocus[0]].name
                            } else {
                              series_show = false
                            }
                            let requestedGraph = project + '-' + module_name + "-" + item
                            function sharedKey() {
                              axios
                                .get(process.env.VUE_APP_API_GET_SHARED_KEY + '/' + requestedGraph)
                                .then((response) => {
                                  let sharedKey = response.data
                            var url = 'https://' + location.host + "/" + "export-graph/" + project + "/" + module_name + "/" + item + "/" + type + "/" + display + "/" + sampling + "/" + series_show
                            let shareableURL = url + "?sharedKey=" + sharedKey

                            copyText(shareableURL)
                                })
                            }
                            sharedKey()
                            }
                            },
                            ]
              }
            }
          },
        };
      } else if (type == "2d") {
        return {
          credits: {
            enabled: false,
          },
          chart: {
            //styledMode: true,
            zoomType: "none",
            plotBackgroundImage: dataset.backgroundimage,
            spacing:[15,15,15,15],
            events:{
              load: function() {

                    let unit = 'm'
                    let unit2 = '/j'
                    let title_legend = "Code Couleur Vitesse"
                    if (dataset.extra_legend){
                    unit = dataset.extra_legend_unit
                    unit2 = dataset.extra_legend_unit2
                    var chart = this
                    let left = chart.plotLeft //+ 20 + chart.plotWidth - 180
                    let bottom = chart.plotTop + chart.plotHeight - 20
                    let top = chart.plotTop
                    if (dataset.extra_legend == "yes"){
                    chart.renderer.label(title_legend, left, top).attr({ zIndex: 5 }).css({ color: '#000000', fontWeight: 600, fontSize: '1em' }).add()
                    chart.renderer.label('vitesse <= 0.1' + unit + unit2, left + 10, top + 14).attr({ zIndex: 5 }).css({ color: '#013ADF', fontWeight: 600, fontSize: '1em' }).add()
                    chart.renderer.label('vitesse <= 0.2' + unit + unit2,left + 10, top + 28).attr({ zIndex: 5 }).css({ color: '#19A0E8', fontWeight: 600, fontSize: '1em' }).add()
                    chart.renderer.label('vitesse <= 0.3' + unit + unit2, left + 10, top + 42).attr({ zIndex: 5 }).css({ color: '#58FAFA', fontWeight: 600, fontSize: '1em' }).add()
                    chart.renderer.label('vitesse <= 0.4' + unit + unit2, left + 10, top + 56).attr({ zIndex: 5 }).css({ color: '#088A08', fontWeight: 600, fontSize: '1em' }).add()
                    chart.renderer.label('vitesse <= 0.5' + unit + unit2, left + 10, top + 70).attr({ zIndex: 5 }).css({ color: '#9FF781', fontWeight: 600, fontSize: '1em' }).add()
                    chart.renderer.label('vitesse <= 0.6' + unit + unit2, left + 10, top + 84).attr({ zIndex: 5 }).css({ color: '#EEEE20', fontWeight: 600, fontSize: '1em' }).add()
                    chart.renderer.label('vitesse <= 0.7' + unit + unit2, left + 10, top + 98).attr({ zIndex: 5 }).css({ color: '#EEA320', fontWeight: 600, fontSize: '1em'}).add()
                    chart.renderer.label('vitesse <= 0.8' + unit + unit2, left + 10, top + 112).attr({ zIndex: 5 }).css({ color: '#EE3F20', fontWeight: 600, fontSize: '1em'}).add()
                    chart.renderer.label('vitesse <= 0.9' + unit + unit2, left + 10, top + 126).attr({ zIndex: 5 }).css({ color: '#FA58AC', fontWeight: 600, fontSize: '1em'}).add()
                    chart.renderer.label('vitesse <= 1.0' + unit + unit2, left + 10, top + 140).attr({ zIndex: 5 }).css({ color: '#B620EE',fontWeight: 600, fontSize: '1em'}).add()
                    chart.renderer.label('vitesse > 1.0' + unit + unit2, left + 10, top + 154).attr({ zIndex: 5 }) .css({ color: '#888888', fontWeight: 600, fontSize: '1em'}).add()
                    chart.renderer.rect(left, top, 174, 174, 1).attr({ 'stroke-width': 1, stroke: 'black', fill: 'white', zIndex: 4}).add();
                    }
                    var arrowLength = 10;
                    var arrowWidth = 5;
                    var startX = chart.plotLeft + chart.plotWidth/7 - arrowLength;
                    var startY = top + 210;
                    chart.renderer.path(['M', left, top + 210,'H', chart.plotLeft + chart.plotWidth/7,]) .attr({ 'stroke-width': 2, stroke: 'black', zIndex: 8 }).add();
                    chart.renderer.path(['M', startX, startY,'L',startX,startY - arrowWidth,startX + arrowLength,startY,startX,startY + arrowWidth,startX,startY]).attr({ 'stroke-width': 2, stroke: 'black', zIndex: 8 }).add();
                    chart.renderer.label(dataset.extra_legend_deparrow, left, top + 190).attr({ zIndex: 5}).css({ color: '#000000', fontWeight: 600, fontSize: '1em'}).add()
                    }
                    }
            }
          },
          title: {
            text: dataset.title,
          },
          xAxis: {
            //reversed: true,
            min: dataset.xaxismin,
            max: dataset.xaxismax,
            gridLineWidth: 1,
            //height: dataset.xAxisheight,
            //width: dataset.xAxiswidth,
            title: {
              enabled: true,
              text: dataset.xtitle,
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            plotLines: dataset.plotLines,
          },
          yAxis: {
            min: dataset.yaxismin,
            max: dataset.yaxismax,
            tickInterval: dataset.tickinterval,
            //height: dataset.yAxisheight,
            //width: dataset.yAxiswidth,
            title: {
              text: dataset.ytitle,
            },
          },
          legend: {
            enabled: dataset.showlegend,
            itemDistance: 8,
            symbolRadius: 4,
          },
          tooltip: {
             headerFormat: "<b>{series.name}</b><br> ",
            //  pointFormat: "déplacement total = {point.d:.2f}"+ dataset.extra_legend_unit +"<br> vitesse = {point.v:.2f}" + dataset.extra_legend_unit + "/j",
             pointFormat: dataset.tooltiptext,
             },
          plotOptions: {
            scatter: {
              turboThreshold: 10000,
              marker: {
                radius: 5,
                states: {
                  hover: {
                    enabled: false,
                    //lineColor: "rgb(100,100,100)",
                  },
                },
              },
              states: {
                hover: {
                  marker: {
                    enabled: false,
                  },
                },
              },
            },
            series: {
              dataLabels: {
                    enabled:true,
                    formatter: function() {
                    return this.point.series.name;
                    }
              },
              states: {
                    inactive: {opacity: 1},
                    hover:{enabled:false},
              },
              turboThreshold: 10000, //set it to a larger threshold, it is by default to 1000
              events: {
            legendItemClick: function (e) {
              let _this = e.target;
              let SeriesLinkedIindex = []
              if (_this.linkedSeries) {
                _this.linkedSeries.forEach((i) => {
                  SeriesLinkedIindex.push(i.index)
                })
              }
              let seriesIndex = _this.index;
              SeriesLinkedIindex.push(seriesIndex)
              let seriesName = _this.name;
              let series = _this.chart.series;
              let currentChart = _this.chart;

              if (!currentChart.seriesOnFocus) {
                currentChart.seriesOnFocus = [];
              }
              if (_this.visible) {
                if (!currentChart.seriesOnFocus.length) {
                  if (isCtrlClick) {
                    _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  } else {

                    series.forEach((i) => {

                      if (i.index !== seriesIndex && SeriesLinkedIindex.includes(i.index)==false) {
                        i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                      }

                    });

                    currentChart.seriesOnFocus.push(seriesIndex);

                  }

                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                } else if (currentChart.seriesOnFocus.length === 1) {
                  series.forEach((i) => {
                    if (i.index !== seriesIndex) {
                      i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                    }
                  });
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //var test = null;
                  //currentChart.extraChart.serieOnFocus = null;
                } else {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                  //var test = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                }
              } else {
                if (isCtrlClick) {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                } else {
                  _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.push(seriesIndex);
                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                }
              }
              currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
              return false; //<- IMPORTANT -> block the "next()" process
            },
              },
            },
          },
          series: dataset.data,
          exporting: {
            enabled: true,
            allowHTML: true,
            fallbackToExportServer: true,
            scale: 1,
            sourceWidth: 1024,
            sourceHeight: 800,
            width: 1024,
            //filename: this.project,
            chartOptions: {
              caption: {
                align: "center",
                text:
                  "<b><span> data are provided by </span>" +
                  "<b>https://evorisk.fr<b>", // + '<img src="/project/logo/geolithe_web.png">'
              },
              // chart: {
              //   events: {
              //     load: function () {
              //       var chart = this;
              //       chart.renderer
              //         .image("/project/logo/geolithe_web.png", 950, 740, 55, 55)
              //         .add()
              //         .toFront();
              //     },
              //   },
              // },
            },
            buttons: {
              contextButton: {
              enabled: true,
              menuItems: ['downloadPNG', 'downloadSVG']
              },
              customButton:{
              symbol: "download",
              menuItems: [{
                          titleKey: 'test',
                          text: 'URL (copied to clipboard)',
                          cursor: 'pointer',
                          onclick: function () {
                            if (this.seriesOnFocus && this.seriesOnFocus.length == 1){
                              series_show = this.series[this.seriesOnFocus[0]].name
                            } else {
                              series_show = false
                            }
                            let requestedGraph = project + '-' + module_name + "-" + item
                            function sharedKey() {
                              axios
                                .get(process.env.VUE_APP_API_GET_SHARED_KEY + '/' + requestedGraph)
                                .then((response) => {
                                  let sharedKey = response.data
                            var url = 'https://' + location.host + "/" + "export-graph/" + project + "/" + module_name + "/" + item + "/" + type + "/" + display + "/" + sampling + "/" + series_show
                            let shareableURL = url + "?sharedKey=" + sharedKey

                            copyText(shareableURL)
                                })
                            }
                            sharedKey()
                            }
                            },
                            ]
              }
            }
          },
        };
      } else if (type == "loglog") {
        return {
          chart: {
            marginLeft: 60, // Keep all charts left aligned
            spacingTop: 20,
            spacingBottom: 20,
            zoomType: "xy",
          },
          title: {
            text: dataset.name,
          },
          credits: {
            enabled: false,
          },
          xAxis: {
            minortickInterval: 0.1,
            gridLineWidth: 1,
            type: 'datetime',
            dateTimeLabelFormats: {
              day: "%d %b %y",
              month: "%b %y",
              year: "%y",
            },
            title: {
              enabled: true,
              text: dataset.xtitle,
            },
            crosshair: {
              width: 2,
              color: "gray",
              dashStyle: "shortdot",
            },
          },
          yAxis: {
            type: 'logarithmic',
            minorTickInterval: 0.1,
            curvature: true,
            accessibility: {
                rangeDescription: 'Range: 0.1 to 10000'
            },
            min: 0.0001,
            max: 100,
            plotLines: dataset.plotline,
            title: {
              text: dataset.ytitle,
            },
          },
          tooltip: {
             headerFormat: "<b>{series.name}</b><br> ",
             pointFormat: dataset.tooltiptext,
             },
          plotOptions: {
          scatter: {
              turboThreshold: 10000,
              marker: {
                radius: 5,
                lineColor:null,
                states: {
                  hover: {
                    enabled: false,
                    //lineColor: "rgb(100,100,100)",
                  },
                },
              },
              states: {
                hover: {
                  marker: {
                    enabled: false,
                  },
                },
              },
            },
            series: {
              dataLabels: {
                    enabled:false,
                    formatter: function() {
                    return this.point.series.name;
                    }
              },
              states: {
                    inactive: {opacity: 1},
                    hover:{enabled:false},
              },
              turboThreshold: 10000, //set it to a larger threshold, it is by default to 1000
              events: {
            legendItemClick: function (e) {
              let _this = e.target;
              let SeriesLinkedIindex = []
              if (_this.linkedSeries) {
                _this.linkedSeries.forEach((i) => {
                  SeriesLinkedIindex.push(i.index)
                })
              }
              let seriesIndex = _this.index;
              SeriesLinkedIindex.push(seriesIndex)
              let seriesName = _this.name;
              let series = _this.chart.series;
              let currentChart = _this.chart;

              if (!currentChart.seriesOnFocus) {
                currentChart.seriesOnFocus = [];
              }
              if (_this.visible) {
                if (!currentChart.seriesOnFocus.length) {
                  if (isCtrlClick) {
                    _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  } else {

                    series.forEach((i) => {

                      if (i.index !== seriesIndex && SeriesLinkedIindex.includes(i.index)==false) {
                        i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                      }

                    });

                    currentChart.seriesOnFocus.push(seriesIndex);

                  }

                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                } else if (currentChart.seriesOnFocus.length === 1) {
                  series.forEach((i) => {
                    if (i.index !== seriesIndex) {
                      i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                    }
                  });
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //var test = null;
                  //currentChart.extraChart.serieOnFocus = null;
                } else {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                  //var test = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                }
              } else {
                if (isCtrlClick) {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                } else {
                  _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.push(seriesIndex);
                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                }
              }
              currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
              return false; //<- IMPORTANT -> block the "next()" process
            },
              },
            },
          },
          legend: {
            enabled: dataset.showlegend,
            itemDistance: 8,
            symbolRadius: 4,
          },
          series: dataset.data,
          exporting: {
            enabled: true,
            allowHTML: true,
            fallbackToExportServer: true,
            scale: 1,
            sourceWidth: 1024,
            sourceHeight: 800,
            width: 1024,
            //filename: this.project,
            chartOptions: {
              caption: {
                align: "center",
                text:
                  "<b><span> data are provided by </span>" +
                  "<b>https://evorisk.fr<b>", // + '<img src="/project/logo/geolithe_web.png">'
              },
              // chart: {
              //   events: {
              //     load: function () {
              //       var chart = this;
              //       chart.renderer
              //         .image("/project/logo/geolithe_web.png", 950, 740, 55, 55)
              //         .add()
              //         .toFront();
              //     },
              //   },
              // },
            },
            buttons: {
              contextButton: {
              enabled: true,
              menuItems: ['downloadPNG', 'downloadSVG']
              },
              customButton:{
              symbol: "download",
              menuItems: [{
                          titleKey: 'test',
                          text: 'URL (copied to clipboard)',
                          cursor: 'pointer',
                          onclick: function () {
                            if (this.seriesOnFocus && this.seriesOnFocus.length == 1){
                              series_show = this.series[this.seriesOnFocus[0]].name
                            } else {
                              series_show = false
                            }
                            let requestedGraph = project + '-' + module_name + "-" + item
                            function sharedKey() {
                              axios
                                .get(process.env.VUE_APP_API_GET_SHARED_KEY + '/' + requestedGraph)
                                .then((response) => {
                                  let sharedKey = response.data
                            var url = 'https://' + location.host + "/" + "export-graph/" + project + "/" + module_name + "/" + item + "/" + type + "/" + display + "/" + sampling + "/" + series_show
                            let shareableURL = url + "?sharedKey=" + sharedKey

                            copyText(shareableURL)
                                })
                            }
                            sharedKey()
                            }
                            },
                            ]
              }
            }
          },
        };
      } else if (type == "heatmap") {
        return{
          chart:{
            type: "heatmap",
            zoomType: "xy",
          },
              boost: {
              useGPUTranslations: true
              },
          credits: {
            enabled: false,
          },
            title: {
            text: dataset.title,
          },
            tooltip: {
                formatter() {
                  let point = this.point;
                  let date = Highcharts.dateFormat('%e %b, %Y', point.x);
                  let yval = this.series.chart.yAxis[0].categories[point.y];
                  return `${date} ${yval}Hz: <b>${point.value}</b>`
            }
          },
          xAxis: {
             type: "datetime",
            dateTimeLabelFormats: {
              day: "%d %b %y",
              month: "%b %y",
              year: "%y",
              },
            //categories: dataset.xcategories,
            labels: {
            rotation: 90
            }
            },
        colorAxis: {
        stops: [
            [0, '#3060cf'],
            [0.1, '#66aee2'],
            [0.2, '#8ec5ed'],
            [0.3, '#4ee8b4'],
            [0.4, '#7ee567'],
            [0.5, '#fffbbc'],
            [0.6, '#f2c05e'],
            [0.7, '#ed9549'],
            [0.8, '#ed7031'],
            [0.9, '#ed4d21'],
            [1, '#c4463a']
        ],
        min: dataset.minscale,
        max: dataset.maxscale,
        startOnTick: true,
        endOnTick: true,
        labels: {
            format: '{value}'
        }
    },
            // colorAxis: {
            //     stops: [
            //         [0, '#3060cf'],
            //         [0.5, '#fffbbc'],
            //         [0.9, '#c4463a'],
            //         [1, '#c4463a']
            //     ],
            //     min: dataset.minscale,
            //     max: dataset.maxscale,
            // },
        yAxis: {
            title: {
                text: dataset.yAxisLabel
            },
          labels: {
            //align: 'left',
            enabled: true,
            style: {
            color: '#919FB2',
            },
            formatter: function() {
              if (!this.isLast && !this.isFirst) {
               return this.value
              }
            }
        },
            categories: dataset.ycategories,
            min:dataset.MinyAxis,
            max:dataset.MaxyAxis,
        },
            series: dataset.data,
                plotOptions: {
                series: {
                boostThreshold: 30000
                },
              scatter: {
              states:{
                inactive:{
                  opacity:1
                },
                hover:{enabled:false}
              }
            },
          },
        }
      } else if (type == "heatmap2") {
        return{
          chart:{
            type: "heatmap",
            zoomType: "xy",
          },
              boost: {
              useGPUTranslations: true
              },
          credits: {
            enabled: false,
          },
            title: {
            text: dataset.title,
          },
          //   tooltip: {
          //       formatter() {
          //         let point = this.point;
          //         let date = Highcharts.dateFormat('%e %b, %Y', point.x);
          //         let yval = this.series.chart.yAxis[0].categories[point.y];
          //         return `${date} ${yval}Hz: <b>${point.value}</b>`
          //   }
          // },
          xAxis: {
            categories: dataset.xcategories,
            labels: {
            rotation: 90
            }
            },
            colorAxis: {
                stops: [
                    [0, '#3060cf'],
                    [0.5, '#fffbbc'],
                    [0.9, '#c4463a'],
                    [1, '#c4463a']
                ],
                min: dataset.minscale,
                max: dataset.maxscale,
            },
        yAxis: {
            title: {
                text: dataset.yAxisLabel
            },
          labels: {
            //align: 'left',
            enabled: true,
            style: {
            color: '#919FB2',
            },
            formatter: function() {
              if (!this.isLast && !this.isFirst) {
               return this.value
              }
            }
        },
            categories: dataset.ycategories,
            min:dataset.MinyAxis,
            max:dataset.MaxyAxis,
        },
            series: dataset.data,
                plotOptions: {
                series: {
                boostThreshold: 30000
                },
              scatter: {
              states:{
                inactive:{
                  opacity:1
                },
                hover:{enabled:false}
              }
            },
        },
        }
      } else if (type == "non_time_series") {
        let isPolar= dataset.type=='polar'
        yAxisconf = {
          min: dataset.yaxismin,
          max: dataset.yaxismax,
          plotLines: dataset.plotline,
          plotBands: dataset.plotbandy,
          tickInterval: dataset.tickinterval,
          title: {
            margin: 10,
            text: dataset.yAxistitle,
            style: {
              fontSize: "16px",
              fontWeight: "bold",
            }
          },
          labels: {
          style: {
              fontSize: '12px',
              fontWeight: "bold",
          }
          }
        };
        if(isPolar){
          xAxisconf = {
            plotBands: dataset.plotbandx,
            min: 0,
            max: 360,
            tickInterval: 30,
            title: {
              margin: 10,
              text: dataset.xAxistitle,
              style: {
                fontSize: "0px",
                fontWeight: "bold",
              }
            },
            crosshair: {
              width: 2,
              color: "gray",
              dashStyle: "shortdot",
            },
            maxPadding: 0,
            labels: {
            style: {
                fontSize: '16px',
                fontWeight: "bold",
                }
            }
          }
        }else{
          xAxisconf = {
            plotBands: dataset.plotbandx,
            min: dataset.XminAll,
            max: dataset.XmaxAll,
            title: {
              margin: 10,
              text: dataset.xAxistitle,
              style: {
               fontSize: "16px",
                fontWeight: "bold",
              }
            },
            crosshair: {
              width: 2,
              color: "gray",
              dashStyle: "shortdot",
            },
            maxPadding: 0,
            labels: {
            style: {
                fontSize: '16px',
                fontWeight: "bold",
                }
            }
          }
        }
        return {
          chart: {
            polar : isPolar,
            marginLeft: 80, // Keep all charts left aligned
            spacingTop: 40,
            spacingBottom: 20,
            zoomType: "xy",
            resetXButton: null,
            resetYButton: null,
            resetAllButton: null,
            testX: false,
            apiCallUrl: apiCallUrl, // Added for downsampling POC
            token: token, // Added for downsampling POC (maybe not ideal, as it isn't the normal way of accessing the token ... )
          },
          title: {
            text: dataset.name,
          },
          credits: {
            enabled: false,
          },
          xAxis: xAxisconf,
          yAxis: yAxisconf,
          tooltip: {
            formatter() {
              let xTitle=this.series.xAxis.axisTitle.textStr
              let yTitle=this.series.yAxis.axisTitle.textStr
              let xVal=(Math.round(this.point.x * 100) / 100).toFixed(2)
              let yVal=(Math.round(this.point.y * 100) / 100).toFixed(2)
              return `<b>${this.series.name}<br> ${xTitle} : <b>${xVal}</b><br>${yTitle} : <b>${yVal}</b>`
            },
            shared: false,
            style: {
              fontSize: "0.65rem",
            },
            shadow: false,
            borderWidth: 0,
            backgroundColor: "rgba(255,255,255,0.0)",
            valueDecimals: 2,
          },
          plotOptions: {
            arearange: {
              turboThreshold: 10000,
              states:{
                inactive:{
                  opacity:0.3
                },
                hover:{enabled:false}
              }
            },
            scatter: {
              turboThreshold: 10000,
              marker: {
                enabled:true,
                radius: 5,
              },
            },
            line: {
              turboThreshold: 10000,
              lineWidth: 1.5,
              opacity: 1
            },
            column: {
              turboThreshold: 10000,
              pointWidth: 4,
              minPointLength: 3,
              borderColor: '#303030'
            },
            series: {
              turboThreshold: 10000,
              stickyTracking: false,
              marker: {
                enabled: true,
                radius: 1,
              },
              animation: {
                duration: 1000,
              },
              states: {
                inactive: {
                  opacity: 1,
                },
              },
              point: {
                events: {
                  mouseOver: function (event) {
                    sync.call(this, ref, event, "over");
                  },
                  mouseOut: function (event) {
                    sync.call(this, ref, event, "out");
                  },
                },
              },
              events: {
                legendItemClick: function (e) {
                  let _this = e.target;
                  let SeriesLinkedIindex = []
                  if (_this.linkedSeries) {
                    _this.linkedSeries.forEach((i) => {
                      SeriesLinkedIindex.push(i.index)
                    })
                  }
                  let seriesIndex = _this.index;
                  SeriesLinkedIindex.push(seriesIndex)
                  let seriesName = _this.name;
                  let series = _this.chart.series;
                  let currentChart = _this.chart;

                  if (!currentChart.seriesOnFocus) {
                    currentChart.seriesOnFocus = [];
                  }
                  if (_this.visible) {
                    if (!currentChart.seriesOnFocus.length) {
                      if (isCtrlClick) {
                        _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                      } else {

                        series.forEach((i) => {

                          if (i.index !== seriesIndex && SeriesLinkedIindex.includes(i.index)==false) {
                            i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                          }

                        });

                        currentChart.seriesOnFocus.push(seriesIndex);

                      }

                      //currentChart.extraChart.serieOnFocus = seriesIndex;
                      //var test = seriesIndex;
                    } else if (currentChart.seriesOnFocus.length === 1) {
                      series.forEach((i) => {
                        if (i.index !== seriesIndex) {
                          i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                        }
                      });
                      currentChart.seriesOnFocus.splice(
                        currentChart.seriesOnFocus.indexOf(seriesIndex),
                        1
                      );
                      //var test = null;
                      //currentChart.extraChart.serieOnFocus = null;
                    } else {
                      _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                      currentChart.seriesOnFocus.splice(
                        currentChart.seriesOnFocus.indexOf(seriesIndex),
                        1
                      );
                      //currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                      //var test = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                    }
                  } else {
                    if (isCtrlClick) {
                      _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                    } else {
                      _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                      currentChart.seriesOnFocus.push(seriesIndex);
                      //currentChart.extraChart.serieOnFocus = seriesIndex;
                      //var test = seriesIndex;
                    }
                  }
                  currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
                  return false; //<- IMPORTANT -> block the "next()" process
                },
              },
            },
          },
          legend: {
            enabled: dataset.showlegend,
            itemDistance: 8,
            symbolRadius: 4,
            title: dataset.legendtitle,
            maxHeight: 80,
          },
          series: dataset.data,
          exporting: {
            enabled: true,
            allowHTML: true,
            fallbackToExportServer: true,
            scale: 1,
            sourceWidth: 1024,
            sourceHeight: 800,
            width: 1024,
            //filename: this.project,
            chartOptions: {
              caption: {
                align: "center",
                text:
                  "<b><span> data are provided by </span>" +
                  "<b>https://evorisk.fr<b>", // + '<img src="/project/logo/geolithe_web.png">'
              },
              // chart: {
              //   events: {
              //     load: function () {
              //       var chart = this;
              //       chart.renderer
              //         .image("/project/logo/geolithe_web.png", 950, 740, 55, 55)
              //         .add()
              //         .toFront();
              //     },
              //   },
              // },
            },
            buttons: {
              contextButton: {
              enabled: true,
              menuItems: ['downloadPNG', 'downloadSVG']
              },
              customButton:{
              symbol: "download",
              menuItems: [{
                          titleKey: 'test',
                          text: 'URL (copied to clipboard)',
                          cursor: 'pointer',
                          onclick: function () {
                            if (this.seriesOnFocus && this.seriesOnFocus.length == 1){
                              series_show = this.series[this.seriesOnFocus[0]].name
                            } else {
                              series_show = false
                            }
                            let requestedGraph = project + '-' + module_name + "-" + item
                            function sharedKey() {
                              axios
                                .get(process.env.VUE_APP_API_GET_SHARED_KEY + '/' + requestedGraph)
                                .then((response) => {
                                  let sharedKey = response.data
                            var url = 'https://' + location.host + "/" + "export-graph/" + project + "/" + module_name + "/" + item + "/" + type + "/" + display + "/" + sampling + "/" + series_show
                            let shareableURL = url + "?sharedKey=" + sharedKey

                            copyText(shareableURL)
                                })
                            }
                            sharedKey()
                            }
                            },
                            ]
              }
            }
          },
        };
      }
    },
  },
};
</script>

<style>

.highcharts-container {
  border-radius: 5px;
  margin-right: 2px;
  border: 1px solid #eee;
}
.highcharts-container image {
	opacity: 0.5;
}
/* .highcharts-plot-background {
  opacity: 0.5;
} */
.chart_line_tier_col_full {
  width: 100%;
  height: calc(52vh - 2rem);
  min-height: 11rem;
  display: inline-block;
}

.chart_line_full_col_full {
  width: 100%;
  height: calc(90vh - 2rem);
  min-height: 11rem;
  display: inline-block;
}
.chart_squared {
  height: calc(90vh - 2rem) !important;
  width: 91vh !important;
  display: inline-block;
}

.chart_line_half_col_full {
  width: 100%;
  height: calc(50vh - 2rem);
  min-height: 11rem;
  display: inline-block;
}
.chart_line_full_col_half {
  width: 50%;
  height: calc(90vh - 2rem);
  min-height: 11rem;
  display: inline-block;
}
.chart_line_half_col_half {
  width: 50%;
  height: calc(50vh - 2rem);
  min-height: 11rem;
  display: inline-block;
}
.temp {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
}

.climb text {
  fill: gray;
  font-weight: normal;
  transform: rotate(-90deg);
}
tspan.marge {
  margin-left: 5em;
  padding-left: 10px;
}

.bandeau_col_full {
  width: 100%;
  height: 30px;
  background-color:#409dc2;
  font-family: "Roboto Bold";
  color: white;
  text-align: center;
  margin-top:5px;
  margin-bottom:5px;
  border-radius: 5px;
  border: solid 2px;
}

.bandeau_col_half {
  width: 50%;
  height: 30px;
  background-color:#409dc2;
  font-family: "Roboto Bold";
  color: white;
  text-align: center;
  margin-top:5px;
  margin-bottom:5px;
  border-radius: 5px;
  border: solid 2px;
}

.bandeau_squared {
  width: 90vh;
  height: 30px;
  background-color:#409dc2;
  font-family: "Roboto Bold";
  color: white;
  text-align: center;
  margin-top:5px;
  margin-bottom:5px;
  border-radius: 5px;
  border: solid 2px;
}


</style>
