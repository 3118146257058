<template>
  <div class="wrapper">
    <header v-if="!mode403" class="header">
      <Header :onClickCallback="goToHome" />
    </header>
    <header v-else class="header">
      <Header />
    </header>

    <main class="main">
      <div v-if="success" class="success">
        <svg
          class="success-svg"
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="black"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline
            stroke="rgb(33, 175, 33)"
            points="22 4 12 14.01 9 11.01"
          ></polyline>
        </svg>
        <p class="success-message">
        {{ $t("email_send_with_succes") }}
        </p>
      </div>
      <div v-else class="ask-element">
        <h1 class="main-title">{{ $t("AskProject.demande_acces") }}</h1>

        <form action="/" method="post" class="form">
          <div v-if="!userInDatabase" class="name-form">
            <label for="firstName">{{ $t("AskProject.first_name") }}:</label>
            <input
              v-model="userFirstName"
              class="form-input"
              ref="input-project"
              type="text"
              name="firstName"
              autocomplete="off"
              :placeholder='$t("AskProject.first_name")'
            />

            <label for="LastName">{{ $t("AskProject.last_name") }}:</label>
            <input
              v-model="userLastName"
              class="form-input"
              ref="input-project"
              type="text"
              name="lastName"
              autocomplete="off"
              :placeholder='$t("AskProject.last_name")'
            />

            <label for="PhoneNumber">{{ $t("AskProject.phone") }}:</label>
            <input
              v-model="userPhoneNumber"
              class="form-input"
              ref="input-project"
              type="text"
              name="phoneNumber"
              autocomplete="off"
              :placeholder='$t("AskProject.phone")'
            />

            <label for="companyName">{{ $t("AskProject.company") }}:</label>
            <input
              v-model="userCompanyName"
              class="form-input"
              ref="input-project"
              type="text"
              name="companyName"
              autocomplete="off"
              :placeholder='$t("AskProject.company")'
            />
          </div>

          <label for="projects">{{ $t("AskProject.project(s)") }}:</label>
          <input
            v-model="inputs.project"
            class="form-input"
            ref="input-project"
            type="text"
            name="project"
            autocomplete="off"
            :placeholder='$t("AskProject.project(s)")'
          />
          <p class="main-subtitle">
            {{ $t("AskProject.put_name_project(s)") }}
          </p>

          <button v-on:click="submit" class="submit" type="submit">
            <span>{{ $t("AskProject.send_request") }}</span>
            <span v-if="loading" class="spinner-w">
              <Spinner v-if="loading" />
            </span>
          </button>
          <div v-if="errored && errors && errors.length" class="error-zone">
            <p v-for="error in errors" :key="error">* {{ error }}</p>
          </div>
        </form>
      </div>
      <button v-if="!mode403" v-on:click="goToHome" class="back-btn">
        {{ $t("AskProject.landing_page") }}
      </button>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import { svg } from "../assets/svg";
export default {
  name: "AskProject",
  components: {
    Header,
    Spinner,
  },
  data: function () {
    return {
      success: false,
      loading: false,
      errored: false,
      errors: [],
      userInDatabase: false,
      userFirstName: "",
      userLastName: "",
      userPhoneNumber: "",
      userCompanyName: "",
      inputsList: ["project"],
      inputs: {
        project: "",
      },

      mode403: false,
    };
  },
  methods: {
    goToHome() {
      this.$router.push({ name: "home" });
      this.$router.go()
    },
    inputIsEmpty() {
      let isErrored = false;

      if (!this.userInDatabase) {
        if (!this.userFirstName.length) {
          this.errors.push("Merci de saisir un prénom");
        }
        if (!this.userLastName.length) {
          this.errors.push("Merci de saisir un nom");
        }
      }
      for (let i = 0; i < this.inputsList.length; i++) {
        if (!this.inputs[this.inputsList[i]].length) {
          this.$refs["input-" + this.inputsList[i]].classList.add("errored");
          this.errors.push(this.inputsList[i] + " required");
        }
      }
      if (this.errors.length) {
        this.errored = true;
        isErrored = true;
      }
      return isErrored;
    },
    clearInputsError() {
      for (let i = 0; i < this.inputsList.length; i++) {
        this.$refs["input-" + this.inputsList[i]].classList.remove("errored");
      }
    },
    submit: function (e) {
      e.preventDefault();
      this.clearInputsError();
      this.errors = [];
      this.errored = false;
      let inputIsEmpty = this.inputIsEmpty();
      if (!inputIsEmpty) {
        this.$el.style.pointerEvents = "none";
        this.loading = true;
        axios
          .post(
            process.env.VUE_APP_API_REQUEST_PROJECT_ACCESS,
            {
              project: this.inputs.project,
              user_email: this.$store.state.user.email,
              user_first_name: this.userFirstName,
              user_last_name: this.userLastName,
              user_phone_number: this.userPhoneNumber,
              user_company: this.userCompanyName,
            },
            {
              headers: { Authorization: `Bearer ${this.$store.state.token}` },
            }
          )
          .then(() => {
            //console.log("res: ", res.data);
            this.success = true; // à mettre sur OFF pour les tests
          })
          .catch((error) => {
            //console.error(error.response.data);
            let data = error.response.data;
            if (data.errors && data.errors.length) {
              data.errors.forEach((d) => {
                if (this.inputsList.includes(d.input)) {
                  this.$refs["input-" + d.input].classList.add("errored");
                  this.errors.push(d.msg);
                }
              });
            } else if (data.error) {
              this.errors.push(data.error);
              // CUSTOM PART
              for (let i = 0; i < this.inputsList.length; i++) {
                this.$refs["input-" + this.inputsList[i]].classList.add(
                  "errored"
                );
              }
            }
            if (this.errors.length) {
              this.errored = true;
            }
          })
          .finally(() => {
            this.$el.style.pointerEvents = "auto";
            this.loading = false;
          });
      }
    },
    getUserInfo() {
      axios
        .get(process.env.VUE_APP_API_GET_USER_INFO, {
          params: {
            email: this.$store.state.user.email,
          },
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        })
        .then((userInfo) => {
          if (userInfo.data["FIRST_NAME"] == "UNKNOWN") {
            this.userInDatabase = false;
          } else {
            this.userInDatabase = true;
            this.userFirstName = userInfo.data["FIRST_NAME"];
            this.userLastName = userInfo.data["LAST_NAME"];
          }
        });
    },
  },
  computed: {
    svg() {
      return svg;
    },
  },
  created: function () {
    if (this.$router.currentRoute.name === "403") {
      //console.log("run this vue in 403 mode");
      this.mode403 = true;
    }
  },
  mounted: function () {
    this.getUserInfo();
  },
};
</script>

<style scoped>
/*--------------------------------------------- WRAPPER */
.wrapper {
  /* - regular version - */
  display: block;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  word-break: break-word;
}
.header {
  display: block;
  width: 100%;
  /*height: 4rem;*/
  height: 3rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.main {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* -------------------------------------------- header */
/* .header-content -> BECOME -> Header component */
/* -------------------------------------------- main */
/* -------------------------------------------- success */
.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90vw;
}
.success-svg {
  width: 4rem;
  height: 4rem;
}
.success-message {
  margin-top: 1rem;
}
/* -------------------------------------------- ask-element */
.ask-element {
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 19rem;
  max-width: 90vw;
  padding: 1rem;
}
/* main-title */
.main-title {
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  text-align: center;
  color: white;
}
/* main-subtitle */
.main-subtitle {
  width: 100%;
  margin-top: 0.8rem;
  font-size: 0.81rem;
  color: white;
}
/* form */
.form {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 1rem;
}
.form-input {
  display: block;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0.8rem;
  color: rgba(0, 0, 0, 1);
  font-family: "Roboto Bold";
  border: solid 0.1rem rgba(255, 0, 0, 0); /* set here too... to prevent glitch from .errored */
  text-align: center;
}
.form-input:focus {
  background: rgba(255, 255, 255, 1);
}
.submit {
  width: 100%;
  background: rgb(33, 175, 33);
  margin-top: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  box-shadow: -0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.4);
}
.submit:hover {
  box-shadow: -0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.2);
}
.errored {
  border: solid 0.1rem rgba(255, 0, 0, 1);
  color: red;
}
.error-zone {
  display: block;
  position: relative;
  padding-top: 0.4rem;
  color: red;
}
.spinner-w {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  margin-left: 0.4rem;
}
/* -------------------------------------------- back-btn */
.back-btn {
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  width: 19rem;
  font-family: "Roboto Bold";
  max-width: 90vw;
  height: 2rem;
  position: relative;
  margin-top: 0.8rem;
  justify-content: center;
  align-items: center;
  color: white;
}
/*
.back-btn:hover {
  box-shadow: -0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.4);
}
*/
/* -------------------------------------------- PORTRAIT */
@media screen and (orientation: portrait) {
}
</style>