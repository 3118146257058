import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import vSelect from "vue-select";
import VueClipboards from 'vue-clipboards';
import store from '@/store/store.js'
import router from './router'
import { i18n } from './i18n-setup'

Vue.config.productionTip = false
// Vue.config.performance = true
// Vue.config.devtools = true

import App from './App.vue'

// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";
// Import the plugin here
import { Auth0Plugin } from "./auth";

// Bus global events
export const eventBus = new Vue();


// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }

});


Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueClipboards);
Vue.component("v-select", vSelect);

Vue.config.productionTip = false;
const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
});
app.$mount("#app");