<template>
  <div id="resize-bar">
    <div class="btn-group">
      <a
        class="btn btn-argos splitColumns-2"
        :class="defaultSize.nbColumns == 2 ? 'selected' : ''"
        v-on:click="splitColumns(2)"
        ><b-icon-grid></b-icon-grid
      ></a>
      <a
        class="btn btn-argos"
        :class="defaultSize.nbColumns == 1 ? 'selected' : ''"
        v-on:click="splitColumns(1)"
        ><b-icon-list></b-icon-list
      ></a>
    </div>

    <div class="btn-group">
      <a
        class="btn btn-argos sm"
        :class="defaultSize.verticalSize == 'sm' ? 'selected' : ''"
        v-on:click="setVerticalSize('sm')"
        ><b-icon-fullscreen></b-icon-fullscreen
      ></a>
      <a
        class="btn btn-argos md"
        :class="defaultSize.verticalSize == 'md' ? 'selected' : ''"
        v-on:click="setVerticalSize('md')"
        ><b-icon-fullscreen></b-icon-fullscreen
      ></a>
      <a
        class="btn btn-argos xl"
        :class="defaultSize.verticalSize == 'xl' ? 'selected' : ''"
        v-on:click="setVerticalSize('xl')"
        ><b-icon-fullscreen></b-icon-fullscreen
      ></a>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../main";
import JQuery from "jquery";
let $ = JQuery;

export default {
  components: {},
  props: [],
  data() {
    return {
      lastDataFetched: "",
      defaultSize: {
        nbColumns: 1, // Default value
        verticalSize: "xl", // Default value
        styleHeight: "",
        styleWidth: "",
      },
    };
  },
  mounted() {},
  methods: {
    splitColumns(nbCols) {
      this.defaultSize.nbColumns = nbCols;
      let widthPercent = 100; // Default (1 column)
      if (nbCols == 2) {
        widthPercent = 50;
      }

      $(".project-content").css("flex-flow", "row wrap");

      let styleWidth = widthPercent + "%";
      this.defaultSize.styleWidth = styleWidth;

      $(".project-content-chart-wrapper").each(function (index) {
        if ($(this).find(".highcharts-container").length > 1) {
          // Many graphs
          $(this)
            .find(".highcharts-container ")
            .parent()
            .css("width", styleWidth);
        } else {
          // Single graph
          $(this)
            .css("float", "left")
            .css("flex-grow", "0")
            .css("width", styleWidth);
        }
        if ($(this).find(".img_included")) {
          $(this)
            .find(".img_included ")
            .css("width",styleWidth)
            $(this)
            .css("flex-grow", "1")
            .css("width", styleWidth)
        } 
      });

      //this.$store.state.chartDefaultSize = this.defaultSize;

      this.sendResizeEvent();
    },
    setVerticalSize(vSize) {
      this.defaultSize.verticalSize = vSize;
      let $component = $("#resize-bar");

      let componentSize = parseInt(
        $component.offset().top + $component.innerHeight()
      );

      let heightPercent = 50; // Default (md)
      if (vSize == "xl") {
        heightPercent = 100;
      } else if (vSize == "sm") {
        heightPercent = 40;
      }

      let styleHeight =
        "calc(" +
        heightPercent +
        "vh - " +
        (heightPercent * componentSize) / 100 +
        "px)";
      this.defaultSize.styleHeight = styleHeight;
      $(".highcharts-container").each(function (index) {
        $(this).parent().css("height", styleHeight);
      });

      //this.$store.state.chartDefaultSize = this.defaultSize;

      this.sendResizeEvent();
    },
    sendResizeEvent() {
      // Trigger resize event
      var evt = window.document.createEvent("UIEvents");
      evt.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(evt);
    },
    refreshComponent() {
      this.splitColumns(this.defaultSize.nbColumns);
      this.setVerticalSize(this.defaultSize.verticalSize);
    },
  },
  created() {
    eventBus.$on("HC1Loaded", (event) => {
      if (this.lastDataFetched !== event) {
        this.lastDataFetched = event;
        let self = this;
        setTimeout(function () {
          self.refreshComponent();
        }, 10);
      }
    });
  },
};
</script>

<style>
#resize-bar {
  width: 100%;
}
.btn-group {
  float: right;
  margin: 0px 5px 15px 10px;
}
.btn-group .btn {
  height: 35px;
  width: 45px;
}
.btn.sm .b-icon {
  width: 60%;
}
.btn.md .b-icon {
  width: 85%;
}
.btn.xl .b-icon {
  width: 110%;
}
.btn-argos {
  color: #fff !important;
  background-color: #409dc2 !important;
  border-color: #409dc2 !important;
}
.btn-argos:hover,
.btn-argos.selected {
  color: #fff;
  background-color: #2f81a1 !important;
  border-color: #2f81a1 !important;
}
</style>
