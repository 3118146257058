const mutations = {

    // user
    UPDATE_USER (state, user) {
      state.user = user
    },
    UPDATE_TOKEN (state, token) {
      state.token = token
    },
    // projectsScopes
    UPDATE_PROJECTS_SCOPES (state, projectsScope) {
      state.projectsScope = projectsScope
    }
    
  }
  
  export default mutations
  
  