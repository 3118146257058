
import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate' // ROYBON / Permet de préserver le store en cas de refresh de la page


import state from './state'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)


export default new Vuex.Store({
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== 'production',
  
  // ROYBON / Permet de préserver le store en cas de refresh de la page
  // plugins: [
  //   createPersistedState({
  //     // storage: window.sessionStorage
  //   })
  // ] 
})
