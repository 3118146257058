import axios from 'axios';
import * as Bear from './vendors/bearcubs/es6/bearcubs'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import default_locale_json from '@/locales/en.json'
const default_locale = 'en';
const localesAvailable = ['fr', 'en'];
const default_messages = {};
default_messages[default_locale] = default_locale_json;
const loadedLanguages = [default_locale];

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: default_locale,
    fallbackLocale: default_locale,
    messages: default_messages
});

// method(s)
const setI18nLanguage = (lang) => {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
};
const loadLanguageAsync = (lang) => {
    if (i18n.locale === lang) {
        return Promise.resolve(lang);
    } else if (localesAvailable.includes(lang)) {
        if (loadedLanguages.includes(lang)) {
            // la langue est déjà chargée, alors on la set
            return Promise.resolve(setI18nLanguage(lang))
        } else {
            // la langue n'est pas chargée, alors on la charge et on la set
            return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`).then(
                messages => {
                    i18n.setLocaleMessage(lang, messages.default)
                    loadedLanguages.push(lang)
                    return setI18nLanguage(lang)
                }
            )
        }
    } else {
        return Promise.resolve(lang + " lang not available");
    }
};

// locale (lang) detection on start
    // step 1
let current_locale = Bear.getQuery('lang') || Bear.getQuery('locale'); //<- query detection
if (!current_locale || !localesAvailable.includes(current_locale)) {
    current_locale = navigator.language.split("-")[0]; //<- navigator detection
    if (!localesAvailable.includes(current_locale)) {
        current_locale = default_locale;
    }
}
// step 2
if (current_locale !== default_locale) {

    loadLanguageAsync(current_locale);
}

// export
export { i18n, loadLanguageAsync };