const svg = {};

svg.cloudDown = `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="rgba(255, 255, 255, 1.0)" stroke-width="3" stroke-linecap="square" stroke-linejoin="arcs"><path d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8M12 19.8V12M16 17l-4 4-4-4"/></svg>`;

svg.info = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>`;

svg.welcomeGraph = `
<svg width="1000" height="500" version="1.1" viewBox="0 0 2000 1000" xmlns="http://www.w3.org/2000/svg">

<defs>
<linearGradient id="rainbow" x1="0" x2="100%" y1="0" y2="100%" gradientUnits="userSpaceOnUse" >
  <stop stop-color="#8012ed" offset="0%"/>
  <stop stop-color="#ca01b3" offset="10%"/>
  <stop stop-color="#f82165" offset="20%"/>
  <stop stop-color="#f86521" offset="30%"/>
  <stop stop-color="#cab301" offset="40%"/>
  <stop stop-color="#80ed12" offset="50%"/>
  <stop stop-color="#35fe4c" offset="60%"/>
  <stop stop-color="#07de9a" offset="70%"/>
  <stop stop-color="#079ade" offset="80%"/>
  <stop stop-color="#354cfe" offset="90%"/>
  <stop stop-color="#354cff" offset="90%"/> 
</linearGradient>
</defs>

    <path d="m100.12 530.35 184.17-5.3558 195.28-355.36 114.43 680.17 578.04-621.87 457.74 188.78 275.82-130.48" fill="none" stroke="url(#rainbow)" stroke-width="13.844"/>

</svg>
`;

svg.rimnat = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="19584px" height="5502px" version="1.0" viewBox="0 0 12000 3370" xmlns="http://www.w3.org/2000/svg">
 <g fill="#626262">
  <path d="m211 2105 4-1376 661.41 481.89s-73.076 79.739-97.409 239.11c-32 209 88 437 281 534 91.875 43.929 133.54 51.072 239.46 53.643l-0.4643 1052.4z" fill="#626262"/>
 </g>
 <g fill="#989898">
  <path d="m549 974c-178-130-334-245-334-245l1084-610 740.3 415.85s-68.268 80.212-54.303 264.15c-129.07 95.161-392.79 286.03-392.79 286.03-91.732-60.556-209.41-87.776-292.21-89.03-187.34 20.448-314.87 90.88-423.59 214.89z" fill="#989898"/>
 </g>
 <g fill="#cdcdcd">
  <path d="m1300 2568-0.5357-530.36c222.04-4.7289 351.54-126.64 351.54-126.64l343.55 186.27s-19.391 167.97 100.12 272.95c0 0-563.94 504.6-795.67 719.77z"/>
  <path d="m1988 1689-168-91v-81c0-45-4-96-9-114l-9-31c134.17-97.923 268.78-195.24 403.18-292.84 0 0 41.11 16.521 71.11 26.521 42.211 11.023 62.481 14.318 103.71 14.318v586l-59 12c-49 9-85.357 31.964-151.36 64.964-3 2-89.643-44.964-181.64-93.964z"/>
 </g>
 <g fill="#FFFFFF">
  <g>
   <path d="m6090 2383c-33-12-56-49-257-399l-208-363-3 374-2 375h-115-115v-602c0-585 1-604 20-636 33-53 114-70 162-33 13 11 143 227 289 480 145 253 267 461 270 461s100-163 215-362c314-548 329-573 359-586 36-17 82-15 114 4 58 34 56 8 59 667l3 607h-121-120l-2-371-3-371-209 366c-218 384-228 397-293 395-15 0-35-3-43-6z"/>
   <path d="m9285 2372c-17-10-45-36-64-58-19-21-134-142-256-269-122-126-293-305-381-397l-159-167-3 444-2 445h-115-115v-610-610l28-30c31-35 87-49 129-31 16 6 219 211 453 455l425 444 3-444 2-444h115 115v610 610l-28 31c-36 40-102 50-147 21z"/>
   <path d="m3380 1769v-601l68-68h492c349 0 504 3 532 12 50 15 122 78 151 132 20 38 22 54 22 221 0 166-2 185-24 238-49 123-194 230-332 245l-64 7 225 194c124 107 232 200 240 207 12 12-13 14-165 14h-180l-235-205-235-204-87-1h-88v-120-120h279c288 0 319-4 374-44 50-37 67-89 67-212 0-72-4-114-12-122-9-9-113-12-405-12h-393v520 520h-115-115v-601z"/>
   <path d="m4890 1735v-635h115 115v635 635h-115-115v-635z"/>
   <path d="m9840 2348c-75-29-151-106-175-177-24-73-18-199 13-262 30-62 86-117 152-148 54-25 61-26 303-29l247-4v116 116h-230-231l-24 25c-19 18-25 35-25 67 0 24 6 50 13 59 27 36 52 39 297 39h242l29-30 30-30-3-205c-3-197-4-205-26-227-22-23-26-23-302-26l-280-3v-110-110l298 3c296 3 297 3 352 29 65 30 122 86 153 149 20 42 22 61 25 270 3 184 1 236-13 286-21 79-75 148-145 188l-55 31-295 2c-280 2-298 1-350-19z"/>
   <path d="m11140 2005v-365h-155-155v-115-115h155 155v-155-155h115 115v155 155h200 200v115 115h-200-200v365 365h-115-115v-365z"/>
   <path d="m7110 1735v-105h420 420v105 105h-420-420v-105z"/>
  </g>
  <ellipse cx="1298.3" cy="1517.7" rx="405.96" ry="406.1" stroke-width=".67672"/>
  <ellipse cx="2387.1" cy="729.22" rx="277.5" ry="273.1" stroke-width=".59639"/>
  <ellipse cx="2383.1" cy="2104.5" rx="277.5" ry="273.1" stroke-width=".59639"/>
  <rect transform="rotate(-36.223)" x="446.81" y="1936.1" width="1103.5" height="120.19" ry="0" stroke-width=".61275"/>
  <rect transform="rotate(28.374)" x="1923.5" y="658.36" width="1103.5" height="120.19" ry="0" stroke-width=".61275"/>
 </g>
</svg>`;

svg.rimnat_icon = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   version="1.0"
   width="5200"
   height="5200"
   viewBox="0 0 3186.2745 3185.0236"
   preserveAspectRatio="xMidYMid meet"
   id="svg94"
   sodipodi:docname="icon_green.svg"
   inkscape:version="0.92.4 (5da689c313, 2019-01-14)">
  <metadata
     id="metadata100">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <defs
     id="defs98" />
  <sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="1258"
     inkscape:window-height="758"
     id="namedview96"
     showgrid="false"
     inkscape:zoom="0.056568542"
     inkscape:cx="3386.0663"
     inkscape:cy="1732.2689"
     inkscape:window-x="380"
     inkscape:window-y="86"
     inkscape:window-maximized="0"
     inkscape:current-layer="layer6"
     inkscape:pagecheckerboard="true" />
  <g
     inkscape:groupmode="layer"
     id="layer9"
     inkscape:label="GRIS SOMBRE"
     transform="translate(0,-185.04902)">
    <g
       style="fill:#626262;fill-opacity:1;stroke:none"
       id="layer102"
       transform="translate(254.54978,184.14239)">
      <path
         d="m 211,2105 4,-1376 661.40926,481.894 c 0,0 -73.0755,79.7392 -97.40926,239.106 -32,209 88,437 281,534 91.875,43.9286 133.5357,51.0715 239.4643,53.6429 L 1299,3090 Z"
         id="path77"
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="cccccccc"
         style="fill:#626262;fill-opacity:1" />
    </g>
  </g>
  <g
     inkscape:groupmode="layer"
     id="layer8"
     inkscape:label="GRIS MOYEN"
     transform="translate(0,-185.04902)">
    <g
       style="fill:#989898;fill-opacity:1;stroke:none"
       id="layer103"
       transform="translate(254.54978,184.14239)">
      <path
         d="M 549,974 C 371,844 215,729 215,729 l 1084,-610 740.3033,415.84771 c 0,0 -68.2677,80.21194 -54.3033,264.15229 -129.0652,95.16126 -392.7881,286.0305 -392.7881,286.0305 C 1500.4801,1024.4746 1382.799,997.2546 1300,996 c -187.3415,20.4483 -314.86894,90.8804 -423.59074,214.894 z"
         id="path86"
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="ccccccccc"
         style="fill:#989898;fill-opacity:1" />
    </g>
  </g>
  <g
     inkscape:groupmode="layer"
     id="layer7"
     inkscape:label="GRIS CLAIR"
     transform="translate(0,-185.04902)">
    <g
       style="fill:#cdcdcd;fill-opacity:1;stroke:none"
       id="layer104"
       transform="translate(254.54978,184.14239)">
      <path
         d="m 1300,2568 -0.5357,-530.3571 C 1521.5068,2032.914 1651,1911 1651,1911 l 343.5457,186.2716 c 0,0 -19.3908,167.9704 100.1218,272.9549 0,0 -563.9374,504.5956 -795.6675,719.7735 z"
         id="path89"
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="ccccccc"
         style="fill:#cdcdcd;fill-opacity:1" />
      <path
         d="m 1988,1689 -168,-91 v -81 c 0,-45 -4,-96 -9,-114 l -9,-31 c 134.168,-97.9228 268.776,-195.2394 403.1786,-292.8393 0,0 41.1101,16.5214 71.1101,26.5214 C 2318.4994,1116.7052 2338.7697,1120 2380,1120 v 293 293 l -59,12 c -49,9 -85.3571,31.9643 -151.3571,64.9643 -3,2 -89.6429,-44.9643 -181.6429,-93.9643 z"
         id="path91"
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="ccscccccccccc"
         style="fill:#cdcdcd;fill-opacity:1" />
    </g>
  </g>
  <g
     inkscape:groupmode="layer"
     id="layer6"
     inkscape:label="VERT"
     transform="translate(0,-185.04902)">
    <g
       id="g4759"
       style="fill:#000000;fill-opacity:1"
       transform="translate(254.54978,184.14239)">
      <g
         style="fill:#8bb517;fill-opacity:1"
         id="g4743">
        <ellipse
           id="path3887"
           style="fill:#8bb517;fill-opacity:1;stroke-width:0.67671824"
           cx="1298.2762"
           cy="1517.653"
           rx="405.96225"
           ry="406.09964" />
        <ellipse
           id="path3891"
           cx="2387.1431"
           cy="729.22272"
           rx="277.5"
           ry="273.09543"
           style="fill:#8bb517;fill-opacity:1;stroke-width:0.59638882" />
        <ellipse
           id="path3891-4"
           cx="2383.1172"
           cy="2104.5312"
           rx="277.5"
           ry="273.09543"
           style="fill:#8bb517;fill-opacity:1;stroke-width:0.59638882" />
        <rect
           id="rect3911"
           width="1103.4525"
           height="120.19313"
           x="446.81427"
           y="1936.0618"
           style="fill:#8bb517;fill-opacity:1;stroke-width:0.61274511"
           transform="rotate(-36.22294)"
           ry="0" />
        <rect
           id="rect3911-0"
           width="1103.4525"
           height="120.19312"
           x="1923.5115"
           y="658.35638"
           style="fill:#8bb517;fill-opacity:1;stroke-width:0.61274511"
           transform="rotate(28.373719)"
           ry="0" />
      </g>
    </g>
  </g>
</svg>`;

svg.copyURL = `
<?xml version="1.0" encoding="UTF-8"?>
<svg class="svg-icon" viewBox="0 0 20 20">
<path d="M16.469,8.924l-2.414,2.413c-0.156,0.156-0.408,0.156-0.564,0c-0.156-0.155-0.156-0.408,0-0.563l2.414-2.414c1.175-1.175,1.175-3.087,0-4.262c-0.57-0.569-1.326-0.883-2.132-0.883s-1.562,0.313-2.132,0.883L9.227,6.511c-1.175,1.175-1.175,3.087,0,4.263c0.288,0.288,0.624,0.511,0.997,0.662c0.204,0.083,0.303,0.315,0.22,0.52c-0.171,0.422-0.643,0.17-0.52,0.22c-0.473-0.191-0.898-0.474-1.262-0.838c-1.487-1.485-1.487-3.904,0-5.391l2.414-2.413c0.72-0.72,1.678-1.117,2.696-1.117s1.976,0.396,2.696,1.117C17.955,5.02,17.955,7.438,16.469,8.924 M10.076,7.825c-0.205-0.083-0.437,0.016-0.52,0.22c-0.083,0.205,0.016,0.437,0.22,0.52c0.374,0.151,0.709,0.374,0.997,0.662c1.176,1.176,1.176,3.088,0,4.263l-2.414,2.413c-0.569,0.569-1.326,0.883-2.131,0.883s-1.562-0.313-2.132-0.883c-1.175-1.175-1.175-3.087,0-4.262L6.51,9.227c0.156-0.155,0.156-0.408,0-0.564c-0.156-0.156-0.408-0.156-0.564,0l-2.414,2.414c-1.487,1.485-1.487,3.904,0,5.391c0.72,0.72,1.678,1.116,2.696,1.116s1.976-0.396,2.696-1.116l2.414-2.413c1.487-1.486,1.487-3.905,0-5.392C10.974,8.298,10.55,8.017,10.076,7.825"></path>
</svg>`


export { svg };