import { getInstance } from "../auth/index";

const actions = {

    // user
    updateUser ({ commit }, user) {
      commit('UPDATE_USER', user)
    },

    // Get token from Auth0 instance
    retrieveTokenFromAuth0(context) {
      
      return new Promise((resolve, reject) => {
        const instance = getInstance();

        instance.$watch("loading", loading => {
          if (loading === false && instance.isAuthenticated) {
            instance
              .getTokenSilently()
              .then(authToken => {
                context.commit("UPDATE_TOKEN", authToken);
                resolve(authToken);
              })
              .catch(error => {
                reject(error);
              });
          }
        });
      });
    },

    // updateToken( {commit}, token) {
    //   commit('UPDATE_TOKEN', token)
    // },
    // projectsScopes
    updateProjectsScope ({ commit }, projectsScope) {
      commit('UPDATE_PROJECTS_SCOPES', projectsScope)
    }
  }
  
  export default actions
  