import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store.js'
import Home from './views/Home.vue'
import AskProject from './views/AskProject.vue'
import SoilStab from './views/SoilStab.vue'
import AnalyseVibration from './views/AnalyseVibration.vue'
import Admin from './views/Admin.vue'
import { authGuard } from "./auth/authGuard"
import { sortBy } from './vendors/utils'

// view(s)
// const AskProject = () => import(/* webpackChunkName: "AskProject" */ './views/AskProject.vue') // <- lazy loading version

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '*',
            name: "notFound",
            redirect: '/'
        },
        {
            path: '/403',
            name: "403",
            component: AskProject
        },
        {
            path: '/',
            name: "home",
            component: Home,
            beforeEnter: authGuard
        },
        {
            path: '/ask-project',
            name: "askproject",
            component: AskProject,
            beforeEnter: authGuard
        },
        {
            path: '/soilstab',
            name: "soilstab",
            component: SoilStab,
            beforeEnter: authGuard
        },

        // Path that is given as url, which loads the Home component, which then redirects
        // to /add-user
        {
            path: '/add-user-projects',
            name: "add-user-projects",
            component: Home,
            beforeEnter: authGuard
        },

        // Path that is push to by the Home component
        {
            path: '/add-user',
            name: "add-user",
            component: Admin,
            beforeEnter: authGuard
        },
        {
            path: '/:project/analyse-vibration/:item',
            name: "analyseVibration",
            component: AnalyseVibration,
            beforeEnter: authGuard
        },
        {
            path: '/admin',
            name: "admin",
        },
        {
            path: '/export-graph/:project/:module/:item/:type/:chart_display/:sampling/:series_show',
            name: "export-graph",
            component: Home,
        },
        {
            path: '/:project',
            name: "home-project",
            component: Home,
            beforeEnter: authGuard
        }
    ]
});


export default router