<template>
<div>
                  <div class="bandeau_col_full">
          <p> {{ module.modulename }} - {{ module.itemname }} </p>
        </div>
        <highcharts
          :key="optionsList.title.text"
          :options="optionsList"
          ref="highcharts"
          class="chart_line_full_col_full"
        ></highcharts></div>
</template>

<script>

import Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import Highcharts3d from "highcharts/highcharts-3d";
import exportingInit from "highcharts/modules/exporting";
import vectorInit from 'highcharts/modules/vector'
import HeatMapInit from 'highcharts/modules/heatmap'
import offlineExporting from "highcharts/modules/offline-exporting";
import stockInit from "highcharts/modules/stock";
import Annotation from "../../node_modules/highcharts/modules/annotations.js";
import customEvent from "highcharts-custom-events";
import { Chart } from "highcharts-vue";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import { eventBus } from "../main";
import JQuery from "jquery";
import boost from "highcharts/modules/boost";
import _ from "lodash";
import { svg } from "../assets/svg";
let $ = JQuery;

HeatMapInit(Highcharts)
vectorInit(Highcharts);
Highcharts3d(Highcharts);
exportingInit(Highcharts);
offlineExporting(Highcharts);
more(Highcharts);
stockInit(Highcharts);
Annotation(Highcharts);
customEvent(Highcharts);
boost(Highcharts);


    (function (H) {
        var defaultPlotOptions = H.getOptions().plotOptions,
            columnType = H.seriesTypes.column,
            each = H.each;

        defaultPlotOptions.xrange = H.merge(defaultPlotOptions.column, {});
        H.seriesTypes.xrange = H.extendClass(columnType, {
            type: 'xrange',
            parallelArrays: ['x', 'x2', 'y'],
            animate: H.seriesTypes.line.prototype.animate,

            /**
             * Borrow the column series metrics, but with swapped axes. This gives free access
             * to features like groupPadding, grouping, pointWidth etc.
             */
            getColumnMetrics: function () {
                var metrics,
                    chart = this.chart,
                    swapAxes = function () {
                        each(chart.series, function (s) {
                            var xAxis = s.xAxis;
                            s.xAxis = s.yAxis;
                            s.yAxis = xAxis;
                        });
                    };

                swapAxes();

                this.yAxis.closestPointRange = 1;
                metrics = columnType.prototype.getColumnMetrics.call(this);

                swapAxes();

                return metrics;
            },
            translate: function () {
                columnType.prototype.translate.apply(this, arguments);
                var series = this,
                    xAxis = series.xAxis,
                    metrics = series.columnMetrics;

                H.each(series.points, function (point) {
                    var barWidth = xAxis.translate(H.pick(point.x2, point.x + (point.len || 0))) - point.plotX;
                    point.shapeArgs = {
                        x: point.plotX,
                        y: point.plotY + metrics.offset,
                        width: barWidth,
                        height: metrics.width
                    };
                    point.tooltipPos[0] += barWidth / 2;
                    point.tooltipPos[1] -= metrics.width / 2;
                });
            }
        });

        /**
         * Max x2 should be considered in xAxis extremes
         */
        H.wrap(H.Axis.prototype, 'getSeriesExtremes', function (proceed) {
            var axis = this,
                dataMax = Number.MIN_VALUE;

            proceed.call(this);
            if (this.isXAxis) {
                each(this.series, function (series) {
                    each(series.x2Data || [], function (val) {
                        if (val > dataMax) {
                            dataMax = val;
                        }
                    });
                });
                if (dataMax > Number.MIN_VALUE) {
                    axis.dataMax = dataMax;
                }
            }
        });
    }(Highcharts));


export default {
      components: {
    highcharts: Chart
  },
  props: ["module","project"],
  data() {
    return {
        optionsList: [],
        result: null
    };
  },
  mounted() {
  },
      created() {
        this.getLog();
    },
  methods: {
      getLog(){
        axios.get(process.env.VUE_APP_API_GET_TIMEDATA, {
            params:{project: this.$parent.projectSelected,capteurs: this.module.item},
                            headers: {Authorization: `Bearer ${this.$store.state.token}`},
        })
        .then((Log) => {
            this.result = Log.data
            this.result.Data = this.result.Data.map((val, j) => {
                let correctedDate0 = new Date(val["x"]);
                let correctedDate_0 = correctedDate0.getTime();
                let correctedDate1 = new Date(val["x2"]);
                let correctedDate_1 = correctedDate1.getTime();                
                return {
                    x: correctedDate_0,
                    x2: correctedDate_1,
                    y: val["y"],
                    color: val["color"]};
            })
          this.optionsList = this.genOptions(this.result)
        })  
      },  
genOptions(dataset) {
    return {
       chart: {
            type: 'xrange'
        },
        credits: {
            enabled: false,
          },
        title: {
            text: 'Disponibilité des données'
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: '',
            categories: dataset.xCategories,
        },
        legend: {
            enabled: false,
          },
        tooltip: {
            followPointer:true,
            formatter: function() {
                console.log(this)
                let start = Highcharts.dateFormat('%Y-%m-%d %Hh%M', new Date(this.point.x)),
                    end = Highcharts.dateFormat('%Y-%m-%d %Hh%M', new Date(this.point.x2)),
                    cat = dataset.xCategories[this.y];
                    console.log(end)
                return `<b>${cat}</b> <br> Dernière donnée: ${end}`
                }
        },

        series: [{
            borderRadius: 5,
            pointWidth: 10,
            data: dataset.Data
        }]
}
}
}

};
</script>
