import Highcharts from 'highcharts'
import { sortBy } from '../vendors/utils.js'
import { svg } from '../assets/svg'

const timezone = new Date().getTimezoneOffset()
    // Highcharts global options
Highcharts.setOptions({
lang: {
    months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    //shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
},
global: {
    timezoneOffset: timezone
}
});

    // custom Highcharts extraGlobal variable
Highcharts.extraGlobal = {
    dateFormatTooltips: '%e-%m-%Y',
    dateTimeLabelFormats: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '<b>%H:%M</b>',
        day: '%e %b %Y',
        week: '%e %b %Y',
        month: '%b %Y',
        year: '%Y'
    },
    originalFunctions: {
        reset: Highcharts.Pointer.prototype.reset
    },
    sync: {
        crosshair: {
            width: 2,
            //color: 'pink',
            color: "black",
            dashStyle: 'shortdot'
            //dashStyle: 'LongDash'
        },
        xModded: (entry, modType) => {
            if (modType) {
            //console.log("modType:  ", modType);
            //if (modType === "datetime" && typeof entry === "string") {
                //entry = entry.split(" ");
                //if (entry.length > 1) {
                //let tempRawHours = entry[1];
                //tempRawHours = tempRawHours.split(":");
                //entry[1] = tempRawHours[0] + "h" + tempRawHours[1];
                //console.log(entry);
                //}
            //}
            if (modType === "datetime") {
                // si les passes suivantes sont trop couteuses en CPU
                    // je peux mettre en place une entrée .rawX depuis seriesBuilder
                // year-month-day hours:minutes:seconds
                let tempRes = [];
                let tempMonth = (entry.getMonth()+1);
                let tempDay = entry.getDate();
                //let gmtOffset = entry.getTimezoneOffset() / 60;
                let gmtOffset = 0;
                if (tempMonth < 10) {
                    tempMonth = "0" + tempMonth;
                }
                if (tempDay < 10) {
                    tempDay = "0" + tempDay;
                }
                // tempRes.push(entry.getFullYear()+"-"+tempMonth+"-"+tempDay);
                let tempHours = entry.getHours();
                if (tempHours && tempHours > 0 && tempHours > gmtOffset) {
                    tempHours = entry.getHours() + gmtOffset;
                } else {
                    tempHours = 0;
                }
                if (tempHours < 10) {
                    tempHours = "0" + tempHours;
                }
                let tempMinutes = entry.getMinutes();
                if (tempMinutes < 10) {
                    tempMinutes = "0" + tempMinutes;
                }
                // tempRes.push(tempHours+"h"+tempMinutes);
                tempRes.push(entry.getFullYear()+"-"+tempMonth+"-"+tempDay+" "+tempHours+"h"+tempMinutes);
                entry = tempRes;
            }
            }
            return entry;
        },
        /*
        lastExtremes: {
            min: null,
            max: null
        },
        */
        lastExtremes: null, //<- cleared when zoomOut AND/OR sync OFF
        currentGroup: null,
        lastX: null,
        isActive: false
    }
};

    // Highcharts module(s)
require('highcharts/modules/exporting')(Highcharts); //-> https://www.highcharts.com/docs/export-module/export-module-overview
  //-> https://jsfiddle.net/gh/get/jquery/1.7.2/highcharts/highcharts/tree/master/samples/highcharts/exporting/multiple-charts-offline/
  // export button -> download icon
/*
Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
    var path = [
        // Arrow stem
        'M', x + w * 0.5, y,
        'L', x + w * 0.5, y + h * 0.7,
        // Arrow head
        'M', x + w * 0.3, y + h * 0.5,
        'L', x + w * 0.5, y + h * 0.7,
        'L', x + w * 0.7, y + h * 0.5,
        // Box
        'M', x, y + h * 0.9,
        'L', x, y + h,
        'L', x + w, y + h,
        'L', x + w, y + h * 0.9
    ];
    return path;
};
*/

    // sync PART
const syncExtremes = function (e) {
    if (Highcharts.extraGlobal.sync.isActive) {
        if (e.trigger !== 'syncExtremes') {
            const thisChart = e.target.chart;
            const thisChartExtra = thisChart.extraChart;
            const thisChartId = thisChartExtra.id;
            const thisChartSyncGroup = thisChartExtra.sync.group;
            Highcharts.extraGlobal.sync.lastExtremes = {
                min: e.min,
                max: e.max
            };
                // hide resetZoomButton if exist
            setTimeout(() => {
                if (thisChart.resetZoomButton) {
                    thisChart.resetZoomButton.hide();
                }
            }, 0);
            Highcharts.charts.forEach((currentChart) => {
            if (currentChart) {
                let currentChartExtra = currentChart.extraChart;
                let synchroConfig = currentChartExtra.sync;
                if (synchroConfig) {
                let currentChartId = currentChartExtra.id;
                if (thisChartId !== currentChartId) {
                    if (synchroConfig.group === thisChartSyncGroup) {
                    if (currentChart.xAxis[0].setExtremes) {
                        currentChart.xAxis[0].setExtremes(
                            e.min,
                            e.max,
                            undefined,
                            false,
                            { trigger: 'syncExtremes' }
                        );
                    }
                    }
                }
                }
            }
            });
        }
    }
};
const sync = {
        // isActive is not used at this moment
    isActive: () => {
        return Highcharts.extraGlobal.sync.isActive;
    },
        // init is used to set sync options
    init: (bobol) => {
        if (bobol) {
            if (!Highcharts.extraGlobal.sync.isActive) {
                //Highcharts.extraGlobal.sync.isActive = true;
                Highcharts.Pointer.prototype.reset = function () {
                    return undefined;
                };
                if (!Highcharts.Point.prototype.highlight) {
                    Highcharts.Point.prototype.highlight = function (event) {
                        event = this.series.chart.pointer.normalize(event);
                        this.onMouseOver();
                        this.series.chart.tooltip.refresh(this);
                        this.series.chart.xAxis[0].drawCrosshair(event, this);
                    };
                }
            }
            Highcharts.charts.forEach((i) => {
                if (i) {
                  i.xAxis[0].crosshair = false;
                  if (i.extraChart) {
                    if (i.extraChart.sync) {
                        i.pointer.reset();
                        i.zoomOut(); //<- reset zoom on sync OFF
                        Highcharts.extraGlobal.sync.lastExtremes = null;
                    }
                  }
                }
            });
        } else {
            if (Highcharts.extraGlobal.sync.isActive) {
                Highcharts.Pointer.prototype.reset = Highcharts.extraGlobal.originalFunctions.reset;
            }
            Highcharts.charts.forEach((i) => {
                if (i) {
                  i.xAxis[0].crosshair = false;
                  if (i.extraChart) {
                    if (i.extraChart.sync) {
                      if (i.extraChart.sync.isActive) {
                        i.pointer.reset();
                        i.zoomOut(); //<- reset zoom on sync OFF
                        Highcharts.extraGlobal.sync.lastExtremes = null;
                        i.extraChart.sync.isActive = false;
                      }
                    }
                  }
                }
            });
        }
        Highcharts.extraGlobal.sync.isActive = bobol;
    },
        // process is used for turn ON/OFF eventListener(s)
    process: (e) => {
        if (Highcharts.extraGlobal.sync.isActive) {
            for (let i = 0; i < Highcharts.charts.length; i++) {
            if (Highcharts.charts[i]) {
                let extra = Highcharts.charts[i].extraChart;
                let synchroConfig = extra.sync;
                if (synchroConfig && synchroConfig.group === Highcharts.extraGlobal.sync.currentGroup) {
                        // Init currentChart
                    let currentChart = Highcharts.charts[i];
                        // init sync options for current chart... if not already initialized
                    if (!synchroConfig.isActive) {
                        synchroConfig.isActive = true;
                        currentChart.xAxis[0].crosshair = Highcharts.extraGlobal.sync.crosshair;
                    }
                        // check if a serie is on focus or selected
                    let serieSelected = 0;
                            // NEW VERSION -> if !== undefined && !== null VERSION
                    if (currentChart.extraChart.serieOnFocus !== undefined && currentChart.extraChart.serieOnFocus !== null) {
                        serieSelected = currentChart.extraChart.serieOnFocus;
                    } else if (currentChart.extraChart.serieHovered !== undefined && currentChart.extraChart.serieHovered !== null) {
                        serieSelected = currentChart.extraChart.serieHovered;
                    }
                            // ORIGINAL VERSION
                    /*
                    if (currentChart.extraChart.serieOnFocus) {
                        serieSelected = currentChart.extraChart.serieOnFocus;
                    } else if (currentChart.extraChart.serieHovered) {
                        serieSelected = currentChart.extraChart.serieHovered;
                    }
                    */
                        // point highlighting
                        // find point from lastX value
                    if (Highcharts.extraGlobal.sync.lastX) {
                        let currentSeriePoints = currentChart.series[serieSelected].points;
                        let xType = extra.xType;
                        let currentSeriePointFromLastX = currentSeriePoints.find(element => Highcharts.extraGlobal.sync.xModded(element.category, xType)[0] === Highcharts.extraGlobal.sync.lastX);
                        // if necessary, trying without hour
                        if (!currentSeriePointFromLastX) {
                            currentSeriePointFromLastX = currentSeriePoints.find(element => Highcharts.extraGlobal.sync.xModded(element.category, xType)[0].substring(0,10) === Highcharts.extraGlobal.sync.lastX.substring(0,10));
                        }
                        if (currentSeriePointFromLastX) {
                            if (currentSeriePointFromLastX.isOutOfRange) {
                                currentSeriePointFromLastX.isOutOfRange = false;
                            }
                                // xDepth pass
                            let xDepth = extra.xDepth;
                            // if (xDepth) {
                                // currentSeriePointFromLastX.xDepthPoints = [];
                                //     // NEW VERSION ( ZOOM BUG FIX )
                                // for (let i = 0; i < xDepth-1; i++) {
                                //     let xDepthCurrentIndex = currentSeriePointFromLastX.index+(i+1);
                                //     if (currentSeriePointFromLastX.series.data[xDepthCurrentIndex]) {
                                //         currentSeriePointFromLastX.xDepthPoints.push(currentSeriePointFromLastX.series.data[xDepthCurrentIndex]);
                                //     }
                                // }
                                    // ORIGINAL VERSION ( ZOOM BUGGED )
                                /*
                                for (let i = 0; i < xDepth-1; i++) {
                                    let xDepthCurrentIndex = currentSeriePointFromLastX.index+(i+1);
                                    if (currentSeriePoints[xDepthCurrentIndex]) {
                                        currentSeriePointFromLastX.xDepthPoints.push(currentSeriePoints[xDepthCurrentIndex]);
                                    }
                                }
                                */
                            // }
                                // ending pass
                            currentChart.extraChart.lastPointSync = currentSeriePointFromLastX;
                            currentSeriePointFromLastX.highlight(e);
                        } else {
                            //console.log("OUT OF RANGE");
                            if (currentChart.extraChart.lastPointSync) {
                                if (currentChart.extraChart.lastPointSync.index > currentSeriePoints.length/2) {
                                    currentChart.extraChart.lastPointSync = currentSeriePoints[currentSeriePoints.length-1];
                                } else {
                                    currentChart.extraChart.lastPointSync = currentSeriePoints[0];
                                }
                                    // NEW VERSION
                                if (currentChart.extraChart.lastPointSync) {
                                    currentChart.extraChart.lastPointSync.isOutOfRange = true;
                                    if (currentChart.extraChart.lastPointSync.xDepthPoints) {
                                        currentChart.extraChart.lastPointSync.xDepthPoints = null;
                                    }
                                    currentChart.extraChart.lastPointSync.highlight(e);
                                }

                                    // ORFIGINAL VERSION génère le bug suivant:
                                        //console.log("BUG here -> onZoom -> pour le reproduire, avoir un graph avec aucun points en commun avec un autre graph. Exemple : meteo + rfid activé + zoomer sur le graph météo après avoir était en onHover sur le graph rfid.");
                                /*
                                currentChart.extraChart.lastPointSync.isOutOfRange = true;
                                if (currentChart.extraChart.lastPointSync.xDepthPoints) {
                                    currentChart.extraChart.lastPointSync.xDepthPoints = null;
                                }
                                currentChart.extraChart.lastPointSync.highlight(e);
                                */
                            } 
                        }
                    }
                }
            }
            }
        }
    }
};

    // building PART
const buildChartSetup = (dataName, data, config, itemId, projectInfos) => {

    // projectInfos pass
    if (!projectInfos) {
        projectInfos = {};
    }
    projectInfos.currentModule = itemId.split(":")[0];
    projectInfos.currentItem = itemId.split(":")[1];
    if (dataName !== projectInfos.currentItem) {
        projectInfos.currentItem = projectInfos.currentItem + "-" + dataName;
    }
    
    // color function(s)
        // rainbow function
    const rainbowGenerator = (size) => {
        function sin_to_hex(i, phase) {
            let sin = Math.sin(Math.PI / size * 2 * i + phase);
            let int = Math.floor(sin * 127) + 128;
            let hex = int.toString(16);
            return hex.length === 1 ? "0"+hex : hex;
        }
        let rainbow = new Array(size);
        for (var i=0; i<size; i++) {
            let red   = sin_to_hex(i, 0 * Math.PI * 2/3);
            let blue  = sin_to_hex(i, 1 * Math.PI * 2/3);
            let green = sin_to_hex(i, 2 * Math.PI * 2/3);
            rainbow[i] = "#"+ red + green + blue;
        }
            // return
        return rainbow;
    };
        // pick color(s) function
    const defaultColor = "black"; //<- useless?
    let linesColor = null;
    if (config.linesColor) {
        //console.log("config.linesColor: ", config.linesColor);
        linesColor = [];
        config.linesColor.forEach((i)=>{
            linesColor.push(i);
        });
    }
    const pickColor = () => {
        if (linesColor) {
            let color = linesColor[0];
            if (color) {
                linesColor.splice(0, 1);
                return color;
            } else  {
                color = colors[0];
                colors.splice(0, 1);
                return color;
            }
        } else if (colors && colors.length) {
            let color = colors[0];
            colors.splice(0, 1);
            return color;
        } else {
            return defaultColor
        }
    };
        // color setter
    const setColor = () => {
        let configColor = config.color || config.lineColor;
        if (!configColor) {
            configColor = pickColor();
        }
        return configColor;
    };

    // variable(s) init
    let chartX = [];
    let chartSeries = [];

    // xDataName AND yExclude init
    let xDataName = ["date", "t", "x"];
    if (config.xDataName) {
        xDataName = config.xDataName;
    }
    let yExclude = xDataName;
    if (config.yExclude) {
        yExclude = config.yExclude;
    }

    // serie(s) detection and init
    let seriesDataTemplate = {};
    let seriesTemplaterIncrement = 0;
    const seriesTemplater = () => {
        let seriesFromRawDatas = Object.keys(data[seriesTemplaterIncrement]);
        if (seriesFromRawDatas.length <= 1) {
            seriesTemplaterIncrement += 1;
            seriesTemplater();
        } else {
            for (let name in data[seriesTemplaterIncrement]) {
                if (!yExclude.includes(name)) {
                    seriesDataTemplate[name] = [];
                }
                /*
                if (name !== "date" && name !== "seuil" && name !== "t") {
                    seriesDataTemplate[name] = [];
                }
                */
            }
        }
    };
    seriesTemplater();

    // rainbow color length detection
    const colors = rainbowGenerator(Object.keys(seriesDataTemplate).length);

    // serie(s) builder
    const seriesBuilder = () => {
        if (!config.xType) {
            config.xType = "datetime";
        }
        if (config.xType !== "datetime" && config.xType !== "category") {
            config.xType = "datetime";
        }
        /*
        let xDataName = ["date", "t"];
        let yExclude = ["date", "t"];
        if (config.xDataName) {
            xDataName = config.xDataName;
        }
        if (config.yExclude) {
            yExclude = config.yExclude;
        }
        */
        data.forEach(i => {
            if (config.xType === "datetime") {
                let currentDatetime = null;
                xDataName.forEach((j) => {
                    if (!currentDatetime && i[j]) {
                        currentDatetime = i[j];
                    }
                });
                currentDatetime = new Date(currentDatetime);
                for (let name in i) {
                    if (seriesDataTemplate[name]) {
                        i[name] = parseFloat(i[name]); // <- VERY IMPORTANT!!!!
                        let dataToPush = {
                            y: i[name],
                            x: currentDatetime
                        };
                        seriesDataTemplate[name].push(dataToPush);
                    }
                    /*
                    if (!yExclude.includes(name)) {
                        if (seriesDataTemplate[name]) {
                            i[name] = parseFloat(i[name]); // <- VERY IMPORTANT!!!!
                            let dataToPush = {
                            y: i[name],
                            x: currentDatetime
                            };
                            seriesDataTemplate[name].push(dataToPush);
                        }
                    }
                    */
                }
            } else {
                for (let name in i) {
                    if (seriesDataTemplate[name]) {
                        i[name] = parseFloat(i[name]); // <- VERY IMPORTANT!!!!
                        let dataToPush = {
                            y: i[name]
                        };
                        seriesDataTemplate[name].push(dataToPush);
                    } else if (xDataName.includes(name)) {
                        chartX.push(i[name]);
                    }
                    /*
                    if (!yExclude.includes(name)) {
                        if (seriesDataTemplate[name]) {
                            i[name] = parseFloat(i[name]); // <- VERY IMPORTANT!!!!
                            let dataToPush = {
                            y: i[name]
                            };
                            seriesDataTemplate[name].push(dataToPush);
                        }
                    } else if (xDataName.includes(name)) {
                        chartX.push(i[name]);
                    }
                    */
                }
            }
        });
        for (let name in seriesDataTemplate) {
            let chartSeriePayload = {
                // MORE SERIES CONFIG SET HERE?!
                name: name,
                data: seriesDataTemplate[name]
            };
            // set color
            chartSeriePayload.color = setColor();
                // NEW
            chartSeriePayload.marker = {
                fillColor: chartSeriePayload.color
            };
            // push the serie inside series
            chartSeries.push(chartSeriePayload);
        }
        seriesDataTemplate = null;
    };
    seriesBuilder();

    // series reordering
        // alphabetical order (property = "name")
    chartSeries = sortBy(chartSeries, "name");
    
    // object to return
    let ChartSetup = {
        credits: {
            enabled: false
        },
        chart: {
            backgroundColor: "none",
            type: config.type || 'line',
            //-> https://api.highcharts.com/highcharts/chart.panning
            zoomType: 'xy'
            //zoomType: 'x',
            //panning: true,
            //panKey: 'shift'
            /*
            zoomType: 'x',
            resetZoomButton: {
                theme: {
                    fill: 'white',
                    stroke: 'silver',
                    r: 0,
                    states: {
                        hover: {
                            fill: '#41739D',
                            style: {
                                color: 'white'
                            }
                        }
                    }
                }
            }
            */
        },
        title: {
            margin: 50, //<- PROVISOIRE -> VOIR tooltip

            text: config.title || ""
        },
        xAxis: {
            labels: {}
        },
        yAxis: {
            title: {
                text: config.yLabel || ""
            }
        },
        series: chartSeries,
        legend: {
            useHTML: true,
            labelFormatter: function () {
                let currentChart = this.chart;
                let isForExport = currentChart.options.chart.forExport;
                //console.log("isForExport: ", isForExport);
                if (!isForExport) {
                    let extraChart = currentChart.extraChart || currentChart.userOptions.extraOptions;
                    //console.log("extraChart: ", extraChart);
                    //console.log("hello from legend formatter, current: ", this.name);
                    if (extraChart.legend) {
                        let currentExtraLegend = extraChart.legend[this.name];
                        if (currentExtraLegend) {
                            let infoLegend = '';
                            if (currentExtraLegend.info_url) {
                                infoLegend = '<a onclick="(function(e){e.stopPropagation();})(event)" href="'+currentExtraLegend.info_url+'" target="_blank" rel="noopener nofollow noreferrer" style="width:1rem;height:1rem;position:relative;margin-left:0.4rem">' + svg.info + '</a>';
                            }
                            let rimnatLegend = '';
                            if (currentExtraLegend.rimnat_url) {
                                rimnatLegend = '<a onclick="(function(e){e.stopPropagation();})(event)" href="'+currentExtraLegend.rimnat_url+'" target="_blank" rel="noopener nofollow noreferrer" style="width:1rem;height:1rem;position:relative;margin-left:0.4rem">' + svg.rimnat_icon + '</a>';
                            }
                            let domLegend = '<div style="display:flex;width:100%;height:100%;position:relative;justify-content:center;align-items:center;"><span>'+ this.name +'</span>' + infoLegend + rimnatLegend  +'</div>';
                            return domLegend;
                        } else {
                            return this.name;
                        }
                    } else {
                        return this.name;
                    }
                } else {
                    return this.name;
                }
            }
        },
        plotOptions: {
            line: {
                //lineWidth: 1
                lineWidth: 1,
                opacity: 1
            },
            column: {
                //pointWidth: 2
            },
            series: {
                // general options for all series
                //turboThreshold: 0, //<- NEW -> déplacé un peu plus bas lors de la turboThreshold pass
                stickyTracking: false,
                animation:{
                    duration: 1000
                },
                states: {
                    inactive: {
                        //opacity: 0.6
                        opacity: 1 //<- NEW
                    }
                    //hover: {
                        //enabled: true,
                        //lineWidth: 2
                    //}
                },
                point: {
                    events: {}
                },
                events: {}
            }
        },
        tooltip: {
                //<- PROVISOIRE ( voir également title -> margin ) 
            // car gérer le tooltip de cette manière, ne permet pas de gérer convenablement le placement et la taille des elements (responsive)
            
            style: {
                fontSize: config.tooltipFontSize ||  "0.65rem"
            },
            positioner: function () {
                return { x: 0, y: 20 };
            },
            shadow: false,
            borderWidth: 0,
            backgroundColor: 'rgba(255,255,255,0.0)',
            
                //<- PROVISOIRE FIN
        
            valueDecimals: 2
        },
        exporting: {
            enabled: false,
            filename: projectInfos.data_name + "-" + projectInfos.currentModule + "-" + projectInfos.currentItem,
            chartOptions: {
                //-> http://jsfiddle.net/izothep/zt5qLwe1/4/
                //-> https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/css/titles/
                //-> http://jsfiddle.net/Fusher/32yFE/
                //-> https://www.google.com/search?client=firefox-b-d&ei=6SGwXtrYJM_earaDnLgD&q=highcharts+exporting+insert+svg+logo&oq=highcharts+exporting+insert+svg+logo&gs_lcp=CgZwc3ktYWIQAzIFCCEQoAE6BAgAEEc6BAgAEEM6BQgAEIMBOgIIADoECAAQCjoGCAAQFhAeOggIABAWEAoQHjoECAAQEzoHCCEQChCgAToICCEQFhAdEB46BAghEBVQoO4MWL-6DWCOvg1oAHACeACAAWSIAcYTkgEEMzUuMZgBAKABAaoBB2d3cy13aXo&sclient=psy-ab&ved=0ahUKEwia8dqLsZrpAhVPrxoKHbYBBzcQ4dUDCAs&uact=5
                //-> https://www.highcharts.com/forum/viewtopic.php?t=34144&start=15
                caption: {
                    align: "center",
                    text: "<b>" + projectInfos.name + "<b><span> data are provided by </span>" + '<b>https://app.surveillance-geolithe.fr<b>'
                },
                /*
                title: {
                    //text: "test title"
                    text: projectInfos.name + ":" +  projectInfos.currentModule + ":" + projectInfos.currentItem
                },
                */
                /*
                chart: {
                    //marginBottom: 300,
                    events: {
                        beforePrint: function () {
                        },
                        afterPrint: function () {
                        },
                        load: function() {
                        }

                    }
                }
                */
              },
            //-> https://api.highcharts.com/highcharts/exporting.buttons.contextButton
            buttons: {
                contextButton: {
                    //symbol: 'download',
                    symbolSize: 8,
                    symbol: 'triangle-down',
                    theme: {
                        fill:"none"
                    },
                    titleKey: "Export Button",
                    text: 'Export  ',
                    menuItems: ['downloadPNG', 'downloadPDF', 'downloadSVG']
                }
            }
        }
    };

    // plotLines pass
    if (config.plotLines) {
        ChartSetup.yAxis.plotLines = [];
        config.plotLines.forEach((i) => {
            let plotConfig = {
                color: i.color || 'black',
                dashStyle: i.dashStyle || 'dot',
                width: i.width || 2,
                value: i.value || 0,
                label: {
                    align: 'right',
                    style: {
                        color: i.color,
                        fontWeight: 'bold',
                        fontStyle: 'italic'
                    },
                    text: i.text || '',
                    x: -10
                },
                zIndex: 99
            };
            if (i.textSize) {
                plotConfig.label.style.fontSize = i.textSize;
            }
            ChartSetup.yAxis.plotLines.push(plotConfig);
        });
    }
// new config graph ALEX
    if (config.multipleLines) {
        ChartSetup.xAxis= [{
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            crosshair: true
        }],
        ChartSetup.yAxis = [{ // Primary yAxis
            labels: {
                format: '{value}°C',
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            },
            title: {
                text: 'Temperature',
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            }
        }, { // Secondary yAxis
            title: {
                text: 'Rainfall',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            labels: {
                format: '{value} mm',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            opposite: true
        }]
    }
    // min AND max pass (yTicks)
    /*
    ChartSetup.yAxis.tickPositioner = function () {
        console.log("yAxis.tickPositioner: ", this);
        //return config.yTicks;
    };
    */
        // ticks version
    /*
    if (config.yTicks) {
        ChartSetup.yAxis.tickPositioner = function () {
            return config.yTicks;
        };
    }
    */
        // max version OFF
    /*
    if (config.max !== null && config.max !== undefined) {
        console.log("config.min:", config.min);
        console.log("config.max:", config.max);
        ChartSetup.yAxis.min = config.min;
        ChartSetup.yAxis.max = config.max;
        ChartSetup.yAxis.startOnTick = false;
        ChartSetup.yAxis.endOnTick = false;
        ChartSetup.yAxis.showLastLabel = true;
    }
    */

    // turboThreshold pass
    if (chartSeries[0].data.length >= 1000) {
        ChartSetup.plotOptions.series.turboThreshold = chartSeries[0].data.length; // <-- OR 0 (zero) to disable it?!
    }

    // xType pass
    if (config.xType === "datetime") {
        ChartSetup.xAxis.type = "datetime";
        ChartSetup.xAxis.dateTimeLabelFormats = Highcharts.extraGlobal.dateTimeLabelFormats;
    } else {
        ChartSetup.xAxis.type = "category";
        ChartSetup.xAxis.categories = chartX;
    }

    // series pass + chart.extraChart.serieHovered setting
        // series
    ChartSetup.plotOptions.series.events.mouseOver = function(e){
        let currentChart = e.target.chart;
        let currentChartSync = currentChart.extraChart.sync;
        if (currentChartSync) {
            Highcharts.extraGlobal.sync.currentGroup = currentChart.extraChart.sync.group;
        } else {
            Highcharts.extraGlobal.sync.currentGroup = null;
        }
        currentChart.extraChart.serieHovered = this.index;
    };
        // point
    ChartSetup.plotOptions.series.point.events.mouseOver = function(e){
        Highcharts.extraGlobal.sync.lastX = Highcharts.extraGlobal.sync.xModded(this.category, e.target.series.chart.extraChart.xType)[0];
    };

    // legend pass
    const legendItemClickInit = () => {
        ChartSetup.plotOptions.series.events.legendItemClick = function(e){
            let _this = e.target;
            let seriesIndex = _this.index;
            let series = _this.chart.series;
            let currentChart = _this.chart;
            if (!currentChart.seriesOnFocus) {
                currentChart.seriesOnFocus = [];
            }
            if (_this.visible) {
                if (!currentChart.seriesOnFocus.length) {
                    series.forEach((i) => {
                        if (i.index !== seriesIndex) {
                            i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                        }
                    });
                    currentChart.seriesOnFocus.push(seriesIndex);
                    currentChart.extraChart.serieOnFocus = seriesIndex;
                } else if (currentChart.seriesOnFocus.length === 1) {
                    series.forEach((i) => {
                        if (i.index !== seriesIndex) {
                            i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                        }
                    });
                    currentChart.seriesOnFocus.splice(currentChart.seriesOnFocus.indexOf(seriesIndex), 1 );
                    currentChart.extraChart.serieOnFocus = null;
                } else {
                    _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                    currentChart.seriesOnFocus.splice(currentChart.seriesOnFocus.indexOf(seriesIndex), 1 );
                    currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                }
            } else {
                if (currentChart.seriesOnFocus.length) {
                    _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                    currentChart.seriesOnFocus.push(seriesIndex);
                    currentChart.extraChart.serieOnFocus = seriesIndex;
                }
            }
            currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
            return false; //<- IMPORTANT -> block the "next()" process
        };
    };
    const legendItemClickBlockedInit = () => {
        ChartSetup.plotOptions.series.events.legendItemClick = function(e){
            e.preventDefault();
        };
    };
    if (config.noLegend) {
        ChartSetup.legend.enabled = false;
    } else if (chartSeries.length > 1) {
        legendItemClickInit();
    } else {
        legendItemClickBlockedInit();
    }

    // ChartSetup.xAxis.events init
    ChartSetup.xAxis.events = {};

    // ChartSetup.yAxis.events init
    ChartSetup.yAxis.events = {};

    // .extraOptions pass
    ChartSetup.extraOptions = {
        isZoomed: false,
        zoomData: {
            originalX: {min: null, max: null},
            originalY: {min: null, max: null}
        },
        legend: null,
        xType: config.xType,
        xDepth: config.xDepth || 0,
        id: itemId + ":" + dataName,
        item_activated_id: itemId
    };
        // .extraOptions.isZoomed pass
    ChartSetup.xAxis.events.afterSetExtremes = function() {
        //console.log("hello from afterSetExtremes", this);
        let currentChart = this.chart;
        //console.log("currentChart: ", currentChart);
        let extra = currentChart.extraChart;
        //console.log("extra: ", extra);
        let zoomData = extra.zoomData;
        //console.log("zoomData: ", zoomData);
        if (!extra.isZoomed) {
            zoomData.originalX.min = this.oldMin;
            zoomData.originalX.max = this.oldMax;
        }
        //console.log("this.oldMin: ", this.oldMin);
        //console.log("this.min: ", this.min);
        if(this.min === zoomData.originalX.min && this.max === zoomData.originalX.max) {
            //console.log('not zoomed');
            extra.isZoomed = false;
        } else {
            //console.log('zoomed');
            extra.isZoomed = true;
        }
        //console.log("zoomData X: ", zoomData);
    };
    ChartSetup.yAxis.events.afterSetExtremes = function() {
        let currentChart = this.chart;
        //let extra = currentChart.extraChart;
        let zoomData = currentChart.extraChart.zoomData;
        if (zoomData.originalY.min === null) {
            zoomData.originalY.min = this.oldMin;
        }
        if (zoomData.originalY.max === null) {
            zoomData.originalY.max = this.oldMax;
        }
        //console.log("zoomData Y: ", zoomData);
    };
        // .extraOptions.synchro pass
    if (config.syncGroup) {
        ChartSetup.extraOptions.sync = {
            group: config.syncGroup
        };
        ChartSetup.xAxis.events.setExtremes = syncExtremes;
    }
        // .extraOptions.legend pass
    if (config.legend) {
        ChartSetup.extraOptions.legend = config.legend;
    }
        // .extraOptions.xDepthTooltipColumn pass
    if (config.xDepthTooltipColumn) {
        ChartSetup.extraOptions.xDepthTooltipColumn = config.xDepthTooltipColumn;
    }

    // tooltip pass
    ChartSetup.tooltip.formatter = function (tooltip) {

        // return false; to disable tooltip but keep formatter active

        let currentChart = this.point.series.chart;
        let currentChartExtra = currentChart.extraChart;
        let yTitle = "";
        if (typeof currentChart.yAxis[0].axisTitle !== 'undefined') yTitle = currentChart.yAxis[0].axisTitle.textStr;
        let rawXValue = this.point.category;
        let xModded = Highcharts.extraGlobal.sync.xModded(rawXValue, currentChartExtra.xType);
        let xValue = xModded[0];
        if (currentChartExtra.xType === "datetime") {
            if (!currentChartExtra.sync || !currentChartExtra.sync.isActive) {
                if (xModded.length > 1 ) {
                    xValue = Highcharts.dateFormat(Highcharts.extraGlobal.dateFormatTooltips, new Date(xModded[0])) + " " + xModded[1];
                } else {
                    xValue = Highcharts.dateFormat(Highcharts.extraGlobal.dateFormatTooltips, new Date(xModded[0]));
                }
            } else if (!this.point.isOutOfRange) {
                xValue = Highcharts.dateFormat(Highcharts.extraGlobal.dateFormatTooltips, new Date(xValue));
            } else {
                xValue = Highcharts.dateFormat(Highcharts.extraGlobal.dateFormatTooltips, new Date(Highcharts.extraGlobal.sync.lastX));
            }
        }
        let yValue = this.point.y;
        yValue = yValue.toFixed(2);
        let tooltipHeader = "<span>" + xModded[0] + "</span><br/>";
        let tooltipBody = "<span style='color:"+ this.point.color +"'> ●</span><span> " + this.point.series.name + ": <b>" + yValue + "</b> " + yTitle + "</span>";
        if (this.point.isOutOfRange) {
            tooltipBody = "<span style='color:"+ this.point.color +"'> ●</span><span> " + this.point.series.name + ": <b>NaN</b></span>";
        } else if (this.point.xDepthPoints) {

                // NEW VERSION
                    // // on réinitialise le tooltipBody par default
            tooltipBody = "";
                    // on créer le nouveau tooltipHeader
            tooltipHeader = "<span><b>" + xValue + "</b></span><span style='color:"+ this.point.color +"'> ●</span><span><b> " + this.point.series.name + "</b></span><br/>";
                    // on créer un Array temporaire afin de ne pas modifier l'objet this.point
            let unshiftedXDepthPointsArray = [];
            unshiftedXDepthPointsArray.push(this.point);
            this.point.xDepthPoints.forEach(element => {
                unshiftedXDepthPointsArray.push(element);
            });
                    // on process
            if (currentChartExtra.xDepthTooltipColumn) {
                let pointsTotal = unshiftedXDepthPointsArray.length;
                let colTotal = currentChartExtra.xDepthTooltipColumn;
                let j = 0;
                for (let i = 0; i < pointsTotal; i++) {
                    let element = unshiftedXDepthPointsArray[i];
                    let elementXModded = Highcharts.extraGlobal.sync.xModded(element.category, currentChartExtra.xType)[1];
                    let elementYValue = element.y;
                    elementYValue = elementYValue.toFixed(2);
                    if (j === colTotal-1) {
                        tooltipBody += "<span> " + elementXModded + ": <b>" + elementYValue + "</b> " + yTitle + "</span><br/>";
                    } else {
                        tooltipBody += "<span> " + elementXModded + ": <b>" + elementYValue + "</b> " + yTitle + ",</span>";
                    }
                    j += 1;
                    if (j >= colTotal) {
                        j = 0;
                    }
                        // exemple de boucle d'itération
                    /*
                    for (let j = 0; j < colTotal; j++) {
                        console.log("j: ", j);
                        if (j >= colTotal-1) {
                            console.log("test");
                        }
                    }
                    */
                }
            } else {
                unshiftedXDepthPointsArray.forEach(element => {
                    let elementXModded = Highcharts.extraGlobal.sync.xModded(element.category, currentChartExtra.xType)[1];
                    let elementYValue = element.y;
                    elementYValue = elementYValue.toFixed(2);
                    tooltipBody += "<span> " + elementXModded + ": <b>" + elementYValue + "</b> " + yTitle + "</span><br/>";
                });
            }
                    // on clear Array temporaire précedemment créé
            unshiftedXDepthPointsArray = null;

                // ORIGINAL VERSION
            /*
            tooltipBody = "<span style='color:"+ this.point.color +"'> ●</span><span> " + this.point.series.name + " </span><span>" + xModded[1] + ": <b>" + yValue + "</b> " + yTitle + "</span><br/>";
            this.point.xDepthPoints.forEach(element => {
                let elementXModded = Highcharts.extraGlobal.sync.xModded(element.category, currentChartExtra.xType)[1];
                let elementYValue = element.y;
                elementYValue = elementYValue.toFixed(2);
                tooltipBody += "<span style='color:"+ this.point.color +"'> ●</span><span> " + this.point.series.name + " </span><span>" + elementXModded + ": <b>" + elementYValue + "</b> " + yTitle + "</span><br/>";
            });   
            */
        }
        return tooltipHeader + tooltipBody;
        //return tooltip.defaultFormatter.call(this, tooltip);
    };

    // exporting pass
    if (config.exporting) {
        ChartSetup.exporting.enabled = true;
    }
    
    // return
    return ChartSetup;

};
const buildChartSetup_multiple = (dataName, data, config, itemId, projectInfos) => {

    // projectInfos pass
    if (!projectInfos) {
        projectInfos = {};
    }
    projectInfos.currentModule = itemId.split(":")[0];
    projectInfos.currentItem = itemId.split(":")[1];
    if (dataName !== projectInfos.currentItem) {
        projectInfos.currentItem = projectInfos.currentItem + "-" + dataName;
    }
    
    // color function(s)
        // rainbow function
    const rainbowGenerator = (size) => {
        function sin_to_hex(i, phase) {
            let sin = Math.sin(Math.PI / size * 2 * i + phase);
            let int = Math.floor(sin * 127) + 128;
            let hex = int.toString(16);
            return hex.length === 1 ? "0"+hex : hex;
        }
        let rainbow = new Array(size);
        for (var i=0; i<size; i++) {
            let red   = sin_to_hex(i, 0 * Math.PI * 2/3);
            let blue  = sin_to_hex(i, 1 * Math.PI * 2/3);
            let green = sin_to_hex(i, 2 * Math.PI * 2/3);
            rainbow[i] = "#"+ red + green + blue;
        }
            // return
        return rainbow;
    };
        // pick color(s) function
    const defaultColor = "black"; //<- useless?
    let linesColor = null;
    if (config.linesColor) {
        //console.log("config.linesColor: ", config.linesColor);
        linesColor = [];
        config.linesColor.forEach((i)=>{
            linesColor.push(i);
        });
    }
    const pickColor = () => {
        if (linesColor) {
            let color = linesColor[0];
            if (color) {
                linesColor.splice(0, 1);
                return color;
            } else  {
                color = colors[0];
                colors.splice(0, 1);
                return color;
            }
        } else if (colors && colors.length) {
            let color = colors[0];
            colors.splice(0, 1);
            return color;
        } else {
            return defaultColor
        }
    };
        // color setter
    const setColor = () => {
        let configColor = config.color || config.lineColor;
        if (!configColor) {
            configColor = pickColor();
        }
        return configColor;
    };

    // variable(s) init
    let chartX = [];
    let chartSeries = [];

    // xDataName AND yExclude init
    let xDataName = ["date", "t", "x"];
    if (config.xDataName) {
        xDataName = config.xDataName;
    }
    let yExclude = xDataName;
    if (config.yExclude) {
        yExclude = config.yExclude;
    }

    // serie(s) detection and init
    let seriesDataTemplate = {};
    let seriesTemplaterIncrement = 0;
    const seriesTemplater = () => {
        let seriesFromRawDatas = Object.keys(data[seriesTemplaterIncrement]);
        if (seriesFromRawDatas.length <= 1) {
            seriesTemplaterIncrement += 1;
            seriesTemplater();
        } else {
            for (let name in data[seriesTemplaterIncrement]) {
                if (!yExclude.includes(name)) {
                    seriesDataTemplate[name] = [];
                }
                /*
                if (name !== "date" && name !== "seuil" && name !== "t") {
                    seriesDataTemplate[name] = [];
                }
                */
            }
        }
    };
    seriesTemplater();

    // rainbow color length detection
    const colors = rainbowGenerator(Object.keys(seriesDataTemplate).length);

    // serie(s) builder
    const seriesBuilder = () => {
        if (!config.xType) {
            config.xType = "datetime";
        }
        if (config.xType !== "datetime" && config.xType !== "category") {
            config.xType = "datetime";
        }
        /*
        let xDataName = ["date", "t"];
        let yExclude = ["date", "t"];
        if (config.xDataName) {
            xDataName = config.xDataName;
        }
        if (config.yExclude) {
            yExclude = config.yExclude;
        }
        */
        data.forEach(i => {
            if (config.xType === "datetime") {
                let currentDatetime = null;
                xDataName.forEach((j) => {
                    if (!currentDatetime && i[j]) {
                        currentDatetime = i[j];
                    }
                });
                currentDatetime = new Date(currentDatetime);
                for (let name in i) {
                    if (seriesDataTemplate[name]) {
                        i[name] = parseFloat(i[name]); // <- VERY IMPORTANT!!!!
                        let dataToPush = {
                            y: i[name],
                            x: currentDatetime
                        };
                        seriesDataTemplate[name].push(dataToPush);
                    }
                    /*
                    if (!yExclude.includes(name)) {
                        if (seriesDataTemplate[name]) {
                            i[name] = parseFloat(i[name]); // <- VERY IMPORTANT!!!!
                            let dataToPush = {
                            y: i[name],
                            x: currentDatetime
                            };
                            seriesDataTemplate[name].push(dataToPush);
                        }
                    }
                    */
                }
            } else {
                for (let name in i) {
                    if (seriesDataTemplate[name]) {
                        i[name] = parseFloat(i[name]); // <- VERY IMPORTANT!!!!
                        let dataToPush = {
                            y: i[name]
                        };
                        seriesDataTemplate[name].push(dataToPush);
                    } else if (xDataName.includes(name)) {
                        chartX.push(i[name]);
                    }
                    /*
                    if (!yExclude.includes(name)) {
                        if (seriesDataTemplate[name]) {
                            i[name] = parseFloat(i[name]); // <- VERY IMPORTANT!!!!
                            let dataToPush = {
                            y: i[name]
                            };
                            seriesDataTemplate[name].push(dataToPush);
                        }
                    } else if (xDataName.includes(name)) {
                        chartX.push(i[name]);
                    }
                    */
                }
            }
        });
        for (let name in seriesDataTemplate) {
            let chartSeriePayload = {
                // MORE SERIES CONFIG SET HERE?!
                name: name,
                data: seriesDataTemplate[name]
            };
            // set color
            chartSeriePayload.color = setColor();
                // NEW
            chartSeriePayload.marker = {
                fillColor: chartSeriePayload.color
            };
            // push the serie inside series
            chartSeries.push(chartSeriePayload);
        }
        seriesDataTemplate = null;
    };
    seriesBuilder();

    // series reordering
        // alphabetical order (property = "name")
    chartSeries = sortBy(chartSeries, "name");
    
    // object to return
    let ChartSetup = {
        credits: {
            enabled: false
        },
        chart: {
            backgroundColor: "none",
            type: config.type || 'line',
            //-> https://api.highcharts.com/highcharts/chart.panning
            zoomType: 'xy'
            //zoomType: 'x',
            //panning: true,
            //panKey: 'shift'
            /*
            zoomType: 'x',
            resetZoomButton: {
                theme: {
                    fill: 'white',
                    stroke: 'silver',
                    r: 0,
                    states: {
                        hover: {
                            fill: '#41739D',
                            style: {
                                color: 'white'
                            }
                        }
                    }
                }
            }
            */
        },
        title: {
            margin: 50, //<- PROVISOIRE -> VOIR tooltip

            text: config.title || ""
        },
        xAxis: {
            labels: {}
        },
        yAxis: {
            title: {
                text: config.yLabel || ""
            }
        },
        series: chartSeries,
        legend: {
            useHTML: true,
            labelFormatter: function () {
                let currentChart = this.chart;
                let isForExport = currentChart.options.chart.forExport;
                //console.log("isForExport: ", isForExport);
                if (!isForExport) {
                    let extraChart = currentChart.extraChart || currentChart.userOptions.extraOptions;
                    //console.log("extraChart: ", extraChart);
                    //console.log("hello from legend formatter, current: ", this.name);
                    if (extraChart.legend) {
                        let currentExtraLegend = extraChart.legend[this.name];
                        if (currentExtraLegend) {
                            let infoLegend = '';
                            if (currentExtraLegend.info_url) {
                                infoLegend = '<a onclick="(function(e){e.stopPropagation();})(event)" href="'+currentExtraLegend.info_url+'" target="_blank" rel="noopener nofollow noreferrer" style="width:1rem;height:1rem;position:relative;margin-left:0.4rem">' + svg.info + '</a>';
                            }
                            let rimnatLegend = '';
                            if (currentExtraLegend.rimnat_url) {
                                rimnatLegend = '<a onclick="(function(e){e.stopPropagation();})(event)" href="'+currentExtraLegend.rimnat_url+'" target="_blank" rel="noopener nofollow noreferrer" style="width:1rem;height:1rem;position:relative;margin-left:0.4rem">' + svg.rimnat_icon + '</a>';
                            }
                            let domLegend = '<div style="display:flex;width:100%;height:100%;position:relative;justify-content:center;align-items:center;"><span>'+ this.name +'</span>' + infoLegend + rimnatLegend  +'</div>';
                            return domLegend;
                        } else {
                            return this.name;
                        }
                    } else {
                        return this.name;
                    }
                } else {
                    return this.name;
                }
            }
        },
        plotOptions: {
            line: {
                //lineWidth: 1
                lineWidth: 1,
                opacity: 1
            },
            column: {
                //pointWidth: 2
            },
            series: {
                // general options for all series
                //turboThreshold: 0, //<- NEW -> déplacé un peu plus bas lors de la turboThreshold pass
                stickyTracking: false,
                animation:{
                    duration: 1000
                },
                states: {
                    inactive: {
                        //opacity: 0.6
                        opacity: 1 //<- NEW
                    }
                    //hover: {
                        //enabled: true,
                        //lineWidth: 2
                    //}
                },
                point: {
                    events: {}
                },
                events: {}
            }
        },
        tooltip: {
                //<- PROVISOIRE ( voir également title -> margin ) 
            // car gérer le tooltip de cette manière, ne permet pas de gérer convenablement le placement et la taille des elements (responsive)
            
            style: {
                fontSize: config.tooltipFontSize ||  "0.65rem"
            },
            positioner: function () {
                return { x: 0, y: 20 };
            },
            shadow: false,
            borderWidth: 0,
            backgroundColor: 'rgba(255,255,255,0.0)',
            
                //<- PROVISOIRE FIN
        
            valueDecimals: 2
        },
        exporting: {
            enabled: false,
            filename: projectInfos.data_name + "-" + projectInfos.currentModule + "-" + projectInfos.currentItem,
            chartOptions: {
                //-> http://jsfiddle.net/izothep/zt5qLwe1/4/
                //-> https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/css/titles/
                //-> http://jsfiddle.net/Fusher/32yFE/
                //-> https://www.google.com/search?client=firefox-b-d&ei=6SGwXtrYJM_earaDnLgD&q=highcharts+exporting+insert+svg+logo&oq=highcharts+exporting+insert+svg+logo&gs_lcp=CgZwc3ktYWIQAzIFCCEQoAE6BAgAEEc6BAgAEEM6BQgAEIMBOgIIADoECAAQCjoGCAAQFhAeOggIABAWEAoQHjoECAAQEzoHCCEQChCgAToICCEQFhAdEB46BAghEBVQoO4MWL-6DWCOvg1oAHACeACAAWSIAcYTkgEEMzUuMZgBAKABAaoBB2d3cy13aXo&sclient=psy-ab&ved=0ahUKEwia8dqLsZrpAhVPrxoKHbYBBzcQ4dUDCAs&uact=5
                //-> https://www.highcharts.com/forum/viewtopic.php?t=34144&start=15
                caption: {
                    align: "center",
                    text: "<b>" + projectInfos.name + "<b><span> data are provided by </span>" + '<b>https://app.surveillance-geolithe.fr<b>'
                },
                /*
                title: {
                    //text: "test title"
                    text: projectInfos.name + ":" +  projectInfos.currentModule + ":" + projectInfos.currentItem
                },
                */
                /*
                chart: {
                    //marginBottom: 300,
                    events: {
                        beforePrint: function () {
                        },
                        afterPrint: function () {
                        },
                        load: function() {
                        }

                    }
                }
                */
              },
            //-> https://api.highcharts.com/highcharts/exporting.buttons.contextButton
            buttons: {
                contextButton: {
                    //symbol: 'download',
                    symbolSize: 8,
                    symbol: 'triangle-down',
                    theme: {
                        fill:"none"
                    },
                    titleKey: "Export Button",
                    text: 'Export  ',
                    menuItems: ['downloadPNG', 'downloadPDF', 'downloadSVG']
                }
            }
        }
    };

    // plotLines pass
    if (config.plotLines) {
        ChartSetup.yAxis.plotLines = [];
        config.plotLines.forEach((i) => {
            let plotConfig = {
                color: i.color || 'black',
                dashStyle: i.dashStyle || 'dot',
                width: i.width || 2,
                value: i.value || 0,
                label: {
                    align: 'right',
                    style: {
                        color: i.color,
                        fontWeight: 'bold',
                        fontStyle: 'italic'
                    },
                    text: i.text || '',
                    x: -10
                },
                zIndex: 99
            };
            if (i.textSize) {
                plotConfig.label.style.fontSize = i.textSize;
            }
            ChartSetup.yAxis.plotLines.push(plotConfig);
        });
    }
// new config graph ALEX
    if (config.multipleLines) {
        ChartSetup.xAxis= [{
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            crosshair: true
        }],
        ChartSetup.yAxis = [{ // Primary yAxis
            labels: {
                format: '{value}°C',
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            },
            title: {
                text: 'Temperature',
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            }
        }, { // Secondary yAxis
            title: {
                text: 'Rainfall',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            labels: {
                format: '{value} mm',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            opposite: true
        }]
    }
    // min AND max pass (yTicks)
    /*
    ChartSetup.yAxis.tickPositioner = function () {
        console.log("yAxis.tickPositioner: ", this);
        //return config.yTicks;
    };
    */
        // ticks version
    /*
    if (config.yTicks) {
        ChartSetup.yAxis.tickPositioner = function () {
            return config.yTicks;
        };
    }
    */
        // max version OFF
    /*
    if (config.max !== null && config.max !== undefined) {
        console.log("config.min:", config.min);
        console.log("config.max:", config.max);
        ChartSetup.yAxis.min = config.min;
        ChartSetup.yAxis.max = config.max;
        ChartSetup.yAxis.startOnTick = false;
        ChartSetup.yAxis.endOnTick = false;
        ChartSetup.yAxis.showLastLabel = true;
    }
    */

    // turboThreshold pass
    if (chartSeries[0].data.length >= 1000) {
        ChartSetup.plotOptions.series.turboThreshold = chartSeries[0].data.length; // <-- OR 0 (zero) to disable it?!
    }

    // xType pass
    if (config.xType === "datetime") {
        ChartSetup.xAxis.type = "datetime";
        ChartSetup.xAxis.dateTimeLabelFormats = Highcharts.extraGlobal.dateTimeLabelFormats;
    } else {
        ChartSetup.xAxis.type = "category";
        ChartSetup.xAxis.categories = chartX;
    }

    // series pass + chart.extraChart.serieHovered setting
        // series
    ChartSetup.plotOptions.series.events.mouseOver = function(e){
        let currentChart = e.target.chart;
        let currentChartSync = currentChart.extraChart.sync;
        if (currentChartSync) {
            Highcharts.extraGlobal.sync.currentGroup = currentChart.extraChart.sync.group;
        } else {
            Highcharts.extraGlobal.sync.currentGroup = null;
        }
        currentChart.extraChart.serieHovered = this.index;
    };
        // point
    ChartSetup.plotOptions.series.point.events.mouseOver = function(e){
        Highcharts.extraGlobal.sync.lastX = Highcharts.extraGlobal.sync.xModded(this.category, e.target.series.chart.extraChart.xType)[0];
    };

    // legend pass
    const legendItemClickInit = () => {
        ChartSetup.plotOptions.series.events.legendItemClick = function(e){
            let _this = e.target;
            let seriesIndex = _this.index;
            let series = _this.chart.series;
            let currentChart = _this.chart;
            if (!currentChart.seriesOnFocus) {
                currentChart.seriesOnFocus = [];
            }
            if (_this.visible) {
                if (!currentChart.seriesOnFocus.length) {
                    series.forEach((i) => {
                        if (i.index !== seriesIndex) {
                            i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                        }
                    });
                    currentChart.seriesOnFocus.push(seriesIndex);
                    currentChart.extraChart.serieOnFocus = seriesIndex;
                } else if (currentChart.seriesOnFocus.length === 1) {
                    series.forEach((i) => {
                        if (i.index !== seriesIndex) {
                            i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                        }
                    });
                    currentChart.seriesOnFocus.splice(currentChart.seriesOnFocus.indexOf(seriesIndex), 1 );
                    currentChart.extraChart.serieOnFocus = null;
                } else {
                    _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                    currentChart.seriesOnFocus.splice(currentChart.seriesOnFocus.indexOf(seriesIndex), 1 );
                    currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                }
            } else {
                if (currentChart.seriesOnFocus.length) {
                    _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                    currentChart.seriesOnFocus.push(seriesIndex);
                    currentChart.extraChart.serieOnFocus = seriesIndex;
                }
            }
            currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
            return false; //<- IMPORTANT -> block the "next()" process
        };
    };
    const legendItemClickBlockedInit = () => {
        ChartSetup.plotOptions.series.events.legendItemClick = function(e){
            e.preventDefault();
        };
    };
    if (config.noLegend) {
        ChartSetup.legend.enabled = false;
    } else if (chartSeries.length > 1) {
        legendItemClickInit();
    } else {
        legendItemClickBlockedInit();
    }

    // ChartSetup.xAxis.events init
    ChartSetup.xAxis.events = {};

    // ChartSetup.yAxis.events init
    ChartSetup.yAxis.events = {};

    // .extraOptions pass
    ChartSetup.extraOptions = {
        isZoomed: false,
        zoomData: {
            originalX: {min: null, max: null},
            originalY: {min: null, max: null}
        },
        legend: null,
        xType: config.xType,
        xDepth: config.xDepth || 0,
        id: itemId + ":" + dataName,
        item_activated_id: itemId
    };
        // .extraOptions.isZoomed pass
    ChartSetup.xAxis.events.afterSetExtremes = function() {
        //console.log("hello from afterSetExtremes", this);
        let currentChart = this.chart;
        //console.log("currentChart: ", currentChart);
        let extra = currentChart.extraChart;
        //console.log("extra: ", extra);
        let zoomData = extra.zoomData;
        //console.log("zoomData: ", zoomData);
        if (!extra.isZoomed) {
            zoomData.originalX.min = this.oldMin;
            zoomData.originalX.max = this.oldMax;
        }
        //console.log("this.oldMin: ", this.oldMin);
        //console.log("this.min: ", this.min);
        if(this.min === zoomData.originalX.min && this.max === zoomData.originalX.max) {
            //console.log('not zoomed');
            extra.isZoomed = false;
        } else {
            //console.log('zoomed');
            extra.isZoomed = true;
        }
        //console.log("zoomData X: ", zoomData);
    };
    ChartSetup.yAxis.events.afterSetExtremes = function() {
        let currentChart = this.chart;
        //let extra = currentChart.extraChart;
        let zoomData = currentChart.extraChart.zoomData;
        if (zoomData.originalY.min === null) {
            zoomData.originalY.min = this.oldMin;
        }
        if (zoomData.originalY.max === null) {
            zoomData.originalY.max = this.oldMax;
        }
        //console.log("zoomData Y: ", zoomData);
    };
        // .extraOptions.synchro pass
    if (config.syncGroup) {
        ChartSetup.extraOptions.sync = {
            group: config.syncGroup
        };
        ChartSetup.xAxis.events.setExtremes = syncExtremes;
    }
        // .extraOptions.legend pass
    if (config.legend) {
        ChartSetup.extraOptions.legend = config.legend;
    }
        // .extraOptions.xDepthTooltipColumn pass
    if (config.xDepthTooltipColumn) {
        ChartSetup.extraOptions.xDepthTooltipColumn = config.xDepthTooltipColumn;
    }

    // tooltip pass
    ChartSetup.tooltip.formatter = function (tooltip) {

        // return false; to disable tooltip but keep formatter active

        let currentChart = this.point.series.chart;
        let currentChartExtra = currentChart.extraChart;
        let yTitle = "";
        if (typeof currentChart.yAxis[0].axisTitle !== 'undefined') yTitle = currentChart.yAxis[0].axisTitle.textStr;
        let rawXValue = this.point.category;
        let xModded = Highcharts.extraGlobal.sync.xModded(rawXValue, currentChartExtra.xType);
        let xValue = xModded[0];
        if (currentChartExtra.xType === "datetime") {
            if (!currentChartExtra.sync || !currentChartExtra.sync.isActive) {
                if (xModded.length > 1 ) {
                    xValue = Highcharts.dateFormat(Highcharts.extraGlobal.dateFormatTooltips, new Date(xModded[0])) + " " + xModded[1];
                } else {
                    xValue = Highcharts.dateFormat(Highcharts.extraGlobal.dateFormatTooltips, new Date(xModded[0]));
                }
            } else if (!this.point.isOutOfRange) {
                xValue = Highcharts.dateFormat(Highcharts.extraGlobal.dateFormatTooltips, new Date(xValue));
            } else {
                xValue = Highcharts.dateFormat(Highcharts.extraGlobal.dateFormatTooltips, new Date(Highcharts.extraGlobal.sync.lastX));
            }
        }
        let yValue = this.point.y;
        yValue = yValue.toFixed(2);
        let tooltipHeader = "<span>" + xModded[0] + "</span><br/>";
        let tooltipBody = "<span style='color:"+ this.point.color +"'> ●</span><span> " + this.point.series.name + ": <b>" + yValue + "</b> " + yTitle + "</span>";
        if (this.point.isOutOfRange) {
            tooltipBody = "<span style='color:"+ this.point.color +"'> ●</span><span> " + this.point.series.name + ": <b>NaN</b></span>";
        } else if (this.point.xDepthPoints) {

                // NEW VERSION
                    // // on réinitialise le tooltipBody par default
            tooltipBody = "";
                    // on créer le nouveau tooltipHeader
            tooltipHeader = "<span><b>" + xValue + "</b></span><span style='color:"+ this.point.color +"'> ●</span><span><b> " + this.point.series.name + "</b></span><br/>";
                    // on créer un Array temporaire afin de ne pas modifier l'objet this.point
            let unshiftedXDepthPointsArray = [];
            unshiftedXDepthPointsArray.push(this.point);
            this.point.xDepthPoints.forEach(element => {
                unshiftedXDepthPointsArray.push(element);
            });
                    // on process
            if (currentChartExtra.xDepthTooltipColumn) {
                let pointsTotal = unshiftedXDepthPointsArray.length;
                let colTotal = currentChartExtra.xDepthTooltipColumn;
                let j = 0;
                for (let i = 0; i < pointsTotal; i++) {
                    let element = unshiftedXDepthPointsArray[i];
                    let elementXModded = Highcharts.extraGlobal.sync.xModded(element.category, currentChartExtra.xType)[1];
                    let elementYValue = element.y;
                    elementYValue = elementYValue.toFixed(2);
                    if (j === colTotal-1) {
                        tooltipBody += "<span> " + elementXModded + ": <b>" + elementYValue + "</b> " + yTitle + "</span><br/>";
                    } else {
                        tooltipBody += "<span> " + elementXModded + ": <b>" + elementYValue + "</b> " + yTitle + ",</span>";
                    }
                    j += 1;
                    if (j >= colTotal) {
                        j = 0;
                    }
                        // exemple de boucle d'itération
                    /*
                    for (let j = 0; j < colTotal; j++) {
                        console.log("j: ", j);
                        if (j >= colTotal-1) {
                            console.log("test");
                        }
                    }
                    */
                }
            } else {
                unshiftedXDepthPointsArray.forEach(element => {
                    let elementXModded = Highcharts.extraGlobal.sync.xModded(element.category, currentChartExtra.xType)[1];
                    let elementYValue = element.y;
                    elementYValue = elementYValue.toFixed(2);
                    tooltipBody += "<span> " + elementXModded + ": <b>" + elementYValue + "</b> " + yTitle + "</span><br/>";
                });
            }
                    // on clear Array temporaire précedemment créé
            unshiftedXDepthPointsArray = null;

                // ORIGINAL VERSION
            /*
            tooltipBody = "<span style='color:"+ this.point.color +"'> ●</span><span> " + this.point.series.name + " </span><span>" + xModded[1] + ": <b>" + yValue + "</b> " + yTitle + "</span><br/>";
            this.point.xDepthPoints.forEach(element => {
                let elementXModded = Highcharts.extraGlobal.sync.xModded(element.category, currentChartExtra.xType)[1];
                let elementYValue = element.y;
                elementYValue = elementYValue.toFixed(2);
                tooltipBody += "<span style='color:"+ this.point.color +"'> ●</span><span> " + this.point.series.name + " </span><span>" + elementXModded + ": <b>" + elementYValue + "</b> " + yTitle + "</span><br/>";
            });   
            */
        }
        return tooltipHeader + tooltipBody;
        //return tooltip.defaultFormatter.call(this, tooltip);
    };

    // exporting pass
    if (config.exporting) {
        ChartSetup.exporting.enabled = true;
    }
    
    // return
    return ChartSetup;

};
    // chartInit PART = set Chart extra
const chartInit = (i) => {
    let extra = i.userOptions.extraOptions; //<- extra is set inside buildChartSetup() ( on end ) ( .extraOptions )
        // .extraChart variable creation
    i.extraChart = {};
        // extra pass
    if (extra) {
        if (extra.xDepth) {
            i.extraChart.xDepth = extra.xDepth;
        }
        if (extra.xType) {
            i.extraChart.xType = extra.xType;
        }
        for (let name in extra) {
            if (!i.extraChart[name]) {
                i.extraChart[name] = extra[name];
            }
        }
            // zoom synchronisation for the new chart
        if (Highcharts.extraGlobal.sync.isActive) {
            if (Highcharts.extraGlobal.sync.lastExtremes) {
                i.xAxis[0].setExtremes(
                    Highcharts.extraGlobal.sync.lastExtremes.min,
                    Highcharts.extraGlobal.sync.lastExtremes.max,
                    undefined,
                    false,
                    { trigger: 'syncExtremes' }
                );
            }
        }
            // clear i.userOptions.extraOptions
        delete i.userOptions.extraOptions;
    }
    return i;
};

    // helper(s) PART
const reflowAll = () => {
    Highcharts.charts.forEach((currentChart) => {
        if (currentChart) {
            currentChart.reflow();
            //currentChart.zoomOut();
            //currentChart.pointer.reset();
        }
    });
};
const resetZoom = () => {
    if (Highcharts.extraGlobal.sync.lastExtremes) {
        Highcharts.charts.forEach((currentChart) => {
            if (currentChart) {
                currentChart.zoomOut();
                //currentChart.pointer.reset();
            }
        });
    }
};

    // export
export { sync, buildChartSetup,buildChartSetup_multiple, chartInit, reflowAll, resetZoom };