<template>
  <div>
    <div v-if="loading">
      <Spinner size="large" :message="$t('loading')"></Spinner>
    </div>
    <div v-else class="small">
      <highcharts
        v-for="options in optionsList"
        :key="options.title.text"
        :options="options"
        ref="highcharts"
        :class="{
          chart_line_tier_col_full: chart_display === 'd3',
          chart_line_half_col_full: chart_display === 'd2',
          chart_line_full_col_half: chart_display === 'd4',
          chart_line_full_col_full: chart_display === 'd1',
          chart_line_half_col_half: chart_display === 'd5',
        }"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import Highcharts3d from "highcharts/highcharts-3d";
import exportingInit from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";
import stockInit from "highcharts/modules/stock";

import { Chart } from "highcharts-vue";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import JQuery from "jquery";
let $ = JQuery;

Highcharts3d(Highcharts);
exportingInit(Highcharts);
offlineExporting(Highcharts);
more(Highcharts);
stockInit(Highcharts);

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: "",
    rangeSelectorFrom: "Début",
    rangeSelectorTo: "Fin",
  },
});

Highcharts.seriesTypes.line.prototype.drawLegendSymbol =
  Highcharts.seriesTypes.column.prototype.drawLegendSymbol;

let isCtrlClick = false;
$(document).keydown(function (event) {
  isCtrlClick = event.which == "17";
});
$(document).keyup(function () {
  isCtrlClick = false;
});
var seriesIndex_past = null;
function sync(ref, event, type) {
  Highcharts.charts.forEach((chart, index, array) => {
    if (chart === undefined) {
      return;
    } else {
      chart.series.forEach((series) => {
        series.data.forEach((point, index) => {
          if (point.x === this.x) {
            if (type === "over") {
              point.setState("hover");
              chart.tooltip.refresh(point);
              chart.xAxis[0].drawCrosshair(event, point);
            } else {
              point.setState();
              chart.tooltip.hide();
              chart.xAxis[0].hideCrosshair();
            }
          }
        });
      });
    }
  });
}
function syncExtremes(e) {
  var thisChart = this.chart;

  if (e.trigger !== "syncExtremes") {
    // Prevent feedback loop
    Highcharts.charts.forEach((chart) => {
      if (chart !== undefined) {
        //if (chart !== thisChart) {
        if (chart.xAxis[0].setExtremes) {
          // It is null while updating
          chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
            trigger: "syncExtremes",
          });
        }
      }
      //}
    });
  }
}

function genOptions(ref, dataset, type) {
  if (type == "multi_axes") {
    return {
      chart: {
        marginLeft: 60, // Keep all charts left aligned
        spacingTop: 20,
        spacingBottom: 20,
        zoomType: "xy",
      },
      title: {
        text: dataset.name,
      },
      credits: {
        enabled: false,
      },
      rangeSelector: {
        verticalAlign: "top",
        enabled: true,
        inputEnabled: true,
        buttonTheme: {
          style: {
            display: "none",
          },
        },
        inputStyle: {
          background: "#fff",
        },
      },
      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%d-%b}",
          //rotation: 45,
          //align: 'left'
        },
        //categories: dataset.categories,
        crosshair: {
          width: 2,
          color: "gray",
          dashStyle: "shortdot",
        },
        events: {
          setExtremes: syncExtremes,
        },
      },
      yAxis: dataset.yAxis,
      tooltip: {
        shared: false,
        style: {
          fontSize: "0.65rem",
        },
        positioner: function () {
          return { x: 0, y: 0 };
        },
        shadow: false,
        borderWidth: 0,
        backgroundColor: "rgba(255,255,255,0.0)",
        valueDecimals: 2,
      },
      plotOptions: {
        line: {
          //lineWidth: 1
          lineWidth: 0.5,
          opacity: 1,
        },
        column: {
          //pointWidth: 2
        },
        series: {
          stickyTracking: false,
          marker: {
            enabled: true,
            radius: 1,
          },
          animation: {
            duration: 1000,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
          point: {
            events: {
              mouseOver: function (event) {
                sync.call(this, ref, event, "over");
              },
              mouseOut: function (event) {
                sync.call(this, ref, event, "out");
              },
            },
          },
          events: {
            legendItemClick: function (e) {
              let _this = e.target;
              let seriesIndex = _this.index;
              let series = _this.chart.series;
              let currentChart = _this.chart;

              if (!currentChart.seriesOnFocus) {
                currentChart.seriesOnFocus = [];
              }
              if (_this.visible) {
                if (!currentChart.seriesOnFocus.length) {
                  if (isCtrlClick) {
                    _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  } else {
                    series.forEach((i) => {
                      if (i.index !== seriesIndex) {
                        i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                      }
                    });
                    currentChart.seriesOnFocus.push(seriesIndex);
                  }

                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                } else if (currentChart.seriesOnFocus.length === 1) {
                  series.forEach((i) => {
                    if (i.index !== seriesIndex) {
                      i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                    }
                  });
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //var test = null;
                  //currentChart.extraChart.serieOnFocus = null;
                } else {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                  //var test = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                }
              } else {
                if (isCtrlClick) {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                } else {
                  _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.push(seriesIndex);
                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                }
              }
              currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
              return false; //<- IMPORTANT -> block the "next()" process
            },
          },
        },
      },
      legend: {
        itemDistance: 8,
        symbolRadius: 4,
      },
      series: dataset.series,
      exporting: {
        enabled: true,
        fallbackToExportServer: false,
        scale: 1,
        sourceWidth: 1024,
        sourceHeight: 800,
        width: 1024,
        //filename: this.project,
        chartOptions: {
          caption: {
            align: "center",
            text:
              "<b><span> data are provided by </span>" +
              "<b>https://evorisk.fr<b>", // + '<img src="/project/logo/geolithe_web.png">'
          },
          // chart: {
          //   events: {
          //     load: function () {
          //       var chart = this;
          //       chart.renderer
          //         .image("/project/logo/geolithe_web.png", 950, 740, 55, 55)
          //         .add()
          //         .toFront();
          //     },
          //   },
          // },
        },
        buttons: {
          contextButton: {
            //symbol: 'download',
            //symbolSize: 8,
            //symbol: "menuball",
            //theme: {
            //    fill:"none"
            //},
            //titleKey: "Export Button",
            //text: 'Export  ',
            menuItems: ["downloadPNG", "downloadSVG"],
          },
        },
      },
    };
  } else if (type == "6") {
    return {
      chart: {
        marginLeft: 60, // Keep all charts left aligned
        spacingTop: 20,
        spacingBottom: 20,
        zoomType: "xy",
      },
      title: {
        text: dataset.name,
      },
      credits: {
        enabled: false,
      },
      rangeSelector: {
        verticalAlign: "top",
        enabled: true,
        inputEnabled: true,
        buttonTheme: {
          style: {
            display: "none",
          },
        },
        inputStyle: {
          background: "#fff",
        },
      },
      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%d-%b}",
          //rotation: 45,
          //align: 'left'
        },
        //categories: dataset.xAxis[0].categories,
        crosshair: {
          width: 2,
          color: "gray",
          dashStyle: "shortdot",
        },
        events: {
          setExtremes: syncExtremes,
        },
      },
      yAxis: {
        max: dataset.yAxismax,
        plotLines: dataset.plotline,
        title: {
          text: dataset.yAxistitle,
        },
      },
      tooltip: {
        shared: false,
        style: {
          fontSize: "0.65rem",
        },
        positioner: function () {
          return { x: 0, y: 0 };
        },
        shadow: false,
        borderWidth: 0,
        backgroundColor: "rgba(255,255,255,0.0)",
        valueDecimals: 2,
      },
      plotOptions: {
        line: {
          //lineWidth: 1
          lineWidth: 0.5,
          opacity: 1,
        },
        column: {
          //pointWidth: 2
        },
        series: {
          stickyTracking: false,
          marker: {
            enabled: true,
            radius: 1,
          },
          animation: {
            duration: 1000,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
          point: {
            events: {
              mouseOver: function (event) {
                sync.call(this, ref, event, "over");
              },
              mouseOut: function (event) {
                sync.call(this, ref, event, "out");
              },
            },
          },
          events: {
            legendItemClick: function (e) {
              let _this = e.target;
              let seriesIndex = _this.index;
              let series = _this.chart.series;
              let currentChart = _this.chart;

              if (!currentChart.seriesOnFocus) {
                currentChart.seriesOnFocus = [];
              }
              if (_this.visible) {
                if (!currentChart.seriesOnFocus.length) {
                  if (isCtrlClick) {
                    _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  } else {
                    series.forEach((i) => {
                      if (i.index !== seriesIndex) {
                        i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                      }
                    });
                    currentChart.seriesOnFocus.push(seriesIndex);
                  }

                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                } else if (currentChart.seriesOnFocus.length === 1) {
                  series.forEach((i) => {
                    if (i.index !== seriesIndex) {
                      i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                    }
                  });
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //var test = null;
                  //currentChart.extraChart.serieOnFocus = null;
                } else {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                  //var test = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                }
              } else {
                if (isCtrlClick) {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                } else {
                  _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.push(seriesIndex);
                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                }
              }
              currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
              return false; //<- IMPORTANT -> block the "next()" process
            },
          },
        },
      },
      legend: {
        itemDistance: 8,
        symbolRadius: 4,
      },
      series: dataset.data,
      exporting: {
        enabled: true,
        fallbackToExportServer: false,
        scale: 1,
        sourceWidth: 1024,
        sourceHeight: 800,
        width: 1024,
        //filename: this.project,
        chartOptions: {
          caption: {
            align: "center",
            text:
              "<b><span> data are provided by </span>" +
              "<b>https://evorisk.fr<b>", // + '<img src="/project/logo/geolithe_web.png">'
          },
          // chart: {
          //   events: {
          //     load: function () {
          //       var chart = this;
          //       chart.renderer
          //         .image("/project/logo/geolithe_web.png", 950, 740, 55, 55)
          //         .add()
          //         .toFront();
          //     },
          //   },
          // },
        },
        buttons: {
          contextButton: {
            //symbol: 'download',
            //symbolSize: 8,
            //symbol: "menuball",
            //theme: {
            //    fill:"none"
            //},
            //titleKey: "Export Button",
            //text: 'Export  ',
            menuItems: ["downloadPNG", "downloadSVG"],
          },
        },
      },
    };
  } else if (type == "3") {
    return {
      credits: {
        enabled: false,
      },
      chart: {
        zoomType: "xy",
      },
      title: {
        text: "déplacements cumulés",
      },
      xAxis: {
        //categories: dataset.xAxis[0].categories,
        crosshair: {
          width: 2,
          color: "gray",
          dashStyle: "shortdot",
        },
      },
      yAxis: [
        {
          // Primary yAxis
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          title: {
            text: "déplacement cumulé (mm)",
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
        },
        {
          // Secondary yAxis
          title: {
            text: "précipitations (mm)",
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          labels: {
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: false,
        style: {
          fontSize: "0.65rem",
        },
        positioner: function () {
          return { x: 0, y: 0 };
        },
        shadow: false,
        borderWidth: 0,
        backgroundColor: "rgba(255,255,255,0.0)",
        valueDecimals: 2,
      },
      plotOptions: {
        line: {
          lineWidth: 0.5,
          opacity: 1,
        },
        column: {
          //pointWidth: 2
        },
        series: {
          stickyTracking: false,
          marker: {
            enabled: true,
            radius: 1,
          },
          animation: {
            duration: 1000,
          },
          states: {
            inactive: {
              opacity: 1, //<- NEW
            },
          },
          point: {
            events: {},
          },
          events: {
            legendItemClick: function (e) {
              let _this = e.target;
              let seriesIndex = _this.index;
              let series = _this.chart.series;
              let currentChart = _this.chart;
              if (!currentChart.seriesOnFocus) {
                currentChart.seriesOnFocus = [];
              }
              if (_this.visible) {
                if (!currentChart.seriesOnFocus.length) {
                  series.forEach((i) => {
                    if (i.index !== seriesIndex) {
                      i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                    }
                  });
                  currentChart.seriesOnFocus.push(seriesIndex);
                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                } else if (currentChart.seriesOnFocus.length === 1) {
                  series.forEach((i) => {
                    if (i.index !== seriesIndex) {
                      i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                    }
                  });
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //var test = null;
                  //currentChart.extraChart.serieOnFocus = null;
                } else {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                  //var test = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                }
              } else {
                if (currentChart.seriesOnFocus.length) {
                  _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.push(seriesIndex);
                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                }
              }
              currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
              return false; //<- IMPORTANT -> block the "next()" process
            },
          },
        },
      },
      legend: {
        layout: "vertical",
        align: "left",
        verticalAlign: "middle",
        itemMarginTop: 2,
        itemMarginBottom: 2,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || // theme
          "rgba(255,255,255,0.25)",
      },
      series: dataset.data,
    };
  } else if (type == "3D") {
    return {
      credits: {
        enabled: false,
      },
      chart: {
        margin: 100,
        type: "scatter3d",
        animation: false,
        options3d: {
          enabled: true,
          alpha: 10,
          beta: 30,
          depth: 250,
          viewDistance: 5,
          fitToPlot: false,
          frame: {
            bottom: { size: 1, color: "rgba(0,0,0,0.02)" },
            back: { size: 1, color: "rgba(0,0,0,0.04)" },
            side: { size: 1, color: "rgba(0,0,0,0.06)" },
          },
        },
      },
      title: {
        text: "Représentation 3D",
      },
      subtitle: {
        text: "Click and drag the plot area to rotate in space",
      },
      plotOptions: {
        scatter: {
          width: 2,
          height: 2,
          depth: 2,
        },
        series: {
          allowPointSelect: true,
          events: {
            legendItemClick: function (e) {
              let _this = e.target;
              let seriesIndex = _this.index;
              let series = _this.chart.series;
              let currentChart = _this.chart;
              if (!currentChart.seriesOnFocus) {
                currentChart.seriesOnFocus = [];
              }
              if (_this.visible) {
                if (!currentChart.seriesOnFocus.length) {
                  series.forEach((i) => {
                    if (i.index !== seriesIndex) {
                      i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                    }
                  });
                  currentChart.seriesOnFocus.push(seriesIndex);
                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                } else if (currentChart.seriesOnFocus.length === 1) {
                  series.forEach((i) => {
                    if (i.index !== seriesIndex) {
                      i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                    }
                  });
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //var test = null;
                  //currentChart.extraChart.serieOnFocus = null;
                } else {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                  //var test = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                }
              } else {
                if (currentChart.seriesOnFocus.length) {
                  _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.push(seriesIndex);
                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                }
              }
              currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
              return false; //<- IMPORTANT -> block the "next()" process
            },
          },
        },
      },
      yAxis: {
        title: {
          text: "z",
        },
      },
      xAxis: {
        title: {
          text: "x",
        },
        gridLineWidth: 1,
      },
      zAxis: {
        title: {
          text: "y",
        },
        showFirstLabel: false,
      },
      legend: {
        itemDistance: 8,
        symbolRadius: 4,
      },
      series: dataset.data,
    };
  } else if (type == "2d") {
    return {
      chart: {
        type: "scatter",
        zoomType: "xy",
      },
      title: {
        text: "profils",
      },
      xAxis: {
        min: dataset.xAxismin,
        max: dataset.xAxismax,
        gridLineWidth: 1,
        //height: dataset.xAxisheight,
        //width: dataset.xAxiswidth,
        title: {
          enabled: true,
          text: "x",
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      },
      yAxis: {
        min: dataset.yAxismin,
        max: dataset.yAxismax,
        tickInterval: 0.1,
        //height: dataset.yAxisheight,
        //width: dataset.yAxiswidth,
        title: {
          text: "y",
        },
      },
      legend: {
        itemDistance: 8,
        symbolRadius: 4,
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 5,
            states: {
              hover: {
                enabled: true,
                lineColor: "rgb(100,100,100)",
              },
            },
          },
          states: {
            hover: {
              marker: {
                enabled: false,
              },
            },
          },
          tooltip: {
            headerFormat: "<b>{series.name}</b><br>",
            pointFormat: "{point.x} m, {point.y} m",
          },
        },
      },
      series: dataset.data,
    };
  } else if (type == "7") {
    return {
      chart: {
        marginLeft: 60, // Keep all charts left aligned
        spacingTop: 20,
        spacingBottom: 20,
        zoomType: "xy",
      },
      title: {
        text: dataset.name,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%y-%m-%d %Hh}",
          //rotation: 45,
          //align: 'left'
        },
        //categories: dataset.xAxis[0].categories,
        crosshair: {
          width: 2,
          color: "gray",
          dashStyle: "shortdot",
        },
        events: {
          setExtremes: syncExtremes,
        },
      },
      yAxis: {
        type: "logarithmic",
        minorTickInterval: 0.1,
        max: dataset.yAxismax,
        plotLines: dataset.plotline,
        title: {
          text: dataset.yAxistitle,
        },
      },
      tooltip: {
        shared: false,
        style: {
          fontSize: "0.65rem",
        },
        positioner: function () {
          return { x: 0, y: 0 };
        },
        shadow: false,
        borderWidth: 0,
        backgroundColor: "rgba(255,255,255,0.0)",
        valueDecimals: 2,
      },
      plotOptions: {
        line: {
          //lineWidth: 1
          lineWidth: 0.5,
          opacity: 1,
        },
        column: {
          //pointWidth: 2
        },
        series: {
          stickyTracking: false,
          marker: {
            enabled: true,
            radius: 1,
          },
          animation: {
            duration: 1000,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
          point: {
            events: {
              mouseOver: function (event) {
                sync.call(this, ref, event, "over");
              },
              mouseOut: function (event) {
                sync.call(this, ref, event, "out");
              },
            },
          },
          events: {
            legendItemClick: function (e) {
              let _this = e.target;
              let seriesIndex = _this.index;
              let series = _this.chart.series;
              let currentChart = _this.chart;
              if (!currentChart.seriesOnFocus) {
                currentChart.seriesOnFocus = [];
              }
              if (_this.visible) {
                if (!currentChart.seriesOnFocus.length) {
                  series.forEach((i) => {
                    if (i.index !== seriesIndex) {
                      i.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                    }
                  });
                  currentChart.seriesOnFocus.push(seriesIndex);
                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                } else if (currentChart.seriesOnFocus.length === 1) {
                  series.forEach((i) => {
                    if (i.index !== seriesIndex) {
                      i.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                    }
                  });
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //var test = null;
                  //currentChart.extraChart.serieOnFocus = null;
                } else {
                  _this.setVisible(false, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.splice(
                    currentChart.seriesOnFocus.indexOf(seriesIndex),
                    1
                  );
                  //currentChart.extraChart.serieOnFocus = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                  //var test = currentChart.seriesOnFocus[currentChart.seriesOnFocus.length-1];
                }
              } else {
                if (currentChart.seriesOnFocus.length) {
                  _this.setVisible(true, false); //<- second argument = redraw (chart.redraw())
                  currentChart.seriesOnFocus.push(seriesIndex);
                  //currentChart.extraChart.serieOnFocus = seriesIndex;
                  //var test = seriesIndex;
                }
              }
              currentChart.yAxis[0].setExtremes(); //<- VERY IMPORTANT
              return false; //<- IMPORTANT -> block the "next()" process
            },
          },
        },
      },
      legend: {
        // layout: 'vertical',
        // align: 'right',
        // verticalAlign: 'middle',
        padding: 2,
        // itemMarginBottom: 2,
        // backgroundColor:
        //     Highcharts.defaultOptions.legend.backgroundColor || // theme
        //     'rgba(255,255,255,0.25)'
      },
      series: dataset.data,
    };
  }
}

// Add mouse and touch events for rotation
var chart3d = [];
var alpha = [];
var beta = [];
var sensitivity = [];
var posX = [];
var posY = [];
var handlers = [];

function dragStart(eStart) {
  Highcharts.charts.forEach((chart, index, array) => {
    if (chart === undefined) {
      return;
    } else if (chart.options.chart.type == "scatter3d") {
      eStart = chart.pointer.normalize(eStart);
      chart3d = chart;
      posX = eStart.chartX;
      posY = eStart.chartY;
      alpha = chart.options.chart.options3d.alpha;
      beta = chart.options.chart.options3d.beta;
      sensitivity = 5; // lower is more sensitive
      handlers = [];
      handlers.push(Highcharts.addEvent(document, "mousemove", drag));
      //handlers.push(Highcharts.addEvent(document, 'touchmove', drag(eStart,chart,alpha,beta,sensitivity,posX,posY)));
      handlers.push(Highcharts.addEvent(document, "mouseup", unbindAll));
      //handlers.push(Highcharts.addEvent(document, 'touchend', unbindAll(handlers)));
    }
  });
}

function drag(e) {
  // Get e.chartX and e.chartY
  e = chart3d.pointer.normalize(e);

  chart3d.update(
    {
      chart: {
        options3d: {
          alpha: alpha + (e.chartY - posY) / sensitivity,
          beta: beta + (posX - e.chartX) / sensitivity,
        },
      },
    },
    undefined,
    undefined,
    false
  );
}

function unbindAll() {
  handlers.forEach(function (unbind) {
    if (unbind) {
      unbind();
    }
  });
  handlers.length = 0;
}

export default {
  components: {
    highcharts: Chart,
    Spinner,
  },
  props: ["apiCallUrl", "item", "type", "chart_display", "project"],
  data() {
    return {
      seriesIndex_past: null,
      loading: true,
      optionsList: [],
      ref: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    addEvents(i) {
      Highcharts.addEvent(i.container, "mousedown", dragStart);
      //Highcharts.addEvent(i.container, "touchstart", dragStart);
    },
    fetchData() {
      axios
        .get(this.apiCallUrl + this.item, {
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
          "Cache-Control": "no-cache",
        })
        .then((activity) => {
          if (this.type != "3D" && this.type != "2d") {
            this.optionsList = activity.data.datasets.map((dataset, i) => {
              if (dataset.plotLines) {
                dataset.plotline = dataset.plotLines;
                console.log(dataset.plotline[0].value);
                dataset.yAxismax = dataset.plotline[0].value + 1;
              } else {
                (dataset.plotline = []), (dataset.yAxismax = null);
              }

              dataset.yAxistitle = dataset.yAxisLabel;

              dataset.series = [];
              dataset.series.push(dataset.data);
              console.log(dataset);
              dataset.color = Highcharts.getOptions().colors[i];
              return genOptions(this.$refs, dataset, this.type);
            });
            this.loading = false;
          } else if (this.type == "3D") {
            this.optionsList = activity.data.datasets.map((dataset, i) => {
              dataset.yAxistitle = dataset.yAxisLabel;
              dataset.data = dataset.series;
              return genOptions(this.$refs, dataset, this.type);
            });
            this.loading = false;
          } else if (this.type == "2d") {
            this.optionsList = activity.data.datasets.map((dataset, i) => {
              console.log(activity.data.xAxismin);
              dataset.xAxisheight = activity.data.xAxisHeight;
              dataset.xAxiswidth = activity.data.xAxisWidth;
              dataset.yAxisheight = activity.data.yAxisHeight;
              dataset.yAxiswidth = activity.data.yAxisWidth;
              dataset.xAxismin = activity.data.xAxismin;
              dataset.xAxismax = activity.data.xAxismax;
              dataset.yAxismin = activity.data.yAxismin;
              dataset.yAxismax = activity.data.yAxismax;
              console.log(dataset.xAxismin, dataset.yAxismin);
              dataset.data = dataset.series;
              return genOptions(this.$refs, dataset, this.type);
            });
            this.loading = false;
          }
        });
    },
  },
};
</script>

